
import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {MainService} from './main.service';
import {ServerPage} from '../_models';
import {Helpers} from '../helpers';
import {CoursePageExamGroupCreateResponseItem} from '../_models/course/coursepageexamgroupcreateresponseitem';
import {catchError, map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {CoursePageExamGroup} from '../_models/course/coursepageexamgroup';
import {CoursePageExamGroupListResponse} from '../_models/course/CoursePageExamGroupListResponse';
import {CoursePageExam} from '../_models/course/coursepageexam';
import {CoursePagePeriodExamsResult} from '../_models/course/CoursePagePeriodExamsResult';

@Injectable()
export class CourseExamGroupService extends MainService {
  getAllGroups(limit = 250, intProgramYearPeriodID = null): Observable<any> {
    return this.getAllList(limit, 'getCourses', intProgramYearPeriodID);
  }

  getGroups(page: ServerPage = null, exclude: [number] = null, intCoursePageID: number): Observable<CoursePageExamGroupListResponse> {
    return this.getList(`api/course/page/exam/group/${intCoursePageID}`, null, page, exclude);
  }

  createGroup(group: CoursePageExamGroup): Observable<CoursePageExamGroupCreateResponseItem> {
    Helpers.setLoading(true);
    return this.http.post(this.mainConfig.backendServerURL + 'api/course/page/exam/group', group, {headers: this.headers, observe: 'response'}).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);
      return response.body;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }

  editGroup(group: CoursePageExamGroup): Observable<any> {
    Helpers.setLoading(true);
    return this.http.put(this.mainConfig.backendServerURL + `api/course/page/exam/group/${group.intCoursePageExamGroupID}`, group, {headers: this.headers, observe: 'response'}).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);
      return response.body;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }

  deleteGroup(intCoursePageExamGroupID: number): Observable<any> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + `api/course/page/exam/group/${intCoursePageExamGroupID}`;
    return this.http.delete(url, {headers: this.headers, observe: 'response'}).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);
      return response.body;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }

  /**
     * Additional endpoints
     */

  getAvailableExams(intCoursePageID: number): Observable<{ code: number, item: CoursePageExam[] }> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + `api/course/page/exam/group/${intCoursePageID}/exams/available`;
    return this.http.get(url, {headers: this.headers, observe: 'response'}).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);
      return response.body;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }

  getAvailablePeriodExams(intCoursePageID: number): Observable<{ code: number, item: CoursePagePeriodExamsResult[] }> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + `api/course/page/exam/group/all?intCoursePageID=${intCoursePageID}`;
    return this.http.get(url, {headers: this.headers, observe: 'response'}).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);
      return response.body;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }

  getGroupExams(intCoursePageExamGroupID: number): Observable<{ code: number, item: CoursePageExam[] }> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + `api/course/page/exam/group/${intCoursePageExamGroupID}/exams`;
    return this.http.get(url, {headers: this.headers, observe: 'response'}).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);
      return response.body;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }

  addExamToGroup(intCoursePageExamID: number, intCoursePageExamGroupID: number): Observable<{ code: number, item: string | {} }> {
    Helpers.setLoading(true);
    return this.http.post(this.mainConfig.backendServerURL + 'api/course/page/exam/group/add/exam',
        {intCoursePageExamID, intCoursePageExamGroupID},
        {headers: this.headers, observe: 'response'},
    ).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);
      return response.body;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }

  clearGroupOfExam(intCoursePageExamID: number): Observable<{}> {
    Helpers.setLoading(true);
    return this.http.post(this.mainConfig.backendServerURL + 'api/course/page/exam/group/clear',
        {intCoursePageExamID},
        {headers: this.headers, observe: 'response'},
    ).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);
      return response.body;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }
}
