import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthenticationService } from '../../../_services';
import { User } from '../../../_models';
import { GlobalMessageHandler, GlobalMessageType } from '../../../_services/message-handler.service';

declare let mLayout: any;
@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
    user: User;
    GlobalMessageType = GlobalMessageType;

    constructor(
        public authService: AuthenticationService,
        public gmHandler: GlobalMessageHandler,
    ) {
        this.user = this.authService.user;
    }

    ngOnInit() {

    }
    ngAfterViewInit() {
        mLayout.initHeader();
    }
}
