import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { PermissionGuard } from '../guards/permission.guard';
import { FormsGuard } from '../auth/_guards/forms.guard';

const routes: Routes = [
  {
    path: '',
    component: ThemeComponent,
    canActivate: [AuthGuard],
    canActivateChild: [FormsGuard],
    children: [
      // System routes
      {
        path: 'dashboard',
        loadChildren: () => import('../pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'index',
        loadChildren: () => import('../pages/program/program.module').then((m) => m.ProgramModule),
        // "loadChildren": "../pages/index/index.module#IndexModule"
      },
      {
        path: 'overview/teacher',
        loadChildren: () => import('../pages/overview/teacher/teacher.module').then((m) => m.TeacherModule),
      },
      /**
       * Info
       */
      {
        path: 'info',
        loadChildren: () => import('../pages/info/info.module').then((m) => m.InfoModule),
      },
      // {
      //     "path": "info/:pagenumber",
      //     "loadChildren": "../pages/info/info.module#InfoModule",
      // },

      /**
       * MyProfile
       */
      {
        path: 'myprofile',
        loadChildren: () => import('../pages/user/myprofile/myprofile.module').then((m) => m.MyProfileModule),
      },

      /**
       * Grades
       */
      {
        path: 'my-grades',
        loadChildren: () => import('../pages/grades/main/main.module').then((m) => m.GradesMainModule),
      },
      {
        path: 'all-grades',
        loadChildren: () => import('../pages/grades/main/main.module').then((m) => m.GradesMainModule),
      },
      /**
       * Analysis
       */
      {
        path: 'course/analyses',
        loadChildren: () => import('../pages/analysis/analysis.module').then((m) => m.AnalysisModule),
        canActivate: [PermissionGuard],
      },

      /**
       * Course Applications
       */

      {
        path: 'course-applications',
        loadChildren: () => import('../pages/course-application/course-application.module').then((m) => m.CourseApplicationModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'course-applications/:pagenumber',
        loadChildren: () => import('../pages/course-application/course-application.module').then((m) => m.CourseApplicationModule),
        canActivate: [PermissionGuard],
      },

      /**
       * Applications
       */


      {
        path: 'applications',
        loadChildren: () => import('../pages/student/application/application.module').then((m) => m.StudentApplicationModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'applications/detail/:id',
        loadChildren: () => import('../pages/student/application/detail/detail.module').then((m) => m.ApplicationDetailModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'applications/:pagenumber',
        loadChildren: () => import('../pages/student/application/application.module').then((m) => m.StudentApplicationModule),
        canActivate: [PermissionGuard],
      },
      /**
       * Students
       */
      {
        path: 'students',
        loadChildren: () => import('../pages/student/student.module').then((m) => m.StudentModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'students/view',
        loadChildren: () => import('../pages/student/detail/detail.module').then((m) => m.StudentDetailModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'students/view/:id',
        loadChildren: () => import('../pages/student/detail/detail.module').then((m) => m.StudentDetailModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'students/view/:id/contact',
        loadChildren: () => import('../pages/student/contact/view/view.module').then((m) => m.StudentContactViewModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'students/view/:id/contact/:cid',
        loadChildren: () => import('../pages/student/contact/view/view.module').then((m) => m.StudentContactViewModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'students/:pagenumber',
        loadChildren: () => import('../pages/student/student.module').then((m) => m.StudentModule),
        canActivate: [PermissionGuard],
      },

      /**
       * Users/Collaborators
       */
      {
        path: 'users/list',
        loadChildren: () => import('../pages/user/user.module').then((m) => m.UserModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'users/list/:pagenumber',
        loadChildren: () => import('../pages/user/user.module').then((m) => m.UserModule),
        canActivate: [PermissionGuard],
      },

      {
        path: 'users/roles',
        loadChildren: () => import('../pages/user/roles/roles.module').then((m) => m.UserRolesModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'users/view',
        loadChildren: () => import('../pages/user/detail/detail.module').then((m) => m.UserDetailModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'users/view/:id',
        loadChildren: () => import('../pages/user/detail/detail.module').then((m) => m.UserDetailModule),
        canActivate: [PermissionGuard],
      },

      /**
       * Courses Exemption
       * Not finished yet
       */
      {
        path: 'exemptions',
        loadChildren: () => import('../pages/course/exemption/exemption.module').then((m) => m.CourseExemptionModule),
      },
      {
        path: 'exemptions/detail/:id',
        loadChildren: () => import('../pages/course/exemption/detail/detail.module').then((m) => m.DetailModule),
      },
      {
        path: 'courses/view/:ccid/exemption-request',
        loadChildren: () => import('../pages/course/exemption/request/request.module').then((m) => m.ExemptionRequestModule),
        canActivate: [PermissionGuard],
      },

      /**
       * Evaluation
       */

      {
        path: 'forms',
        loadChildren: () => import('../pages/forms/forms.module').then((m) => m.FormsModule),
      },

      {
        path: 'forms/new',
        loadChildren: () => import('../pages/forms/detail/detail.module').then((m) => m.FormsDetailModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'forms/view/:id',
        loadChildren: () => import('../pages/forms/detail/detail.module').then((m) => m.FormsDetailModule),
      },

      /**
       * Courses
       */

      {
        path: 'program/year/group/:id',
        loadChildren: () => import('../pages/program/program-detail/groups/detail/detail.module').then((m) => m.ProgramYearGroupDetailModule),
        canActivate: [PermissionGuard],
      },

      {
        path: 'programs',
        loadChildren: () => import('../pages/program/program.module').then((m) => m.ProgramModule),
      },
      // Used to copy the contents of a courseyear into another calendar year e.g. 2018-2019 Leerjaar 1 -> copy -> 2019-2020 Leerjaar 1
      {
        path: 'programs/:pid/calendaryear/:calid/year/:yid/copy',
        loadChildren: () => import('../pages/program/course-year-copier/course-year-copier.module').then((m) => m.CourseYearCopierModule),
      },

      // --> Concrete Course
      {
        path: 'courses/:pid/view',
        loadChildren: () => import('../pages/course/view/view.module').then((m) => m.CourseViewModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'courses/view/:ccid',
        loadChildren: () => import('../pages/course/view/view.module').then((m) => m.CourseViewModule),
      },
      {
        path: 'courses/view/:courseID/page/final-grade',
        loadChildren: () => import('../pages/course/final-grade/final-grade.module').then((m) => m.FinalGradeModule),
      },
      {
        path: 'courses/view/:courseID/analysis',
        loadChildren: () => import('../pages/course/analysis/analysis.module').then((m) => m.AnalysisModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'takeexam/:pid/:eid',
        loadChildren: () => import('../pages/course/view/page/takeexam/takeexam.module').then((m) => m.TakeExamModule),
      },
      {
        path: 'review-exam/page/:pid/exam/:eid/student/:sid/submission/:subid',
        loadChildren: () => import('../pages/course/view/page/review/review.module').then((m) => m.ReviewExamModule),
        // "canActivate": [PermissionGuard],
      },

      // Template routes
      {
        path: '404',
        loadChildren: () => import('./pages/default/not-found/not-found.module').then((m) => m.NotFoundModule),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThemeRoutingModule {}
