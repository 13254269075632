import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable()
export class MainConfig {
    websiteName: string = 'IUA Backoffice';

    // backendServerURL:string = "https://api.wnzo.nl/";
    backendServerURL: string = environment.backendUrl;
}
