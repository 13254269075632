<div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
 id="m_login">
	<div style="margin-left:auto;margin-right:auto;" class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
		<div class="m-stack m-stack--hor m-stack--desktop">
			<div class="m-stack__item m-stack__item--fluid">

				<div class="m-login__wrapper">

					<div class="m-login__logo">
						<a href="#">
							<img src="./assets/app/media/img//logos/logo-iua-big.png" class="img-fluid">
						</a>
					</div>

					<div class="m-login__signin">
						<div class="m-login__head">
							<h3 class="m-login__title">
								{{ '_login_sign_in_title' | translate }}
							</h3>


							<div id='warning-message' class="m--margin-top-40 m-alert m-alert--outline m-alert--outline-2x alert alert-success fade show" role="alert">
								{{ '_login_account_only_for_you_explanation' | translate }}
								<br /> {{ '_login_account_only_for_you_explanation_en' | translate }}

								<!-- <span style="font-weight: 500;">demo@demo.com</span> and password
								<span style="font-weight: 500;">demo</span> to continue or singup. -->
							</div>
						</div>

						<form (ngSubmit)="f.form.valid && signin()" #f="ngForm" class="m-login__form m-form" action="">
							<ng-template #alertSignin></ng-template>
							<div class="form-group m-form__group">
								<input class="form-control m-input" type="text" placeholder="{{ '_login_form_email' | translate }}" name="username" [(ngModel)]="model.email"
								 #email="ngModel" autocomplete="username">
							</div>
							<div class="form-group m-form__group">
								<input class="form-control m-input m-login__form-input--last" type="password" placeholder="{{ '_login_form_password' | translate }}"
								 name="current-password" autocomplete="current-password" [(ngModel)]="model.password" #password="ngModel">
							</div>
							<!-- <div class="row m-login__form-sub">
								<div class="col m--align-right">
									<a href="javascript:;" id="m_login_forget_password" class="m-link">
										
									</a>
								</div>
							</div> -->
							<div class="m-login__form-action">
								<button [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light': loading}" id="m_login_signin_submit"
								 class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air">{{ '_login_sign_in' | translate }}</button>
							</div>
						</form>
					</div>

					<!-- <div class="m-login__forget-password"> -->
						<!-- <div class="m-login__head">
							<h3 class="m-login__title">{{ '_login_forgot_password' | translate }}</h3>
							<div class="m-login__desc">{{ '_login_forgot_password_enter_email_to_reset' | translate }}</div>
						</div> -->
						<!-- <form (ngSubmit)="f.form.valid && forgotPass()" #f="ngForm" class="m-login__form m-form" action="">
							<ng-template #alertForgotPass></ng-template>
							<div class="form-group m-form__group">
								<input class="form-control m-input" type="text" placeholder="{{ '_login_forgot_password_email' | translate }}" name="email"
								 [(ngModel)]="model.email" #email="ngModel" id="m_email" autocomplete="off">
							</div>
							<div class="m-login__form-action">
								<button class="mr-2" [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light': loading}" id="m_login_forget_password_submit"
								 class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air">
									{{ '_login_forgot_password_request' | translate }}
								</button>
								<button [disabled]="loading" id="m_login_forget_password_cancel" class="btn btn-outline-focus m-btn m-btn--pill m-btn--custom">
									{{ '_login_forgot_password_cancel' | translate }}
								</button>
							</div>
						</form> -->
					<!-- </div> -->
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content m-grid-item--center"
	 style="background-image: url(./assets/app/media/img/bg/bg-iua.jpg)">
		<div class="m-grid__item">
			<h3 class="m-login__welcome">
				{{ '_login_welcome' | translate }}
			</h3>
			<p class="m-login__msg">
				Lorem ipsum dolor sit amet, coectetuer adipiscing
				<br>elit sed diam nonummy et nibh euismod
			</p>
		</div>
	</div> -->
</div>