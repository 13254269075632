import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

export enum GlobalMessageType {
    SUCCESS = 'success',
    ERROR = 'danger',
    INFO = 'info',
    WARNING = 'warning'
}
export class GlobalMessage {
    message: string;
    type: GlobalMessageType;
}

@Injectable()
export class GlobalMessageHandler {
    messages: GlobalMessage[] = [];
    interval: any;

    constructor(
        private router: Router
    ) {
        router.events.subscribe((val) => {
            // see also
            this.messages = [];
        });
    }

    handleMessage(messages: GlobalMessage[], doNotClear: boolean = false): void {
        clearInterval(this.interval);
        this.messages = messages;
        if (!doNotClear) {
            this.interval = setTimeout(() => {
                this.messages = [];
            }, 5000);
        }
    }

    handleSuccess(message: string) {
        this.handleMessage([{ message: message, type: GlobalMessageType.SUCCESS }]);
    }

    handleError(errorResponse: HttpErrorResponse) {
        const message = (errorResponse.error.message) ? errorResponse.error.message : errorResponse.message;

        this.handleMessage([{ message: 'Er ging iets fout: ' + message, type: GlobalMessageType.ERROR }], true);
    }

    handleCustomError(message) {
        this.handleMessage([{ message: `Er ging iets fout: ${message}`, type: GlobalMessageType.ERROR }], true);
    }
}
