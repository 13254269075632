export const LANG_NL_NAME = 'nl';

export const LANG_NL_TRANS = {
  _core_delete_title: 'Verwijderen',
  _core_delete_description: 'Weet u zeker dat u de betreffende item wilt verwijderen?',
  _core_delete_cancel: 'Annuleren',
  _core_delete_ok: 'Verwijderen',
  _core_please_wait: 'Aan het laden ...',
  _core_welcome: 'Welkom',
  _core_you_are_logged_in_with: 'U bent ingelogd met',
  _core_logout: 'Uitloggen',
  _core_my_profile: 'Mijn Profiel',
  _core_to_gmail: 'GMail',
  _core_to_google_account: 'Google',
  _core_to_google_drive: 'Drive',
  _core_to_google_documents: 'Documenten',
  _core_to_google_spreadsheets: 'Spreadsheets',
  _core_to_social_media: 'Social media',
  _core_to_facebook: 'Facebook',
  _core_to_instagram: 'Instagram',
  _core_to_linkedin: 'Linkedin',
  _core_website: 'IUA Website',
  _core_manage: 'Beheer',
  _core_info_page: 'Informatie pagina',
  _core_files: 'Bestanden',
  _core_brought_to_you_by: 'Studentenportaal mogelijk gemaakt door',
  _core_username_or_password_wrong: 'E-mail en/of wachtwoord onjuist. Probeer het a.u.b. opnieuw',
  _core_acount: 'Acties',
  _core_maximum: 'Maximaal',
  _core_saved_successfully: 'Successvol opgeslagen!',
  _core_updated_succesfully: 'Succesvol aangepast!',
  _core_added_succesfully: 'Succesvol toegevoegd!',
  _core_deleted_succesfully: 'Succesvol verwijderd!',

  _save: 'Opslaan',
  _error_occurred: 'Er is een fout opgetreden. Probeer later opnieuw',
  _datalist_remove: 'Verwijderen',
  _loading: 'Aan het laden ...',
  _saved_successfuly: 'Successvol opgeslagen',
  _saving: 'Aan het opslaan ...',
  _filter: 'Filter...',

  _login_sign_in_title: 'Islamitische Theologische Faculteit - Inloggen',
  _login_account_only_for_you_explanation:
    'Jouw IUA-account is alleen van en voor jou. Deel je wachtwoord nooit met anderen.',
  _login_account_only_for_you_explanation_en:
    'Your IUA account is only for use by you. Never share your password with anyone.',
  _login_forgot_password: 'Wachtwoord vergeten',
  _login_sign_in: 'Inloggen',
  _login_forgot_password_enter_email_to_reset: 'Voer uw e-mail adres in om uw wachtwoord te herstellen',
  _login_form_email: 'E-mail adres',
  _login_form_password: 'Wachtwoord',
  _login_forgot_password_email: 'E-mail adres',
  _login_forgot_password_request: 'Aanvragen',
  _login_forgot_password_cancel: 'Annuleren',
  _login_welcome: 'Welkom',

  /**
   * Overview
   */
  _overview_teacher_title: 'Openstaande taken',

  /**
   * Course Analysis
   */
  _course_analysis_empty_message: 'Geen toetsanalysen gevonden',
  _course_analysis_total: 'Totaal',

  /**
   * Exemptions
   */
  _exemptions_empty_message: 'Geen vrijstellingen gevonden',
  _exemptions_empty_message_no_open_exemption_requests: 'Geen openstaande vrijstelling aanvragen gevonden',
  _exemptions_total: 'Totaal',
  _exemption_request_title: 'Vrijstelling aanvragen',
  _exemption_request_button: 'Verder',
  _exemption_request_save_button: 'Aanvraag opslaan',
  _exemption_request_back_button: 'Terug',
  _exemption_request_submit_button: 'Indienen',
  _exemption_request_review_button: 'Controleren',
  _exemption_request_messages: 'Reacties',
  _exemption_request_message_add_button: 'Aanvullend bericht toevoegen',
  _exemption_request_message: 'Het aanvullend bericht',
  _exemption_request_deny_request: 'Aanvraag afwijzen',
  _exemption_request_deny_request_message:
    'U staat op het punt om een aanvraag voor een vrijstelling te afwijzen. Deze afwijzing kan niet ongedaan worden. Indien u van de student een aanvulling wilt voor de aanvraag, gebruikt dan a.u.b. de knop \'Vraag om aanvulling\'',
  _exemption_request_request_action: 'Vraag om aanvulling',
  _exemption_request_send_to_exmaninator: 'Doorzetten naar de examencommissie',
  _exemption_request_request_action_message:
    'U staat op het punt om de aanvraag terug te sturen de student. Hierna kan de student de aanvraag aanpassen en eventuele bestanden uploaden/verwijderen.\n\nVergeet niet om een reactie achter te laten waarom deze aanvraag is teruggestuurd',
  _exemption_request_send_to_exmaninator_message:
    'U staat op het punt om de aanvraag te accorderen. Deze aanvraag zal hierna doorgezet worden naar de examencommissie voor een beoordeling.',
  _exemption_request_accept: 'Aanvraag accepteren',
  _exemption_request_accept_message:
    'U staat op het punt om deze aanvraag te accepteren en de studiepunten (EC\'s) toe te kennen aan de betreffende student.',
  _exemption_request_submit_review: 'Aanvraag indienen',
  _exemption_request_submit_review_message:
    'Je staat op het punt om deze aanvraag in te dienen bij je betreffende docent.\n\n<b>Let op:</b> Alleen je docent kan je aanvraag terug naar jou toesturen. Tot die tijd zal het niet mogelijk zijn om aanpassingen door te voeren. Controleer alles goed voordat je je aanvraag indient',

  /**
   * Forms
   */
  _forms_title: 'Formulieren',
  _forms_item_type_text_answer: 'Antwoord',
  _forms_item_type_choice: 'Meerkeuze',
  _forms_item_type_multiple_choice: 'Selectievakjes',
  _forms_item_type_dropdown: 'Dropdownmenu',
  _forms_item_type_text_more_choice: 'Meerkeuzeraster',
  _forms_item_type_more_multiple_choice: 'Selectievakraster',

  /**
   * Applications
   */
  _applications_title: 'Aanmeldingen',
  _applications_total: 'Totaal',
  _course_applications_empty_message: 'Geen inschrijvingen gevonden',
  _applications_empty_message: 'Geen aanmeldingen gevonden',
  _applications_detail_id: 'Aanmelding ID',
  _applications_detail_name: 'Naam',
  _applications_detail_address: 'Adres en Woonplaats',
  _applications_detail_email: 'Email',
  _applications_detail: 'Aanmelding',
  _applications_detail_info: 'Informatie',
  _application_create_student: 'Studentdossier aanmaken',
  _application_accept_confirm_title: 'Studentdossier aanmaken',
  _application_accept_confirm_description:
    'Weet u zeker dat u van de huidige aanmelding een studentendossier wilt aanmaken?',
  _application_accept_confirm_cancel: 'Annuleren',
  _application_accept_confirm_ok: 'Aanmaken',
  _application_detail_files: 'Bestanden',

  /**
   * Programs
   */
  _programs_document: 'Documenten',
  _programs_groups: 'Groepen',
  _programs_add_program: 'Opleiding toevoegen',
  _programs_add_program_name: 'Naam opleiding',
  _programs_add_program_save: 'Opslaan',
  _programs_add_program_add: 'Toevoegen',
  _programs_add_program_cancel: 'Annuleren',

  _programs_edit: 'Aanpassen',
  _programs_delete: 'Verwijderen',

  _programs_added_successfully: 'Succesvol toegevoegd',
  _programs_deleted_successfully: 'Succesvol verwijderd',
  _programs_updated_successfully: 'Succesvol aangepast',

  /**
   * Program Year Group
   */
  _programs_year_group_add: 'Groep toevoegen',
  _programs_year_group_add_add: 'Toevoegen',
  _programs_year_groups: 'Groepen',
  _programs_year_group_manage: 'Groep beheren',
  _programs_year_group_delete: 'Groep verwijderen',
  _programs_year_group_exists_error: 'Deze groep bestaat al! Kies a.u.b. een andere groepnaam',
  _programs_year_group_name: 'Groepnaam',
  _programs_year_group_remove_student: 'Verwijderen',
  _programs_year_group_add_student: 'Toevoegen',
  _programs_year_group_copy_to: 'Kopieer naar',
  _programs_year_group_copy: 'Kopiëren',
  _programs_year_group_choose_program_year: 'Kies leerjaar',

  /**
   * Duplicates
   */
  _programs_duplicate: 'Dupliceren',
  _programs_duplicate_proceed: 'Dupliceren',
  _programs_duplicate_cancel: 'Annuleren',

  _programs_duplicate_description:
    'U staat op het punt om een leerjaar te dupliceren naar een calenderjaar, Wilt u doorgaan?\n\n<span style="color:red"><b>Let op:</b> Dit proces kan enkele minuten in beslag nemen. Na het starten van dit proces, krijgt u via de e-mail de status van het proces. Wacht geduldig af op de e-mail met afronding</span>',
  _programs_duplicate_started: 'Het dupliceerproces is succesvol gestart. U zult gemailed worden over het proces.',

  /**
   * Course analysis
   */
  _course_analyses: 'Toetsanalyse',

  /**
   * Course
   */
  _courses_title: 'Onderwijseenheden',
  _courses_choose_education: 'Kies opleiding',
  _courses_choose_year_or_course: 'Kies leerjaar of richting',
  _courses_choose_period: 'Kies periode',
  _courses_choose_year: 'Kies leerjaar',
  _courses_choose_course: 'Kies onderwijseenheid',
  _courses_new_course: 'Nieuwe Onderwijseenheid',
  _courses_view_new_course: 'Nieuwe Onderwijseenheid',
  _courses_view_course_name: 'Naam onderwijseenheid',
  _courses_view_course_ec_points: 'EC Punten',
  _courses_view_course_caesura_points: 'Cesuur',
  _courses_view_course_min_grade_compensated_exam: 'Minimale cijfer voor gecompenseerde toetsen',
  _courses_view_course_save: 'Aanmaken',
  _courses_view_course_save_current: 'Opslaan',
  _courses_view_course_description: 'Omschrijving',
  _courses_view_enter_content: 'Voer omschrijving in',
  _courses_view_add_teacher: 'Docent toevoegen',
  _courses_view_teacher: 'Docent',
  _courses_view_course_add: 'Toevoegen',
  _courses_view_course_teachers: 'Toegewezen docent(en)',
  _courses_view_no_teachers_attached: 'Geen docent(en) toegewezen',
  _courses_view_course_last_edit_date: 'Aanpassing mogelijk tot',
  _courses_view_navigate: 'Navigeren',
  _courses_view_deletePage: 'Pagina Verwijderen',

  _course_files_empty_message: 'Geen bestanden gevonden',
  _course_files_total: 'Totaal',
  _courses_files_name: 'Bestandsnaam',
  _courses_files_uploaded_by: 'Geüpload door',
  _courses_upload_file: 'Bestand uploaden',
  _courses_upload: 'Uploaden',

  _course_view_page_name: 'Naam pagina',
  _course_view_page_name_new: 'Nieuwe pagina',
  _course_view_page_description: 'Omschrijving',
  _course_view_page_save: 'Opslaan',
  _course_view_page_published: 'Gepubliceerd',
  _course_view_page_type: 'Type',
  _course_view_page_type_page: 'Informatieve pagina',
  _course_view_page_type_homework: 'Huiswerk',
  _course_view_page_type_exam: 'Examen',
  _course_view_page_type_resit: 'Herkansing',

  _course_view_page_upload_file: 'Bestand uploaden',
  _course_view_page_files: 'Bestanden',
  _course_view_page: 'Pagina',
  _course_view_page_exam_type: 'Soort',
  _course_view_page_exam_type_document: 'Upload document (PDF, Word, Excel, PowerPoint, OpenOffice)',
  _course_view_page_exam_type_multiple_choice: 'Multiple en open vragen',
  _course_view_page_exam_type_video: 'Upload videobestand',
  _course_view_page_exam_type_written: 'Schriftelijk',
  _course_view_page_exam_type_turnitin: 'Turn It In',
  _course_view_page_exam_type_oral: 'Mondeling',
  _course_view_page_exam_parent: 'Hoofdtoets',
  _course_view_page_exam_edit: 'Toetsing aanpassen',
  _course_view_page_exam_save_button: 'Opslaan',
  _course_view_page_exam_add: 'Toetsing toevoegen',
  _course_view_page_exams: 'Toetsingen',
  _course_view_page_exam_name: 'Naam',
  _course_view_page_exam_add_button: 'Toevoegen',
  _course_view_page_exams_not_found: 'Geen toetsingen gevonden',
  _course_view_page_exam_not_counts_for_rating: 'Telt niet mee voor eindbeoordeling',
  _course_view_page_exam_no_submissions: 'Geen inzendingen',
  _course_view_page_exam_ungraded_submissions: 'Inzendingen in afwachting',

  _course_view_page_exam_group: 'Compenstaties instellen',
  _course_view_page_exam_group_descr:
    'U kunt de compensaties van meerdere toetsen hier aanmaken. Eerst maakt u een "Compensatie groep" aan. Hierna kunt u de toetsen selecteren die met elkaar gecompenseerd kunnen worden. Deze compensatie zal later gebruikt worden tijdens het bepalen van het eindcijfer.',
  _course_view_page_exam_group_descr2:
    'Alle cijfers van deze toetsen zullen bij elkaar opgeteld worden. Wanneer het gezamenlijke cijfer boven de 5.5 is, zullen de betreffende toetsen gemarkeerd worden met "behaald".',
  _course_view_page_exam_group_id: 'Groeps ID',
  _course_view_page_exam_group_name: 'Groepsnaam',
  _course_view_page_exam_group_edit: 'A',
  _course_view_page_exam_group_delete: 'V',
  _course_view_page_exam_group_cancel_button: 'Annuleren',
  _course_view_page_exam_group_save_button: 'Aanpassen',
  _course_view_page_exam_group_add_button: 'Toevoegen',
  _course_view_page_exam_group_save_success: 'Succesvol opgeslagen!',
  _course_view_page_exam_group_create_success: 'Succesvol toegevoegd!',
  _course_view_page_exam_group_delete_title: 'Compenstatiegroep verwijderen?',
  _course_view_page_exam_group_delete_descr: 'Weet u zeker dat u de betreffende compensatiegroep wilt verwijderen?',
  _course_view_page_exam_group_delete_cancel: 'Annuleren',
  _course_view_page_exam_group_delete_ok: 'Verwijderen',
  _course_view_page_exam_group_delete_success: 'Verwijderen van een compensatiesgroep successvol uitgevoerd!',
  _course_view_page_exam_group_empty_message: 'Geen compensatiegroepen gevonden',
  _course_view_page_exam_group_exemptions_total: 'Totaal',
  _course_view_page_exam_group_manager_title: 'Groep',
  _course_view_page_exam_group_manager_item_already_added: 'Al toegevoegd aan een compensatie',
  _course_view_page_exam_group_manager_available_exams_current: 'Huidige',
  _course_view_page_exam_group_manager_available_exams_cross: 'Overbruggende',

  _course_view_page_counts_for_rating: 'Telt mee voor eindbeoording',
  _course_view_page_rating_value: 'Weging (max 100)',
  _course_view_page_rating: 'Weging',
  _course_view_page_rating_value_explanation:
    'Let op: Alle wegingen binnen 1 pagina dienen samen opgeteld 100 te zijn! Als er maar 1 toets is binnen deze pagina, vul dan hier 100 in. Mocht er twee toetsen zijn (b.v. 1 werkstuk en 1 videobestand) let dan op dat deze samen opgeteld een weging heeft van 100',

  _course_page_exam_title: 'Vragenlijst titel',
  _course_page_exam_add_questions: 'Aantal',
  _course_page_exam_add_amount: 'Vragen toevoegen',
  _course_page_exam_question: 'Vraag',
  _course_page_exam_type: 'Soort vraag',
  _course_page_exam_type_multiple_choice: 'Multiple choice',
  _course_page_exam_type_open_question: 'Open vraag',
  _course_page_exam_weight: 'Weging',
  _course_page_exam_multiple_choice_a: 'A',
  _course_page_exam_multiple_choice_b: 'B',
  _course_page_exam_multiple_choice_c: 'C',
  _course_page_exam_multiple_choice_d: 'D',
  _course_page_exam_multiple_choice_e: 'E',
  _courses_view_open_navigation: 'Open navigatie',
  _courses_view_close_navigation: 'Sluit navigatie',

  _course_view_page_general: 'Algemeen',
  _course_view_page_application_required: 'Aanmelding verplicht',
  _course_view_page_start_date: 'Start inzage op',
  _course_view_page_end_date: 'Eind inzage op',
  _course_view_page_allow_on_location: 'Uitvoer op locatie (onder toezicht)',
  _course_view_page_does_not_have_start_date: 'Voer a.u.b. start inzage datum en tijd in',
  _course_view_page_does_not_have_end_date: 'Voer a.u.b. eind inzage datum en tijd in',
  _course_view_questions_total_is_not_100: 'De totale weging van de betreffende vragenlijst komt niet uit op 100',
  _course_view_does_not_have_questions: 'bevat geen vragenlijst',
  _course_view_page_ready_to_publish_message: 'Alles ziet er goed uit!, u kunt nu de pagina publiceren',
  _course_view_page_does_not_have_exams: 'Er zijn geen toetsingen gevonden',
  _course_view_page_countdown_year: 'Jaar',
  _course_view_page_countdown_month: 'Maand',
  _course_view_page_countdown_weeks: 'Weken',
  _course_view_page_countdown_days: 'Dagen',
  _course_view_page_countdown_hours: 'Uren',
  _course_view_page_countdown_minutes: 'Minuten',
  _course_view_page_countdown_seconds: 'Seconden',
  _course_view_page_countdown_milliseconds: 'Milliseconden',
  _course_view_page_submits: 'Inzendingen',

  _course_page_resit_only_students_can_apply: 'Alleen studenten kunnen inschrijven voor een herkansing',
  _course_page_resit_apply: 'Inschrijven voor herkansing',
  _course_page_subscribe_resit_title: 'Inschrijven voor herkansing',
  _course_page_subscribe_resit_description:
    'Je staat op het punt om in te schrijven voor een herkansing.\r\nVraag je docent om je inschrijving goed te keuren',
  _course_page_subscribe_resit_cancel: 'Annuleren',
  _course_page_subscribe_resit_ok: 'Inschrijven',
  _course_page_resit_success: 'Inschrijving succesvol uitgevoerd!',
  _course_page_resit_apply_wait: 'Verzoek tot inschrijven ingediend, wachten op goedkeuring',
  _course_page_resit_no_requests: 'Geen openstaande herkansings aanvragen gevonden',

  /**
   * Course
   */
  _course_view_page_title: 'Vrijstellingen',
  _course_analysis_view_page_title: 'Toetsanalyse',

  _course_view_request_to_access_success:
    'Uw aanvraag is successvol ingediend. Vraag een beheerder of de betreffende docent om de aanvraag te laten goedkeuren.',
  _course_view_request_no_requests_found: 'Geen nieuwe aanvragen gevonden',

  /**
   * Evaluation
   */
  _course_view_page_evaluations: 'Evalutie',

  /**
   * Rating
   */

  _course_page_rating_title: 'Titel',
  _course_page_rating_add_amount: 'Aantal',
  _course_page_rating_add_rating: 'Beoordelingen toevoegen',
  _course_page_rating_item_title: 'Beoordeling',
  _course_page_rating_weight: 'Weging',
  _course_page_rating_description: 'Beschrijving',
  _course_page_rating_item_required: 'Verplicht',
  _course_view_page_rating_save_button: 'Opslaan',
  _course_view_page_rating_form: 'Beoordelingsformulier',
  _course_view_page_rating_score: 'Gekozen waarde',
  _course_view_page_rating_score_explanation: 'Toelichting',
  _course_view_page_rating_choose_file: 'Kies een bestand',
  _course_view_page_rating_download_file: 'Bestand downloaden',
  _course_view_page_rating_item_accomplished: 'Voldaan',
  _course_view_page_rating_finish: 'Beoordeling invoeren',
  _course_view_page_assignees: 'Inschrijvingen',

  /**
   * Take Exam
   */
  _course_view_exam_question: 'Vraag',
  _course_view_exam_your_answer: 'Je antwoord',
  _course_view_exam_all_questions: 'Alle vragen',
  _course_view_exam_submit: 'Antwoorden inleveren',
  _course_view_exam_submit_title: 'Antwoorden inleveren',
  _course_view_exam_submit_description:
    'Weet je zeker dat je de ingevoerde antwoorden wilt inleveren? Deze actie kan niet meer teruggezet worden',
  _course_view_exam_submit_cancel: 'Annuleren',
  _course_view_exam_submit_ok: 'Inleveren',
  _course_view_exam_submit_check: 'Antwoorden controleren',

  _course_view_final_back_to: 'Terug naar',
  _course_view_final_assessment_title: 'Eindbeoordeling',
  _course_view_final_assessment_review: 'Beoordeling',
  _course_view_final_assessment_grade: 'Cijfer',
  _course_view_final_assessment_select_student: 'Selecteer a.u.b. een student vanuit de lijst hiernaast',
  _course_view_final_assessment_saved_successfully: 'Succesvol opgeslagen',

  _course_view_student_not_allowed_title: 'Toegang verkrijgen',
  _course_view_student_not_allowed_description:
    'Schrijf je in als student voor deze onderwijseenheid of vraag toegang aan voor een herkansing of vrijstelling. Neem contact op met de beheerder voor meer informatie. Succes!',
  _course_view_following_students_want_access:
    'De volgende studenten willen toegang voor de betreffende onderwijseenheid.',
  _course_view_following_students_want_access_course_page:
    'De volgende studenten willen toegang voor de betreffende herkansing.',

  _course_view_access: 'Toegang',
  _course_view_access_grant: 'Toegang verlenen',
  _course_view_access_grant_short: 'Verlenen',
  _course_view_access_deny: 'Toegang weigeren',
  _course_view_access_deny_short: 'Weigeren',
  _course_view_access_for: 'Toegang voor',
  _course_view_access_application: 'Inschrijving',
  _course_view_access_resit: 'Herkansing',
  _course_view_access_exemption: 'Vrijstelling',
  _course_view_access_granted: 'Verleend',
  _course_view_access_denied: 'Geweigerd',
  _course_view_access_not_rated: 'Niet beoordeeld',
  _course_view_access_date: 'Datum',
  _course_view_access_last_edit: 'Laatste update',

  _course_view_request_access: 'Toegang aanvragen',
  _course_view_request_to: 'Toegang voor onderwijseenheid',

  _course_view_resit_create_title: 'Herkansing aanmaken',
  _course_view_resit_create_description: 'Weet u zeker dat u een nieuwe herkasing wilt aanmaken voor deze pagina?',
  _course_view_resit_create_cancel: 'Annuleren',
  _course_view_resit_create_ok: 'Aanmaken',

  _course_view_resit_move_title: 'Herkasing verplaatsen',
  _course_view_resit_move_description: `<b>LET OP:</b>\r\nDe bedoeling van deze functie is om de oude herkasingspagina's onder de daadwerkelijke toetspagina te brengen. \r\n\r\nEerste selectie is de herkansing zelf.\r\nTweede selectie is naar welke toetspagina het verwezen moet worden.\r\n\r\n <b style="color:red">Gebruik dit functie zorgvuldig a.u.b!</b>\r\nVerplaatsen naar een verkeerde toetspagina kan niet ongedaan worden`,
  _course_view_resit_move_cancel: 'Annuleren',
  _course_view_resit_move_ok: 'Verplaatsen',

  _course_view_resit_access_to: 'Toegang voor herkansingen',
  _course_view_course_analysis_title: 'Toetsanalyse',

  /**
   * Grades
   */
  _all_grades: 'Resultaten',
  _my_grades: 'Mijn cijferlijst',
  _dashboard: 'Dashboard',
  _my_grades_check_review: 'Beoordeling bekijken',
  _my_grades_review_not_available: 'Beoordeling niet beschikbaar',
  _my_grades_view_exemption_request: 'Bekijk aanvraag',

  /**
   * Exam
   */
  _couse_page_exam_review: 'Beoordeling',
  _couse_page_exam_review_files: 'Bestanden',

  /**
   * Students
   */
  _students_title: 'Studenten',
  _students_total: 'Totaal',
  _students_empty_message: 'Geen studenten gevonden',
  _students_first_name: 'Voornaam',
  _students_gender_male: 'Man',
  _students_gender_female: 'Vrouw',
  _students_gender_unknown: 'N.B.',

  _students_detail_studentsfile: 'Dossier',
  _students_detail_firstname: 'Voornaam',
  _students_detail_lastname: 'Achternaam',
  _students_detail_nickname: 'Roepnaam',
  _students_detail_gender: 'Geslacht',
  _students_detail_birthdate: 'Geboortedatum',
  _students_detail_address: 'Adres',
  _students_detail_zipcode: 'Postcode',
  _students_detail_city: 'Plaats',
  _students_detail_phone_number: 'Telefoonnummer',
  _students_detail_private_email: 'Privé E-mail',
  _students_detail_school_email: 'School E-mail',
  _students_detail_student_id: 'Student ID',
  _students_detail_save: 'Opslaan',
  _students_detail_user_account_not_exists_explanation:
    'Er bestaat momenteel nog geen gebruiker of google account voor deze student. U kunt hieronder aangeven om een nieuwe omgeving te creëren.',
  _students_detail_create_user_account: 'Een gebruikersomgeving creëren',
  _students_detail_create_google_account: 'Een Google account creëren',
  _students_detail_generate_environment: 'Omgeving aanmaken',

  _students_number: 'Studentennummer',
  _students_remove_image_title: 'Afbeelding verwijderen',
  _students_remove_image_description: 'Weet u zeker dat u de betreffende afbeelding wilt verwijderen?',
  _students_remove_image_cancel: 'Annuleren',
  _students_remove_image_ok: 'Verwijderen',
  _students_detail_contact_moments: 'Contactmomenten',
  _students_user_account: 'Gebruikersaccount',
  _students_user_gaccount: 'Google account',
  _students_detail_delete_user_account: 'Gebruiker Verwijderen',
  _students_detail_delete_google_account: 'Google account Verwijderen',
  _students_detail_teachers: 'SLB\'ers',
  _students_detail_no_teachers_attached: 'Geen SLB\'ers gevonden',
  _students_detail_add_teacher: 'SLB\'er toevoegen',
  _students_detail_teacher: 'SLB\'er',
  _students_detail_application: 'Aanmeldingsformulier',

  /**
   * Student contacts
   */

  _student_contacts_empty_message: 'Geen contactmomenten gevonden',
  _student_contacts_total: 'Totaal',
  _student_contacts_new: 'Nieuw contactmoment',
  _students_contacts_attachment: 'Bijlage',
  _students_contacts_type: 'Type',
  _students_contacts_mentor: 'SLB\'er',
  _students_contacts_subject: 'Onderwerp',
  _students_contacts_description: 'Omschrijving',
  _students_contacts_manage: 'Beheer',
  _students_contacts_date: 'Datum',
  _students_contacts_id: 'ID',
  _student_contact_type_oral: 'Mondeling',
  _student_contact_type_written: 'Schriftelijk',
  _student_contact_type_email: 'E-mail',
  _student_contact_type_chat: 'Chat',
  _student_contact_type_webform: 'Website formulier',
  _student_contacts_title: 'Contactmoment',
  _student_contacts_save: 'Opslaan',
  _student_contacts_back_to_student_file: 'Terug naar dossier',
  _students_add_student: 'Student toevoegen',
  _students_archived: 'Gearchiveerd',
  _students_sort_on: 'Sorteer op',
  _students_sort_name: 'Naam',
  _students_sort_id: 'StudentenID',
  _students_private_email: 'Privé E-mail',
  _students_phone_number: 'Telefoonnummer',

  /**
   * Users
   */
  _users_title: 'Gebruikers',
  _users_empty_message: 'Geen gebruikers gevonden',
  _users_total: 'Totaal',
  _users_new: 'Nieuwe gebruiker',
  _users_detail_user_id: 'Gebruiker ID',
  _users_detail_name: 'Naam',
  _users_detail_email: 'Email',
  _users_user_title: 'Gebruiker',
  _users_detail_firstname: 'Voornaam',
  _users_detail_lastname: 'Achternaam',
  _users_detail_description: 'Omschrijving',
  _users_detail_save: 'Opslaan',
  _users_detail_role: 'Gebruikersrol',
  _users_roles_title: 'Gebruikersrollen',
  _users_detail_account_not_exists_explanation:
    'Er bestaat momenteel nog geen google account voor deze account. U kunt hieronder aangeven om een Google omgeving te creëren.',
  _users_detail_create_google_account: 'Een Google account creëren',
  _users_detail_gaccount: 'Google Account',
  _users_detail_delete_google_account: 'Google account Verwijderen',
  _users_detail_generate_environment: 'Omgeving Aanmaken',
  _users_detail_password: 'Wachtwoord',
  _users_detail_account_email: 'E-mail account',

  /**
   * My Profile
   */
  _my_profile_title: 'Mijn Profiel',
  _my_profile_edit_profile: 'Profiel bijwerken',
  _my_profile_personal_information: 'Persoonlijke gegevens',
  _my_profile_password: 'Wachtwoord',
  _my_profile_current_password: 'Huidig wachtwoord',
  _my_profile_new_password: 'Nieuw wachtwoord',
  _my_profile_new_password_repeat: 'Herhaal nieuw wachtwoord',
  _my_profile_password_does_not_match: 'Wachtwoord komt niet overeen',
  _my_profile_password_not_strong: 'Wachtwoord moet minimaal 7 karakters, 1 hoofdletter en 1 cijfer bevatten',
  _my_profile_password_strength: 'Wachtwoord sterkte:',
  _my_profile_current_password_wrong: 'Huidige wachtwoord is onjuist, gegevens niet opgeslagen',
  _my_profile_password_email_already_exist: 'Een gebruiker met deze email-adress bestaat al, gegevens niet opgeslagen', // code: 409
  _my_profile_new_user_save_succesfull: 'De gegevens voor deze gebruiker zijn succesvol opgeslagen!', // code: 201
  _my_profile_user_update_succesfull: 'De gegevens voor deze gebruiker zijn succesvol geupdate!', // code: 200
  _my_profile_change_password_cause_logout:
    'Let op: Het wijzigen van je wachtwoord zorgt ervoor dat je uitgelogd wordt!',
  _my_profile_view_contact_moments: 'SLB Contactmomenten',

  /**
   * Permissions
   */

  // System languages
  _permission_new_user_role_title: 'Nieuwe gebruikersrol',
  _permission_save: 'Opslaan',
  _permission_name: 'Naam recht',
  _permission_add_new_user_role: 'Toevoegen',
  _permission_user_role_delete_title: 'Gebruikersrol Verwijderen',
  _permission_user_role_delete_description: 'Weet u zeker dat u de betreffende gebruikersrol wilt verwijderen?',
  _permission_user_role_delete_cancel: 'Annuleren',
  _permission_user_role_delete_ok: 'Verwijderen',
  _permission_users_roles: 'Rollen',

  _permission_course: 'Onderwijseenheden',
  _permission_course_create: 'Onderwijseenheid aanmaken',
  _permission_course_edit: 'Onderwijseenheid aanpassen',
  _permission_course_view: 'Onderwijseenheid inzien',
  _permission_course_delete: 'Onderwijseenheid verwijderen',
  _permission_course_page_manage: 'Pagina\'s beheren',
  _permission_course_manage_grades: 'Cijfers beheren',
  _permission_course_manage_analysis: 'Toetsanalyse beheren',

  _permission_students_contact_view: 'Contactmomenten inzien',
  _permission_students_contact_manage: 'Contactmomenten beheren',

  _permission_users: 'Gebruikers',
  _permission_user_manage: 'Beheren',
  _permission_students: 'Studenten',
  _permission_students_manage: 'Beheren',
  _permission_program: 'Opleiding',
  _permission_program_manage: 'Beheren',
  _permission_students_view: 'Inzien',
  _permission_application: 'Aanmeldingen',
  _permission_application_manage: 'Beheren',

  _permission_page: 'Pagina',
  _permission_page_manage: 'Beheren',
  _permission_page_update: 'Aanpassen',

  _permission_groups: 'Groepen',
  _permission_groups_manage: 'Beheren',
  _permission_forms: 'Formulieren',
  _permission_forms_manage: 'Beheren',
  _permission_exemptions: 'Vrijstellingen',
  _permission_exemptions_push: 'Doorzetten naar examencommissie',
  _permission_exemptions_approve: 'Goedkeuren en toekennen',
  _permission_exemptions_request_create: 'Aanvragen',
  _permission_exemptions_can_upload_file: 'Bestand uploaden',

  /**
   * Applications
   */
  _application_detail_form_type: 'Formulier',
  _application_detail_first_name: 'Voornaam',
  _application_detail_last_name: 'Achternaam',
  _application_detail_nick_name: 'Roepnaam',
  _application_detail_birth_date: 'Geboortedatum',
  _application_detail_city: 'Woonplaats',
  _application_detail_address: 'Adres',
  _application_detail_zipcode: 'Postcode',
  _application_detail_phone_number: 'Telefoonnummer',
  _application_detail_email: 'E-mail',
  _application_detail_one_time_payment: 'Eenmalige betaling',
  _application_detail_ten_period_payment: 'Periodieke betaling',
  _application_detail_iban_number: 'IBAN nummer',
  _application_detail_birth_city: 'Geboorteplaats',
  _application_detail_date: 'Datum tekening',
  _application_detail_nationality: 'Nationaliteit',
  _application_detail_city_signing: 'Plaats tekening',
  _application_detail_country: 'Land',
  _application_detail_legitimation: 'Legitimatie',
  _application_detail_diploma: 'Diploma',
  _application_detail_copy_college: 'Copy College',
  _application_detail_profile_image: 'Profielfoto',
  _application_detail_type: 'Type opleiding',
  _application_detail_date_application: 'Aanmelddatum',
  _application_detail_education: 'Opleiding',
  _application_detail_direct_debit: 'Automatisch Incasso',
  _application_detail_course_period_1: 'Periode 1',
  _application_detail_course_period_2: 'Periode 2',
  _application_detail_course_period_3: 'Periode 3',
  _application_detail_course_period_4: 'Periode 4',
  _application_detail_pre_bachelor: 'Pre-bachelor traject',
  _application_detail_other_courses: 'Overige cursussen',
  _application_delete: 'Aanmelding verwijderen',
};
