<div class="m-portlet m-portlet--mobile">
  <div class="m-portlet__head">
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
        <h3 class="m-portlet__head-text">
          Toetsanalyse herinneringen
        </h3>
      </div>
    </div>
  </div>
  <div class="m-portlet__body">
    <div class="table-responsive" *ngIf="overview">
      <ngx-datatable
        #table
        class="material min-width-700"
        [messages]="{
          emptyMessage: 'Geen herinneringen',
          totalMessage: 'Totaal'
        }"
        [rows]="overview.item.courseAnalysisReminders"
        [columnMode]="'force'"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="3"
      >
        <ngx-datatable-column name="Onderwijseenheid">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>
              <a [routerLink]="['/courses/view', row.intCourseID, 'analysis']">
                {{ row.strName }}
              </a>
            </div>

            <div *ngIf="row.objCourseAnalysis">
              <div *ngIf="!row.objCourseAnalysis.booRejected" class="labels">
                <label
                  class="m-1 p-1 badge"
                  [ngClass]="{
                    'badge-warning': !row.objCourseAnalysis.booInitialSubmit,
                    'badge-success': row.objCourseAnalysis.booInitialSubmit
                  }"
                >
                  <span *ngIf="!row.objCourseAnalysis.booInitialSubmit">Nog niet ingediend bij de examencommissie</span>
                  <span *ngIf="row.objCourseAnalysis.booInitialSubmit">Ingediend bij de examencomissie</span>
                </label>

                <label
                  *ngIf="row.objCourseAnalysis.booInitialSubmit"
                  class="m-1 p-1 badge"
                  [ngClass]="{
                    'badge-warning': !row.objCourseAnalysis.booExaminatorSubmit,
                    'badge-success': row.objCourseAnalysis.booExaminatorSubmit
                  }"
                >
                  <span *ngIf="!row.objCourseAnalysis.booExaminatorSubmit"
                    >Nog niet beoordeeld door de examencommissie</span
                  >
                  <span *ngIf="row.objCourseAnalysis.booExaminatorSubmit">Beoordeeld door de examencommissie</span>
                </label>

                <label
                  *ngIf="row.objCourseAnalysis.booExaminatorSubmit"
                  class="m-1 p-1 badge"
                  [ngClass]="{
                    'badge-warning': !row.objCourseAnalysis.booApprovalExaminator,
                    'badge-success': row.objCourseAnalysis.booApprovalExaminator
                  }"
                >
                  <span *ngIf="!row.objCourseAnalysis.booApprovalExaminator">In afwachting van de docent</span>
                  <span *ngIf="row.objCourseAnalysis.booApprovalExaminator">Aanvraag goedgekeurd</span>
                </label>
              </div>
              <div *ngIf="row.objCourseAnalysis.booRejected">
                <label class="m-1 p-1 badge badge-danger">
                  <span>Toetsanalyse afgewezen</span>
                </label>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Programma">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <small>
              <div>{{ row.strName }}</div>
              <div>{{ row.objProgramYearPeriod.strName }}</div>
              <div *ngIf="row.objProgramYearPeriod.objProgramYear">
                <div>{{ row.objProgramYearPeriod.objProgramYear.strName }}</div>
                <div>{{ row.objProgramYearPeriod.objProgramYear.objCalendarYear.strName }}</div>
                <div>{{ row.objProgramYearPeriod.objProgramYear.objProgram.strName }}</div>
              </div>
              <div *ngIf="row.objProgramYearPeriod.objProgramCourseYear">
                <div>{{ row.objProgramYearPeriod.objProgramCourseYear.strName }}</div>
                <div>{{ row.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.strName }}</div>
                <div>{{ row.objProgramYearPeriod.objProgramCourseYear.objCalendarYear.strName }}</div>
                <div>
                  {{ row.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.objProgram.strName }}
                </div>
              </div>
            </small>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
