<div class="mb-5">
  <div>
    <h4 class="color-iua mb-3">Documenten</h4>
  </div>
  <div *ngIf="canEdit" class="row mb-3">
    <div class="col-auto">
      <input class="fileSelector" #myFile type="file" name="file" ng2FileSelect [uploader]="fileUploader" />
    </div>
    <div class="col">
      <button type="button" (click)="uploadFile()" class="btn btn-primary btn-sm mr-1">
        Uploaden
      </button>

      <button type="button" (click)="resetOrder()" class="btn btn-primary btn-sm mr-1">
        Herstel sortering
      </button>

      <button type="button" (click)="saveOrder()" class="btn btn-primary btn-sm">
        Sortering opslaan
      </button>
    </div>
  </div>
  <div *ngIf="rows && rows.length > 0 " class="table-responsive">
    <table class="min-width-700" mat-table [dataSource]="dataSource">
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>Naam</th>

        <td mat-cell *matCellDef="let file">
          <a *ngIf="file.strFileFullName" [attr.href]="config.backendServerURL + 'api/uploads/files/' + ((file.path) ? file.path : '') + file.strFileFullName + '?token=' + authService.token + ((isDownload) ? '&download=true' : '')"
            target="_blank">
            <i class="fa fa-cloud-download" style="margin-right:10px;"></i>
            <span>{{ (file.originalname) ? file.originalname : file.strFileFullName}}</span>
          </a>

          <a *ngIf="!file.strFileFullName" [attr.href]="config.backendServerURL + 'api/uploads/files/' + file.strFileName + '?token=' + authService.token + '&download=true'"
            target="_blank">
            <i class="fa fa-cloud-download" style="margin-right:10px;"></i>
            <span>{{ file.strFileName }}</span>
          </a>

        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="uploader">
        <th mat-header-cell *matHeaderCellDef>Door</th>

        <td mat-cell *matCellDef="let file">
          <small *ngIf="file.objCreateUser">
            {{file.objCreateUser.strFirstName}} {{file.objCreateUser.strLastName}}<br />
            {{file.createdAt | date:'dd-MM-yyyy HH:mm'}}

          </small>
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container *ngIf="canEdit" matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef>Sortering</th>

        <td mat-cell *matCellDef="let file">
          <mat-form-field style="width:40px;">
            <input matInput number [(ngModel)]="file.intOrder">
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Grootte</th>

        <td mat-cell *matCellDef="let file">
          <small *ngIf="file.size > 0">{{file.size | fileSize}}</small>
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container *ngIf="canEdit" matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let file">
          <small>
            <i (click)="removeFile(file)" style="font-size:8pt;margin-right:10px;cursor: pointer;" class="flaticon-delete-2 text-danger ng-star-inserted"></i>
          </small>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[15]" showFirstLastButtons></mat-paginator>
  </div>
  <div *ngIf="!rows || rows.length == 0">
    Geen documenten gevonden
  </div>
</div>

<app-confirmdialog #appConfirmDialogDelete title="{{ '_core_delete_title' | translate }}" description="{{ '_core_delete_description' | translate }}"
  btncancel="{{ '_core_delete_cancel' | translate }}" btnok="{{ '_core_delete_ok' | translate }}"></app-confirmdialog>