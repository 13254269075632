import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { File } from '../../../_models/core/file';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MainConfig } from '../../../_helpers';
import { AuthenticationService } from '../../../_services';
import { AppConfirmdialogComponent } from '../confirmdialog/confirmdialog.component';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: '<app-files-manager></app-files-manager>',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class AppFilesManageComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('appConfirmDialogDelete', { static: true }) appConfirmDialogDelete: AppConfirmdialogComponent;
  @ViewChild('myFile') myFile: ElementRef;

  @Input('files') rows: File[] = [];
  @Input('canEdit') canEdit: boolean = true;
  @Input('isDownload') isDownload: boolean = false;

  @Output('uploadFile') fileUploadClicked = new EventEmitter<FileUploader>();
  @Output('deleteFile') deleteFileClicked = new EventEmitter<File>();
  @Output('reloadList') reloadList = new EventEmitter<boolean>();

  fileUploader: FileUploader = new FileUploader({ url: '' });
  displayedColumns: string[] = ['position', 'order', 'uploader', 'name', 'delete'];
  dataSource: MatTableDataSource<File>;

  constructor(
    private config: MainConfig,
    private changeDetectorRefs: ChangeDetectorRef,
    private authService: AuthenticationService,
    private coreService: CoreService
  ) {

  }

  ngOnInit() {
    if (!this.canEdit) {
      this.displayedColumns = ['position', 'uploader', 'name'];
    }
    this.initData();
  }

  uploadFile() {
    if (this.canEdit) {
      this.fileUploadClicked.emit(this.fileUploader);
      this.myFile.nativeElement.value = '';
    }
  }

  initData() {
    this.dataSource = new MatTableDataSource<File>(this.rows);
    this.dataSource.paginator = this.paginator;
    this.changeDetectorRefs.detectChanges();
  }

  removeFile(file: File) {
    this.appConfirmDialogDelete.openModal(() => {
      this.deleteFileClicked.emit(file);
    });
  }

  resetOrder() {
    if (!this.canEdit) {
      return;
    }

    const fileOrders = [];

    this.rows.forEach((file, index) => {
      fileOrders.push({
        intFileID: file.intFileID,
        intOrder: index
      });
    });

    this.coreService.saveFileOrders(fileOrders).subscribe((result) => {
      this.reloadList.emit(true);
    }, (error) => console.log(error));
  }

  saveOrder() {
    if (!this.canEdit) {
      return;
    }

    const fileOrders = [];
    this.rows.forEach((file) => {
      fileOrders.push({
        intFileID: file.intFileID,
        intOrder: file.intOrder
      });
    });

    this.coreService.saveFileOrders(fileOrders).subscribe((result) => {
      this.reloadList.emit(true);
    }, (error) => console.log(error));
  }
}
