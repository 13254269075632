// app/translate/translate.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';
import { Course } from '../_models/course/course';

@Pipe({
  name: 'courseName',
  pure: false,
})
export class CourseNamePipe implements PipeTransform {
  constructor() {}

  transform(objCourse: Course, args?: any[]): any {
    const labels: string[] = [];
    if (objCourse.objProgramYearPeriod.objProgramCourseYear) {
      labels.push(`${objCourse.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.objProgram.strName}`);
      labels.push(`${objCourse.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.strName}`);
      labels.push(
        `${objCourse.objProgramYearPeriod.objProgramCourseYear.strName} <small>(${objCourse.objProgramYearPeriod.objProgramCourseYear.objCalendarYear.strName})</small>`
      );
      labels.push(`${objCourse.objProgramYearPeriod.strName}`);
    } else if (objCourse.objProgramYearPeriod.objProgramYear) {
      labels.push(`${objCourse.objProgramYearPeriod.objProgramYear.objProgram.strName}`);
      labels.push(
        `${objCourse.objProgramYearPeriod.objProgramYear.objCalendarYear.strName} <small>(${objCourse.objProgramYearPeriod.objProgramYear.strName})</small>`
      );
      labels.push(`${objCourse.objProgramYearPeriod.strName}`);
    }
    return labels.map((label) => `<div>${label}</div>`).join('');
  }
}
