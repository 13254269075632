import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '../../../../shared/translate';
import { CourseStudentTypeEnum } from '../../../../_models/course/coursestudenttypeenum';
import { TeacherOverviewResponse } from '../../../../_models/responses/overview/teacher.overview';
import { Student } from '../../../../_models/student/student';
import { CourseService } from '../../../../_services/course.service';
import { GlobalMessageHandler, GlobalMessageType } from '../../../../_services/message-handler.service';

@Component({
  selector: 'app-overview-teacher-course-view-request',
  templateUrl: './course-view-request.component.html',
  styleUrls: ['./course-view-request.component.scss'],
})
export class TeacherOverviewCourseViewRequestComponent implements OnInit {
  @Input() overview: TeacherOverviewResponse;
  @Output('viewUpdated') viewUpdated: EventEmitter<void> = new EventEmitter<void>();
  CourseStudentTypeEnum = CourseStudentTypeEnum;
  constructor(
    public translate: TranslateService,
    public courseService: CourseService,
    private gmHandler: GlobalMessageHandler
  ) {}

  GlobalMessageType = GlobalMessageType;

  ngOnInit() {}

  grantAccess(intCourseID: number, intStudentID: number, grantAccess: boolean) {
    this.courseService.grantAccess(intCourseID, intStudentID, grantAccess).subscribe(
      () => {
        this.gmHandler.handleMessage([
          { message: this.translate.instant('_core_saved_successfully'), type: GlobalMessageType.SUCCESS },
        ]);
        this.viewUpdated.emit();
      },
      (error) => this.gmHandler.handleError(error)
    );
  }
}
