<!--begin: Search Form -->
<div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
  <div class="row align-items-center">
    <div class="col-12">
      <div class="form-group m-form__group align-items-center">
        <div class="m-input-icon m-input-icon--left">
          <input
            type="text"
            class="form-control m-input m-input--solid data-filter"
            placeholder="{{ '_filter' | translate }}"
            id="generalSearch"
            (keyup)="updateFilter($event)"
            [(ngModel)]="searchValue"
          />

          <span class="m-input-icon__icon m-input-icon__icon--left">
            <span>
              <i class="la la-search"></i>
            </span>
          </span>
        </div>
        <div class="col-auto">
          <div class="row no-gutters">
            <div class="col-auto p-3 mt-3">
              <mat-checkbox (change)="clearPage() && getStudents(page)" [(ngModel)]="page.custom.isArchived">
                {{ '_students_archived' | translate }}
              </mat-checkbox>
            </div>
            <div class="col-auto p-3">
              <mat-form-field>
                <mat-label>{{ '_students_sort_on' | translate }}</mat-label>
                <mat-select [(ngModel)]="page.custom.strOrder" (selectionChange)="getStudents(page)">
                  <mat-option value="strName">{{ '_students_sort_name' | translate }}</mat-option>
                  <mat-option value="intStudentID">{{ '_students_sort_id' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end: Search Form -->

<div class="table-responsive">
  <ngx-datatable
    #table
    [messages]="messages"
    class="material min-width-700"
    [rows]="rows"
    [columnMode]="'force'"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [selected]="selected"
    [selectionType]="'checkbox'"
    (select)="onSelect($event)"
    [externalPaging]="searchValue == null || searchValue == ''"
    [count]="page.count"
    [offset]="page.pageNumber"
    [limit]="page.limit"
    (page)="setPage($event)"
  >
    <ngx-datatable-column name="{{ '_students_detail_student_id' | translate }}">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <b>{{ row.intStudentID }}</b>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{ '_students_first_name' | translate }}">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="onStudentSelected.emit(row)">
          {{ row.strFirstName }} {{ row.strLastName }}
        </a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{ '_students_private_email' | translate }}">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a [attr.href]="'mailto:' + row.strPrivateEmail" target="_blank">
          {{ row.strPrivateEmail }}
        </a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{ '_students_phone_number' | translate }}">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="onStudentSelected.emit(row)">
          {{ row.strPhoneNumber }}
        </a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{ '_students_detail_address' | translate }}">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="onStudentSelected.emit(row)">
          <small>{{ row.strAddress }} {{ row.strZipCode }} {{ row.strCity }}</small>
        </a>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<!--end: Datatable -->
