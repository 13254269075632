import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthRoutingModule } from './auth-routing.routing';
import { AuthComponent } from './auth.component';
import { AlertComponent } from './_directives/alert.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthGuard } from './_guards/auth.guard';
import { AlertService } from './_services/alert.service';
import { UserService } from './_services/user.service';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '../shared/translate/translate.module';

const APP_PROVIDERS = [AuthGuard, AlertService, UserService];

@NgModule({
  declarations: [AuthComponent, AlertComponent, LogoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AuthRoutingModule,
    SharedModule,
    // api backend simulation
    TranslateModule.forRoot(),
  ],
  providers: [APP_PROVIDERS],
})
export class AuthModule {}
