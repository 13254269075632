<!-- Child component -->
<form #f="ngForm" (ngSubmit)="f.form.valid && submitMessage()" class="m-form">
  <!-- Motivatie -->
  <div class="row p-2">
    <div class="col">
      <mat-form-field class="example-full-width">
        <textarea
          name="strMessage"
          [(ngModel)]="requestMessage.strMessage"
          required
          cdkTextareaAutosize
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="15"
          #autosize="cdkTextareaAutosize"
          matInput
          placeholder="{{ '_exemption_request_message' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <!-- Message add form -->
  <div class="row p-2">
    <div class="col">
      <button type="submit" class="btn btn-primary pull-right">
        {{ '_exemption_request_message_add_button' | translate }}
      </button>
    </div>
  </div>

  <div *ngFor="let message of objCourseAnalysis.messages" class="border-bottom rounded row p-5">
    <div class="col-auto">
      <b>{{ message.objCreateUser.strFirstName }} {{ message.objCreateUser.strLastName }}</b>
    </div>
    <div class="col-auto">
      <b>{{ message.createdAt | date : 'dd-MM-yyyy HH:mm' }}</b>
    </div>
    <div class="col" [innerHTML]="message.strMessage | nl2br : true"></div>
  </div>
</form>
