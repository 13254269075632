import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MainDatatableComponent, ServerPage } from '../../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { MainConfig } from '../../../_helpers';
import { TranslateService } from '../../../shared/translate';
import { StudentService } from '../../../_services/student.service';
import { StudentContact } from '../../../_models/student/studentcontact';
import { Student } from '../../../_models/student/student';
import { AppUtils } from '../../../_helpers/utils';
import { PlatformLocation } from '@angular/common';
import { AuthenticationService, UserPermissionEnum } from '../../../_services';

@Component({
  selector: 'app-student-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class AppStudentContactComponent extends MainDatatableComponent implements OnInit {
  @Input('redirectPage') redirectPage: boolean = false;
  @Input('mainRedirectURL') mainRedirectURL: string = null;
  @Input('student') student: Student;
  @Output('studentContactDelete') studentContactDelete: EventEmitter<StudentContact> = new EventEmitter<StudentContact>();

  constructor(
    route: ActivatedRoute,
    router: Router,
    config: MainConfig,

    translate: TranslateService,
    private studentService: StudentService,
    private authService: AuthenticationService,
    public appUtils: AppUtils
  ) {
    super(route, router, config, '', { itemSelected: '', itemsSelected: '' }, translate);


    // Init datatable messages
    this.messages = {
      emptyMessage: this.translate.instant('_student_contacts_empty_message'),
      totalMessage: this.translate.instant('_student_contacts_total')
    };
  }

  ngOnInit() {
    this.navigateUrl = false;

    this.page.limit = 4;
    this.setListFn(this.getStudentContacts);
    this.setPage({ offset: this.urlPageNumber });

    this.route.params.subscribe((params) => {
      if (this.mainRedirectURL !== null) {
        this.mainURL = this.mainRedirectURL;
      } else {
        this.mainURL = '/students/view/' + this.student.intStudentID + '/contact';
      }
    });
  }

  getStudentContacts(page: ServerPage) {
    this.loading = true;

    this.studentService.getStudentContacts(page, this.student.intStudentID).subscribe((result) => {
      this.temp = [...result.rows];
      this.rows = <Array<StudentContact>>result.rows;
      this.loading = false;
      this.page.count = result.count;
    });
  }

  deleteStudentContact(studentContact: StudentContact) {
    this.studentContactDelete.emit(studentContact);
  }

  userCanManageContactMoment() {
    if (this.student && this.student.teachers && this.student.teachers.length > 0) {
      let output = false;
      this.student.teachers.forEach((teacher) => {
        if (teacher.intUserID == this.authService.user.intUserID) {
          output = true;
        }
      });

      if (output) {
        return output;
      }
    }
    return this.authService.hasPermission(UserPermissionEnum.STUDENTS_CONTACT_MANAGE);
  }
}
