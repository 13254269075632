import { HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Helpers } from '../helpers';
import { TeacherOverviewResponse } from '../_models/responses/overview/teacher.overview';
import { MainService } from './main.service';
import { GlobalMessageHandler } from './message-handler.service';

@Injectable()
export class OverviewService extends MainService {
  private _events: EventEmitter<TeacherOverviewResponse> = new EventEmitter();
  private _totalOverviewTeacherNotificationCount: number = 0;

  get events(): EventEmitter<TeacherOverviewResponse> {
    return this._events;
  }

  get totalOverviewTeacherNotificationCount() {
    return this._totalOverviewTeacherNotificationCount;
  }

  getTeacherOverview() {
    const url = `${this.mainConfig.backendServerURL}api/overview/teacher`;
    return this.http.get<TeacherOverviewResponse>(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<TeacherOverviewResponse>) => {
        Helpers.setLoading(false);
        this._totalOverviewTeacherNotificationCount = response.body.item.intCount;
        this._events.emit(response.body);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  getAllOverviews() {
    this.getTeacherOverview().subscribe();
  }
}
