import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AlertService } from './_services/alert.service';
import { UserService } from './_services/user.service';
import { AlertComponent } from './_directives/alert.component';
import { Helpers } from '../helpers';
import { AuthenticationService } from '../_services';
import { TranslateService } from '../shared/translate';

declare let $: any;
declare let mUtil: any;

@Component({
  selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
  templateUrl: 'auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;

  @ViewChild('alertSignin', { read: ViewContainerRef, static: true }) alertSignin: ViewContainerRef;
  @ViewChild('alertSignup', { read: ViewContainerRef }) alertSignup: ViewContainerRef;
  @ViewChild('alertForgotPass', { read: ViewContainerRef }) alertForgotPass: ViewContainerRef;

  constructor(
    private _router: Router,
    private _script: ScriptLoaderService,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _alertService: AlertService,
    private cfr: ComponentFactoryResolver,
    private translate: TranslateService
  ) {}
  ngOnInit() {
    this.model.remember = true;
    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    this._router.navigate([this.returnUrl]);

    this._script
      .loadScripts(
        'body',
        ['assets/vendors/base/vendors.bundle.js', 'assets/demo/default/base/scripts.bundle.js'],
        true
      )
      .then(() => {
        Helpers.setLoading(false, true);
        this.handleSignInFormSubmit();
      });

    Helpers.setLoading(false, true);
  }

  signin() {
    this.loading = true;
    this._authService.login(this.model.email, this.model.password).subscribe(
      (data) => {
        this._router.navigate([this.returnUrl]);
      },
      (error) => {
        console.log(error);
        Helpers.setLoading(false, true);
        this.showAlert('alertSignin');
        if (error.status == 400) {
          this._alertService.error(this.translate.instant('_core_username_or_password_wrong'));
        } else {
          this._alertService.error(error);
        }

        this.loading = false;
      }
    );
  }

  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  handleSignInFormSubmit() {
    $('#m_login_signin_submit').click((e) => {
      const form = $(e.target).closest('form');
      form.validate({
        rules: {
          email: {
            required: true,
            email: true,
          },
          password: {
            required: true,
          },
        },
      });
      if (!form.valid()) {
        e.preventDefault();
        return;
      }
    });
  }

  displaySignUpForm() {
    const login = document.getElementById('m_login');
    mUtil.removeClass(login, 'm-login--forget-password');
    mUtil.removeClass(login, 'm-login--signin');

    mUtil.addClass(login, 'm-login--signup');
    mUtil.animateClass(login.getElementsByClassName('m-login__signup')[0], 'flipInX animated');
  }

  displaySignInForm() {
    const login = document.getElementById('m_login');
    mUtil.removeClass(login, 'm-login--forget-password');
    mUtil.removeClass(login, 'm-login--signup');
    try {
      $('form').data('validator').resetForm();
    } catch (e) {}

    mUtil.addClass(login, 'm-login--signin');
    mUtil.animateClass(login.getElementsByClassName('m-login__signin')[0], 'flipInX animated');
  }

  displayForgetPasswordForm() {
    const login = document.getElementById('m_login');
    mUtil.removeClass(login, 'm-login--signin');
    mUtil.removeClass(login, 'm-login--signup');

    mUtil.addClass(login, 'm-login--forget-password');
    mUtil.animateClass(login.getElementsByClassName('m-login__forget-password')[0], 'flipInX animated');
  }
}
