import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { MainService } from './main.service';
import { ServerPage } from '../_models';
import { Course } from '../_models/course/course';

import { catchError, map } from 'rxjs/operators';
import { Helpers } from '../helpers';
import { CoursePage } from '../_models/course/coursepage';
import { FileUploader } from 'ng2-file-upload';
import { File } from '../_models/core/file';
import { CoursePageExam } from '../_models/course/coursepageexam';
import { CoursePageExamQuestion } from '../_models/course/exam';
import { CoursePageExamRating } from '../_models/course/coursepageexamrating';
import { CoursePageExamSubmitReview } from '../_models/course/coursepageexamsubmitreview';
import { CourseAssessment } from '../_models/course/courseassessment';
import { CoursePageExamSubmit } from '../_models/course/coursepageexamsubmit';
import { HttpResponse } from '@angular/common/http';

import { ADForm } from './../_models/form/form';
import { CoursePageExamSubmitPaper } from '../_models/course/coursepageexamsubmitpaper';
import { CourseExemptionRequest } from '../_models/course/courseexemptionrequest';
import { BreadCrumb } from '../_models/core/breadcrumb';

@Injectable()
export class CourseService extends MainService {
  /**
   * Course Page Resits
   */
  applyForResit(coursePage: CoursePage): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/resit/apply', coursePage, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  changeResitAccess(coursePageStudent: { intCoursePageStudentID: number; booIsAllowed: boolean }): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/resit/access/student', coursePageStudent, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  createResitPage(coursePage: CoursePage): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/resit/create', coursePage, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  moveResitPage(coursePageMove: CoursePage, coursePageTo: CoursePage): Observable<any> {
    Helpers.setLoading(true);
    const options = {
      intCoursePageMoveID: coursePageMove.intCoursePageID,
      intCoursePageToID: coursePageTo.intCoursePageID,
    };
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/resit/move', options, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  attachResit(intCoursePageExamID: number, intCoursePageExamParentID: number) {
    Helpers.setLoading(true);
    const payload = { intCoursePageExamID: intCoursePageExamID, intCoursePageExamParentID: intCoursePageExamParentID };
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/resit/attach', payload, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  /**
   * Course Exemption Request
   */

  submitExemptionRequest(cer: CourseExemptionRequest): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/exemption', cer, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }
  getExemptionRequests(page: ServerPage = null, exclude: [number] = null): Observable<any> {
    return this.getList('api/course/exemption', null, page, exclude);
  }
  getExemptionRequest(intCourseExemptionRequestID: number): Observable<any> {
    return this.getItem('api/course/exemption', intCourseExemptionRequestID);
  }

  /**
   * Courses
   */
  getAllCourses(limit = 250, intProgramYearPeriodID = null): Observable<any> {
    return this.getAllList(limit, 'getCourses', intProgramYearPeriodID);
  }

  getCourses(page: ServerPage = null, exclude: [number] = null, intProgramYearPeriodID): Observable<any> {
    let params = null;
    if (intProgramYearPeriodID !== null) {
      params = 'intProgramYearPeriodID=' + intProgramYearPeriodID;
    }
    return this.getList('api/course', params, page, exclude);
  }

  getCourse(intCourseID, type?: string): Observable<any> {
    switch (type) {
      case 'exemption-request':
        return this.getItem('api/course', intCourseID + '/exemption-request');
      case 'basic':
        return this.getItem(`api/course/${intCourseID}/basic`);
    }

    return this.getItem('api/course', intCourseID);
  }

  saveCourse(course: Course, intProgramYearPeriodID: number): Observable<any> {
    // this.showSaving(".main-page");
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/course',
        {
          intCourseID: course.intCourseID,
          intProgramYearPeriodID: intProgramYearPeriodID,
          strName: course.strName,
          intECPoints: course.intECPoints,
          douCaesura: course.douCaesura,
          minGradeCompensatedExam: course.minGradeCompensatedExam,

          // We need to remove the custom added token to get the file behind secured URL
          strDescription1: course.strDescription1 ? course.strDescription1.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription2: course.strDescription2 ? course.strDescription2.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription3: course.strDescription3 ? course.strDescription3.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription4: course.strDescription4 ? course.strDescription4.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription5: course.strDescription5 ? course.strDescription5.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription6: course.strDescription6 ? course.strDescription6.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription7: course.strDescription7 ? course.strDescription7.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription8: course.strDescription8 ? course.strDescription8.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription9: course.strDescription9 ? course.strDescription9.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription10: course.strDescription10 ? course.strDescription10.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription11: course.strDescription11 ? course.strDescription11.replace(/\?token=(.*?)&1=1/g, '') : '',
          strDescription12: course.strDescription12 ? course.strDescription12.replace(/\?token=(.*?)&1=1/g, '') : '',

          datLastEditDate: course.datLastEditDate,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  getCourseFiles(page: ServerPage = null, exclude: [number] = null, intCourseID): Observable<any> {
    let params = null;
    if (intCourseID !== null) {
      params = 'intCourseID=' + intCourseID;
    }
    return this.getList('api/course/files', params, page, exclude);
  }

  addTeacher(course: Course, intUserID: number): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/course/' + course.intCourseID + '/teacher',
        {
          intUserID: intUserID,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  removeTeacher(course: Course, intUserID: number): Observable<any> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + 'api/course/' + course.intCourseID + '/teacher/' + intUserID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  requestAccess(intCourseID: number, strType: string): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/course/' + intCourseID + '/access',
        {
          strType: strType,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }
  grantAccess(intCourseID: number, intStudentID: number, booAccess = true): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/course/' + intCourseID + '/access/grant/' + intStudentID,
        { booAccess: booAccess },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  /**
   * Course Pages
   */
  saveCoursePage(coursePage: CoursePage): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page', coursePage, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  deleteCoursePage(intCoursePageID: number): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/course/page/' + intCoursePageID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  getCoursePage(intCoursePageID: number): Observable<any> {
    return this.getItem('api/course/page', intCoursePageID);
  }

  uploadCoursePageFile(params: any, fileUploader: FileUploader): Observable<any> {
    Helpers.setLoading(true);
    const observable = new Observable((observer) => {
      const options = {
        url: this.mainConfig.backendServerURL + 'api/course/page/file',
        authToken: 'Bearer ' + this.authenticationService.token,
      };
      if (params) {
        options['additionalParameter'] = params;
      }
      fileUploader.setOptions(options);
      if (fileUploader.queue.length > 0) {
        const item = fileUploader.queue[0];
        fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          const newResponse = new HttpResponse({
            status: status,
            statusText: response.message,
            url: item.url,
            body: response,
          });
          Helpers.setLoading(false);
          fileUploader.clearQueue();
          observer.next(newResponse.body);
        };
        item.upload();
      } else {
        observer.next(null);
      }
    });
    return observable;
  }

  deleteCoursePageFile(file: File): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/course/page/file/' + file.intFileID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  /**
   * Course Page Exam
   */
  saveCoursePageExam(coursePageExam: CoursePageExam): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/exam', coursePageExam, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  getCoursePageExam(intCoursePageExamID: number): Observable<any> {
    return this.getItem('api/course/page/exam/', intCoursePageExamID);
  }

  deleteCoursePageExam(coursePageExam: CoursePageExam): Observable<any> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + 'api/course/page/exam/' + coursePageExam.intCoursePageExamID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  uploadCoursePageExamFile(params: any, fileUploader: FileUploader): Observable<any> {
    Helpers.setLoading(true);
    const observable = new Observable((observer) => {
      const options = {
        url: this.mainConfig.backendServerURL + 'api/course/page/exam/file',
        authToken: 'Bearer ' + this.authenticationService.token,
      };
      if (params) {
        options['additionalParameter'] = params;
      }
      fileUploader.setOptions(options);
      if (fileUploader.queue.length > 0) {
        const item = fileUploader.queue[0];
        fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          const newResponse = new HttpResponse({
            status: status,
            statusText: response.message,
            url: item.url,
            body: response,
          });
          Helpers.setLoading(false);
          fileUploader.clearQueue();
          observer.next(newResponse.body);
        };
        item.upload();

        fileUploader.onErrorItem = (item: any, response: string, status: any, headers: any) => {
          console.log(response);
          Helpers.setLoading(false);
        };
      } else {
        Helpers.setLoading(false);
        fileUploader.clearQueue();
        observer.next(null);
      }
    });
    return observable;
  }

  launchTIN(body: any): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/exam/turnitin', body, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  launchTINPaper(paper: CoursePageExamSubmitPaper): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .get(
        this.mainConfig.backendServerURL + 'api/course/page/exam/review/paper/' + paper.intCoursePageExamSubmitPaperID,
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  deleteCoursePageExamFile(file: File): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/course/page/exam/file/' + file.intFileID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  attachStudentToCoursePageExam(intStudentID: number, intCoursePageExamID: number): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/course/page/exam/submit/student',
        { intStudentID: intStudentID, intCoursePageExamID: intCoursePageExamID },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  deleteCoursePageExamSubmit(coursePageExamSubmit: CoursePageExamSubmit): Observable<any> {
    Helpers.setLoading(true);
    const url =
      this.mainConfig.backendServerURL +
      'api/course/page/exam/submit/' +
      coursePageExamSubmit.intCoursePageExamSubmitID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  uploadCoursePageExamSubmitFile(params: any, fileUploader: FileUploader): Observable<any> {
    Helpers.setLoading(true);
    const observable = new Observable((observer) => {
      const options = {
        url: this.mainConfig.backendServerURL + 'api/course/page/exam/submit/file',
        authToken: 'Bearer ' + this.authenticationService.token,
      };
      if (params) {
        options['additionalParameter'] = params;
      }
      fileUploader.setOptions(options);
      if (fileUploader.queue.length > 0) {
        const item = fileUploader.queue[0];
        fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          const newResponse = new HttpResponse({
            status: status,
            statusText: response.message,
            url: item.url,
            body: response,
          });
          Helpers.setLoading(false);
          fileUploader.clearQueue();
          observer.next(newResponse.body);
        };
        item.upload();

        fileUploader.onErrorItem = (item: any, response: string, status: any, headers: any) => {
          console.log(response);
          Helpers.setLoading(false);
        };
      } else {
        Helpers.setLoading(false);
        fileUploader.clearQueue();
        observer.next(null);
      }
    });
    return observable;
  }

  deleteCoursePageExamSubmitFile(file: File): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/course/page/exam/submit/file/' + file.intFileID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  /**
   * Course Page Exam Questions
   */
  saveCoursePageExamQuestion(coursePageExamQuestion: CoursePageExamQuestion): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/exam/question', coursePageExamQuestion, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  getCoursePageForExam(intCoursePageID: number, intCoursePageExamID: number): Observable<any> {
    return this.getItem('api/course/page/exam/take', intCoursePageID + '/' + intCoursePageExamID);
  }

  submitCoursePageExamQuestion(coursePageExamQuestion: CoursePageExamQuestion): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/exam/submit', coursePageExamQuestion, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  /**
   * Course Page Exam Ratings
   */
  saveCoursePageExamRating(coursePageExamRating: CoursePageExamRating): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/exam/rating', coursePageExamRating, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  saveCoursePageExamSubmitReview(
    coursePageExamSubmitReview: CoursePageExamSubmitReview,
    isFinalReview: boolean
  ): Observable<any> {
    Helpers.setLoading(true);
    coursePageExamSubmitReview.isFinalReview = isFinalReview;
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/page/exam/review', coursePageExamSubmitReview, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  /**
   * Course review
   */

  getCoursePageForReview(ids: {
    intCoursePageID: number;
    intCoursePageExamID: number;
    intStudentID: number;
    intCoursePageExamSubmitID: number;
  }): Observable<any> {
    return this.getItem(
      'api/course/page/exam/review',
      ids.intCoursePageID +
        '/exam/' +
        ids.intCoursePageExamID +
        '/student/' +
        ids.intStudentID +
        '/submission/' +
        ids.intCoursePageExamSubmitID
    );
  }

  getFinalAssessmentStudents(intCourseID: number) {
    return this.getItem('api/course/' + intCourseID + '/final-assessment/student');
  }

  setFinalAssessment(intCourseID: number, courseAssessment: CourseAssessment) {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/course/' + intCourseID + '/final-assessment/submit',
        courseAssessment,
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  setCourseExamInsight(intCoursePageExamID: number, booViewReviewStudent: boolean) {
    Helpers.setLoading(true);

    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/course/page/exam/insight',
        {
          intCoursePageExamID: intCoursePageExamID,
          booViewReviewStudent: booViewReviewStudent,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 201) {
            return { code: 201 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  /**
   * Course Files
   */
  uploadCourseFile(params: any, fileUploader: FileUploader): Observable<any> {
    Helpers.setLoading(true);

    const observable = new Observable((observer) => {
      const options = {
        url: this.mainConfig.backendServerURL + 'api/course/file',
        authToken: 'Bearer ' + this.authenticationService.token,
      };
      if (params) {
        options['additionalParameter'] = params;
      }
      fileUploader.setOptions(options);
      if (fileUploader.queue.length > 0) {
        const item = fileUploader.queue[0];
        fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          const newResponse = new HttpResponse({
            status: status,
            statusText: response.message,
            url: item.url,
            body: response,
          });
          Helpers.setLoading(false);
          fileUploader.clearQueue();
          observer.next(newResponse.body);
        };
        item.upload();

        fileUploader.onErrorItem = (item: any, response: string, status: any, headers: any) => {
          console.log(response);
          Helpers.setLoading(false);
        };
      } else {
        Helpers.setLoading(false);
        fileUploader.clearQueue();
        observer.next(null);
      }
    });
    return observable;
  }

  deleteCourseFile(file: File): Observable<any> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + 'api/course/file/' + file.intFileID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  getCourseExemption(): Observable<any> {
    return this.getItem('api/course/exemptions');
  }

  getCourseExemptionItem(courseExemptionId: number): Observable<any> {
    return this.getItem('api/course/exemptions/detail/' + courseExemptionId);
  }

  getAllForms(limit = 250): Observable<any> {
    return this.getAllList(limit, 'getForms');
  }

  getForms(page: ServerPage = null, exclude: [number] = null): Observable<any> {
    return this.getList('api/form', null, page, exclude);
  }

  deleteForm(form: ADForm): Observable<any> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + 'api/form/' + form.intFormID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  // setForm(strName: string){
  //     Helpers.setLoading(true);

  //     return this.http.post(this.mainConfig.backendServerURL + 'api/form', {
  //         strName: strName
  //     }, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
  //         //this.hideLoading(".main-page");
  //         Helpers.setLoading(false);
  //         //this.showSaved();

  //         if (response.status === 201) {
  //             return { code: 201 }
  //         }
  //         return response.body;

  //     }), catchError(error => this.unAuthorizedHandler(error)));

  // }

  getForm(intFormID: number) {
    return this.getItem('api/form/' + intFormID);
  }

  getFilters(): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .get(this.mainConfig.backendServerURL + 'api/form/filter', { headers: this.headers, observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  saveForm(form: ADForm): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/form', form, { headers: this.headers, observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  getBreadcrumbs(intCourseID: number): Observable<{ code: number; item: { breadCrumbs: BreadCrumb[] } }> {
    return this.getItem(`api/course/${intCourseID}/breadcrumbs`);
  }

  getCourseAnalysis(intCourseID: number): Observable<any> {
    return this.getItem('api/course/analysis', intCourseID);
  }

  getCourseAnalyses(page: ServerPage = null): Observable<any> {
    return this.getList('api/course/analyses', null, page);
  }

  saveCourseAnalysis(intCourseID, body = {}) {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/course/analysis/' + intCourseID, body, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  submitCourseAnalysis(intCourseID: number, command: string = null) {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/course/analysis/' + intCourseID + '/submit',
        { command },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  getCourseApplications(page: ServerPage = null, exclude: [number] = null): Observable<any> {
    return this.getList('api/course/applications', null, page, exclude);
  }

  setCourseApplication(intCourseStudentID: number, booAccess: boolean, comment?: string) {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/course/applications',
        { intCourseStudentID, booAccess, comment },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }
}
