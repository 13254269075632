import { MainService } from './main.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Helpers } from '../helpers';
import { catchError, map } from 'rxjs/operators';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { CalendarYear } from '../_models/core/calendaryear';
import { Program } from '../_models/program/program';
import { ProgramCourseYear } from '../_models/program/programcourseyear';
import { ProgramYear } from '../_models/program/programyear';
import { ProgramCourse } from '../_models/program/programcourse';
import { ProgramYearPeriod } from '../_models/program/programyearperiod';
import { Course } from '../_models/course/course';
import { CoursePageExam } from '../_models/course/coursepageexam';

@Injectable()
export class CourseGradesService extends MainService {
  getOptions(query: HttpParams): Observable<{
    code: number;
    item: {
      type: string;
      calendarYears: CalendarYear[];
      programs: Program[];
      programCourses: ProgramCourse[];
      programCourseYears: ProgramCourseYear[];
      programYears: ProgramYear[];
      programYearPeriods: ProgramYearPeriod[];
      courses: Course[];
      exams: CoursePageExam[];
    };
  }> {
    Helpers.setLoading(true);
    return this.http
      .get(`${this.mainConfig.backendServerURL}api/course/grades/options`, {
        headers: this.headers,
        observe: 'response',
        params: query,
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }
}
