import { TranslateModule } from './translate/translate.module';
import { NgModule, ModuleWithProviders } from '@angular/core';

// Custom imports
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StudentService } from '../_services/student.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ngx-bootstrap4
// http://valor-software.com/ngx-bootstrap/index-bs4.html#/
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';

// Angular Material
// https://material.angular.io/
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppConfirmdialogComponent } from './components/confirmdialog/confirmdialog.component';
import { UserService } from '../_services/user.service';
import { AppStudentContactComponent } from '../pages/student/contact/contact.component';
import { DatePipe, CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProgramService } from '../_services/program.service';
import { CourseService } from '../_services/course.service';
import { CoreService } from '../_services/core.service';
import { AppFilesManageComponent } from './components/files/files.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FileSizePipe } from '../_pipes/fileSize';
import { getDutchPaginatorIntl } from '../_helpers/dutch-paginator-intl';
import { CountDownComponent } from './components/countdown/countdown';
import { CourseGradesService } from '../_services/course-grades.service';
import { CourseExamGroupService } from '../_services/course.exam.group.service';
import { CourseFinalGradeService } from '../_services/course/final-grade.service';
import { CourseBreadcrumbsComponent } from './course-breadcrumbs/course-breadcrumbs.component';
import { StudentsDatatableComponent } from './components/students-datatable/students-datatable.component';
import { StudentEducationGradesComponent } from './components/student-education-grades/student-education-grades.component';
import { TeacherOverviewComponent } from '../components/overview/teacher/teacher.component';
import { OverviewService } from '../_services/overview.service';
import { TeacherOverviewExemptionRequestComponent } from '../components/overview/teacher/exemption-request/exemption-request.component';
import { TeacherOverviewCourseViewRequestComponent } from '../components/overview/teacher/course-view-request/course-view-request.component';
import { TeacherOverviewCourseSubmitComponent } from '../components/overview/teacher/course-submit/course-submit.component';
import { TeacherOverviewCoursePageResitRequestComponent } from '../components/overview/teacher/course-page-resit-request/course-page-resit-request.component';
import { AnalysisReportComponent } from '../pages/course/analysis/report/report.component';
import { AnalysisReportMessagesComponent } from '../pages/analysis/detail/messages/messages.component';
import { TeacherOverviewCourseAnalysisReminderComponent } from '../components/overview/teacher/course-analysis-reminder/course-analysis-reminder.component';
import { CourseNamePipe } from '../pipes/coursename.pipe';
import { NL2BRPipe } from '../pipes/nl2br.pipe';

@NgModule({
  providers: [
    ProgramService,
    StudentService,
    UserService,
    CourseService,
    CourseExamGroupService,
    CourseGradesService,
    CoreService,
    CourseFinalGradeService,
    DatePipe,
    FileSizePipe,
    OverviewService,
    CourseNamePipe,
    NL2BRPipe,

    // { provide: OWL_DATE_TIME_LOCALE, useValue: 'nl' },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    /**
     * Angular material
     */
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    NgxDatatableModule,
    FileUploadModule,

    TranslateModule.forRoot(),
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
  ],
  declarations: [
    CountDownComponent,
    AppConfirmdialogComponent,
    AppStudentContactComponent,
    AppFilesManageComponent,
    FileSizePipe,
    CourseBreadcrumbsComponent,
    StudentsDatatableComponent,
    StudentEducationGradesComponent,

    TeacherOverviewComponent,
    TeacherOverviewExemptionRequestComponent,
    TeacherOverviewCourseViewRequestComponent,
    TeacherOverviewCourseSubmitComponent,
    TeacherOverviewCoursePageResitRequestComponent,
    TeacherOverviewCourseAnalysisReminderComponent,

    AnalysisReportComponent,
    AnalysisReportMessagesComponent,
    CourseNamePipe,
    NL2BRPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    /**
     * Angular material
     */
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    NgxDatatableModule,

    BsDropdownModule,
    AlertModule,
    TabsModule,
    ModalModule,
    PopoverModule,

    AppConfirmdialogComponent,
    AppStudentContactComponent,
    AppFilesManageComponent,
    CountDownComponent,
    FileSizePipe,
    CourseBreadcrumbsComponent,
    StudentsDatatableComponent,
    StudentEducationGradesComponent,

    TeacherOverviewComponent,
    TeacherOverviewExemptionRequestComponent,
    TeacherOverviewCourseViewRequestComponent,
    TeacherOverviewCourseSubmitComponent,
    TeacherOverviewCoursePageResitRequestComponent,
    TeacherOverviewCourseAnalysisReminderComponent,

    AnalysisReportComponent,
    AnalysisReportMessagesComponent,
    CourseNamePipe,
    NL2BRPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
