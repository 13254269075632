<!-- BEGIN: Left Aside -->
<button class="m-aside-left-close  m-aside-left-close--skin-dark" id="m_aside_left_close_btn" appunwraptag="">
	<i class="la la-close"></i>
</button>
<div id="m_aside_left" class="m-grid__item	m-aside-left  m-aside-left--skin-dark">
	<!-- BEGIN: Aside Menu -->
	<div id="m_ver_menu" class="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark"
		m-menu-vertical="1" m-menu-scrollable="1" m-menu-dropdown-timeout="500" style="position: relative;">
		<ul class="m-menu__nav  m-menu__nav--dropdown-submenu-arrow">
			<li class="m-menu__item" aria-haspopup="true">
				<a target="_blank" href="https://www.i-ua.nl" class="m-menu__link">
					<i class="m-menu__link-icon flaticon-settings"></i>
					<span class="m-menu__link-text">{{ '_core_website' | translate }}</span>
				</a>
			</li>
			<li class="m-menu__item " routerLinkActive="m-menu__item--active "
				routerLinkActiveOptions="{ exact: true } " aria-haspopup="true ">
				<a [routerLink]="['/info']" class="m-menu__link ">
					<i class="m-menu__link-icon flaticon-info"></i>
					<span class="m-menu__link-text">{{ '_core_info_page' | translate }}</span>
				</a>
			</li>
			<li class="m-menu__item m-menu__item--submenu my_google m-menu__item--open" aria-haspopup="true "
				m-menu-submenu-toggle="hover ">
				<a href="javascript:void(0) " class="m-menu__link m-menu__toggle ">
					<i class="m-menu__link-icon fab fa-google"></i>
					<span class="m-menu__link-text ">{{ '_core_to_google_account' | translate }}</span>
					<i class="m-menu__ver-arrow la la-angle-right "></i>
				</a>
				<div class="m-menu__submenu ">
					<span class="m-menu__arrow "></span>
					<ul class="m-menu__subnav ">
						<li class="m-menu__item google_sub" aria-haspopup="true ">
							<a target="_blank" href="https://drive.google.com" class="m-menu__link ">
								<i id="gDriveIcon" class="m-menu__link-icon fab fa-google-drive">
									<span></span>
								</i>

								<span class="m-menu__link-text ">{{ '_core_to_google_drive' | translate }}</span>
							</a>
						</li>
						<li class="m-menu__item google_sub" aria-haspopup="true ">
							<a target="_blank" href="https://docs.google.com/document" class="m-menu__link ">
								<i id="gDocIcon" class="m-menu__link-icon fa fa-file-alt">
									<span></span>
								</i>
								<span class="m-menu__link-text ">{{ '_core_to_google_documents' | translate }}</span>
							</a>
						</li>
						<li class="m-menu__item google_sub" aria-haspopup="true ">
							<a target="_blank" href="https://docs.google.com/spreadsheets" class="m-menu__link ">
								<i id="gSheetIcon" class="m-menu__link-icon fa fa-file-excel">
									<span></span>
								</i>
								<span class="m-menu__link-text ">{{ '_core_to_google_spreadsheets' | translate }}</span>
							</a>
						</li>
					</ul>
				</div>
			</li>


			<li class="m-menu__item m-menu__item--submenu my_socialmedia m-menu__item--open" aria-haspopup="true "
				m-menu-submenu-toggle="hover ">
				<a href="javascript:void(0) " class="m-menu__link m-menu__toggle ">
					<i class="m-menu__link-icon fab fa-wechat"></i>
					<span class="m-menu__link-text ">{{ '_core_to_social_media' | translate }}</span>
					<i class="m-menu__ver-arrow la la-angle-right "></i>
				</a>
				<div class="m-menu__submenu ">
					<span class="m-menu__arrow "></span>
					<ul class="m-menu__subnav ">
						<li class="m-menu__item socialmedia_sub" aria-haspopup="true ">
							<a target="_blank" href="https://www.linkedin.com/company/iua-islamic-faculty-amsterdam/"
								class="m-menu__link ">
								<i id="linkedin" class="m-menu__link-icon socicon-linkedin">
									<span></span>
								</i>
								<span class="m-menu__link-text ">{{ '_core_to_linkedin' | translate }}</span>
							</a>
						</li>
						<li class="m-menu__item socialmedia_sub" aria-haspopup="true ">
							<a target="_blank" href="https://www.facebook.com/IUA.Islamitische.Theologische.Faculteit"
								class="m-menu__link ">
								<i id="facebook" class="m-menu__link-icon socicon-facebook">
									<span></span>
								</i>
								<span class="m-menu__link-text ">{{ '_core_to_facebook' | translate }}</span>
							</a>
						</li>
						<li class="m-menu__item socialmedia_sub" aria-haspopup="true">
							<a target="_blank" href="https://www.instagram.com/iua.nl/" class="m-menu__link ">
								<i id="instagram" class="m-menu__link-icon socicon-instagram">
									<span></span>
								</i>
								<span class="m-menu__link-text ">{{ '_core_to_instagram' | translate }}</span>
							</a>
						</li>
					</ul>
				</div>
			</li>



			<li class="m-menu__item my_gmail" routerLinkActive="m-menu__item--active"
				routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
				<a target="_blank"
					href="https://accounts.google.com/signin/v2/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&service=mail&sacu=1&rip=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin"
					class="m-menu__link">
					<i class="m-menu__link-icon socicon-mail"></i>
					<span class="m-menu__link-text">{{ '_core_to_gmail' | translate }}</span>
				</a>
			</li>

			<li *ngIf="authService.user.objStudent" class="m-menu__item" routerLinkActive="m-menu__item--active"
				routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
				<a routerLink="/dashboard" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-home"></i>
					<span class="m-menu__link-text">{{ '_dashboard' | translate }}</span>
				</a>
			</li>

			<li class="m-menu__item" routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }"
				aria-haspopup="true">
				<a routerLink="/programs" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-book"></i>
					<span class="m-menu__link-text">{{ '_courses_title' | translate }}</span>
				</a>
			</li>

			<li *ngIf="authService.user.objStudent" class="m-menu__item" routerLinkActive="m-menu__item--active"
				routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
				<a routerLink="/my-grades/exams/1" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-graduation-cap"></i>
					<span class="m-menu__link-text">{{ '_my_grades' | translate }}</span>
				</a>
			</li>
			<li *ngIf="authService.hasPermissionWithoutAdminCheck(userPermissionEnum.COURSE_MANAGE_GRADES) && authService.hasUserRoleID([2])" class="m-menu__item" routerLinkActive="m-menu__item--active"
				routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
				<a routerLink="/overview/teacher" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-tasks"></i>
					<span class="m-menu__link-title ">
						<span class="m-menu__link-wrap ">
							<span class="m-menu__link-text ">{{ '_overview_teacher_title' | translate }}</span>
							<span class="m-menu__link-badge">
								<span class="m-badge m-badge--danger">{{overviewService.totalOverviewTeacherNotificationCount}}</span>
							</span>
						</span>
					</span>
				</a>
			</li>
			<li *ngIf="authService.hasPermission(userPermissionEnum.COURSE_MANAGE_ANALYSIS)" class="m-menu__item" routerLinkActive="m-menu__item--active"
				routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
				<a routerLink="/course/analyses" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-stack-overflow"></i>
					<span class="m-menu__link-text">{{ '_course_analyses' | translate }}</span>
				</a>
			</li>
			<li *ngIf="authService.hasPermission(userPermissionEnum.COURSE_MANAGE_GRADES)" class="m-menu__item" routerLinkActive="m-menu__item--active"
				routerLinkActiveOptions="{ exact: true }" aria-haspopup="true">
				<a routerLink="/all-grades/exams" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-graduation-cap"></i>
					<span class="m-menu__link-text">{{ '_all_grades' | translate }}</span>
				</a>
			</li>
			<li class="m-menu__item" routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }"
				aria-haspopup="true">
				<a routerLink="/myprofile" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-user-circle"></i>
					<span class="m-menu__link-text">{{ '_my_profile_title' | translate }}</span>
				</a>
			</li>
			
			<!-- Not finished yet-->
			<li class="m-menu__item" routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }"
				aria-haspopup="true">
				<a routerLink="/exemptions" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-book"></i>
					<span class="m-menu__link-text">{{ '_course_view_page_title' | translate }}</span>
				</a>
			</li>
			
			<li class="m-menu__item" routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }"
				aria-haspopup="true">
				<a routerLink="/forms" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-wpforms"></i>
					<span class="m-menu__link-text">{{ '_forms_title' | translate }}</span>
				</a>
			</li>

			<li *ngIf="authService.hasPermission(5,6)" class="m-menu__section">
				<h4 class="m-menu__section-text">{{ '_core_manage' | translate }}</h4>
				<i class="m-menu__section-icon flaticon-more-v3"></i>
			</li>
			<li *ngIf="authService.hasPermission(userPermissionEnum.STUDENTS_MANAGE, userPermissionEnum.STUDENTS_VIEW)"
				class="m-menu__item " routerLinkActive="m-menu__item--active "
				routerLinkActiveOptions="{ exact: true } " aria-haspopup="true ">
				<a [routerLink]="['/students']" class="m-menu__link ">
					<i class="m-menu__link-icon flaticon-line-graph "></i>
					<span class="m-menu__link-text">{{ '_students_title' | translate }}</span>
				</a>
			</li>
			<li *ngIf="authService.hasPermission(7)" class="m-menu__item " routerLinkActive="m-menu__item--active "
				routerLinkActiveOptions="{ exact: true } " aria-haspopup="true ">
				<a [routerLink]="['/course-applications']" class="m-menu__link ">
					<i class="m-menu__link-icon flaticon-line-graph "></i>
					<span class="m-menu__link-text">
						Inschrijvingen
					</span>
				</a>
			</li>
			<li *ngIf="authService.hasPermission(8)" class="m-menu__item " routerLinkActive="m-menu__item--active "
				routerLinkActiveOptions="{ exact: true } " aria-haspopup="true ">
				<a [routerLink]="['/applications']" class="m-menu__link ">
					<i class="m-menu__link-icon flaticon-line-graph "></i>
					<span class="m-menu__link-text">{{ '_applications_title' | translate }}</span>
				</a>
			</li>

			<li *ngIf="authService.hasPermission(5) " class="m-menu__item m-menu__item--submenu "
				routerLinkActive="m-menu__item--open " routerLinkActiveOptions="{ exact: true } " aria-haspopup="true "
				m-menu-submenu-toggle="hover ">
				<a href="javascript:void(0) " class="m-menu__link m-menu__toggle ">
					<i class="m-menu__link-icon flaticon-settings "></i>
					<span class="m-menu__link-text ">{{ '_users_title' | translate }}</span>
					<i class="m-menu__ver-arrow la la-angle-right "></i>
				</a>
				<div class="m-menu__submenu ">
					<span class="m-menu__arrow "></span>
					<ul class="m-menu__subnav ">
						<li class="m-menu__item " routerLinkActive="m-menu__item--active " routerLinkActiveOptions="{ exact: true }
			 " aria-haspopup="true ">
							<a [routerLink]="[ '/users/list'] " class="m-menu__link ">
								<i class="m-menu__link-bullet m-menu__link-bullet--dot ">
									<span></span>
								</i>
								<span class="m-menu__link-text ">{{ '_users_title' | translate }}</span>
							</a>
						</li>
						<li *ngIf="authService.hasPermission(12) " class="m-menu__item "
							routerLinkActive="m-menu__item--active " routerLinkActiveOptions="{ exact: true }
			 " aria-haspopup="true ">
							<a [routerLink]="[ '/users/roles'] " class="m-menu__link ">
								<i class="m-menu__link-bullet m-menu__link-bullet--dot ">
									<span></span>
								</i>
								<span class="m-menu__link-text ">{{ '_users_roles_title' | translate }} </span>
							</a>
						</li>
					</ul>
				</div>
			</li>
			<li class="m-menu__item" routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }"
				aria-haspopup="true">
				<a routerLink="/logout" class="m-menu__link">
					<i class="m-menu__link-icon fa fa-power-off"></i>
					<span class="m-menu__link-text">{{ '_core_logout' | translate }}</span>
				</a>
			</li>
		</ul>
	</div>
	<!-- END: Aside Menu -->
</div>
<!-- END: Left Aside -->