import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainConfig } from '../../../../_helpers';
import { Course } from '../../../../_models/course/course';
import { AuthenticationService } from '../../../../_services';

@Component({
  selector: 'app-course-analysis-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class AnalysisReportComponent implements OnInit {
  @Input('analysisResponse') analysisResponse: any;
  @Input('course') course: Course;
  @Output('createAnalysisClicked') createAnalysisClicked = new EventEmitter<boolean>();

  constructor(public authService: AuthenticationService, public config: MainConfig) {}

  canCreate = false;

  ranges = {
    p: {
      min: 0.3,
      max: 0.8,
    },
    rit: {
      min: 0.25,
      max: 1,
    },
    cronbach: {
      min: 0.7,
      max: 1,
    },
  };

  editable = false;
  courseFileName = '';
  downloadURL = '';

  ngOnInit() {
    let strCourseName = this.course.strName;
    strCourseName = `Toetsanalyse ${strCourseName}`;
    this.courseFileName = strCourseName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    this.downloadURL = `${this.config.backendServerURL}api/course/analysis/${this.course.intCourseID}/${this.courseFileName}.pdf?token=${this.authService.token}`;
    this.canCreateAnalysis();
    this.calculateEditable();
  }

  calculateEditable() {
    let objCourseAnalysis;
    if (this.analysisResponse.objCourseAnalysis && this.authService.user.isTeacher) {
      objCourseAnalysis = this.analysisResponse.objCourseAnalysis;
      if (
        // No changes allowed after approval of examinator
        objCourseAnalysis.booApprovalExaminator == false &&
        // If the initial submit is not sent by the teacher
        (objCourseAnalysis.booInitialSubmit == false ||
          // If the initial submit is sent by the teacher, and it's reviewed by the examinator, but no approval is given
          (objCourseAnalysis.booInitialSubmit == true && objCourseAnalysis.booExaminatorSubmit == true))
      ) {
        this.editable = true;
      }
    } else {
      this.editable = false;
    }
  }

  canCreateAnalysis() {
    if (this.analysisResponse.objCourseAnalysis) {
      this.canCreate = false;
      return;
    }

    const teacherIDs = this.course.teachers.map((user) => user.intUserID);
    if (teacherIDs.includes(this.authService.user.intUserID)) {
      this.canCreate = true;
    } else {
      this.canCreate = false;
    }
  }

  createAnalysis(reset = false) {
    this.createAnalysisClicked.emit(reset);
  }
}
