<div class="row">
  <div class="col-lg-6">
    <app-overview-teacher-course-page-resit-request (viewUpdated)="getOverview()" [(overview)]="overview"></app-overview-teacher-course-page-resit-request>
  </div>
  <div class="col-lg-6">
    <app-overview-teacher-course-view-request (viewUpdated)="getOverview()" [(overview)]="overview"></app-overview-teacher-course-view-request>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <app-overview-teacher-course-submit [(overview)]="overview"></app-overview-teacher-course-submit>
  </div>
  <div class="col-lg-6">
    <app-overview-teacher-exemption-request [(overview)]="overview"></app-overview-teacher-exemption-request>
  </div>
</div>

<div class="row">
  <div class="col-lg-6">
    <app-overview-teacher-course-analysis-reminder [(overview)]="overview"></app-overview-teacher-course-analysis-reminder>
  </div>
</div>
