import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalMessageHandler, GlobalMessageType } from '../../../_services/message-handler.service';
import { TINHandler } from '../../../_services/turn-it-in-handler.service';

@Component({
    selector: '.m-grid__item.m-grid__item--fluid.m-grid.m-grid--ver-desktop.m-grid--desktop.m-body',
    templateUrl: './default.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DefaultComponent implements OnInit {
    constructor(
        public tinHandler: TINHandler
    ) {

    }
    ngOnInit() {

    }
}
