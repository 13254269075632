<!-- begin::Page loader -->
<div class="m-page-loader m-page-loader--base m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px;">

    <div class="m-blockui">
        <span>{{ '_core_please_wait' | translate }}</span>
        <span>
            <div class="m-loader m-loader--brand"></div>
        </span>
    </div>
</div>
<!-- end::Page loader -->
<!-- begin:: Page -->

<router-outlet></router-outlet>

<!-- end:: Page -->
<!-- <app-quick-sidebar></app-quick-sidebar> -->
<app-scroll-top></app-scroll-top>
<!-- <app-tooltips></app-tooltips> -->
<!--begin::Base Scripts -->
<!--end::Base Scripts -->
<!--begin::Page Vendors -->
<!--end::Page Vendors -->
<!--begin::Page Snippets -->
<!--end::Page Snippets -->
<div id="block-me"></div>