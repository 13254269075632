import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { ServerPage } from '../_models';
import { FileUploader } from 'ng2-file-upload';
import { Helpers } from '../helpers';
import { File } from '../_models/core/file';
import { catchError, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ProgramYearGroup } from '../_models/program/programyeargroup';
import { Program } from '../_models/program/program';

@Injectable()
export class ProgramService extends MainService {
  /**
   * CRUD METHODS
   */

  /**
   * Programs
   */
  addProgram(program: Program): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/program/add', program, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 201) {
            return { code: 201 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  updateProgram(program: Program): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .put(this.mainConfig.backendServerURL + 'api/program/update', program, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  deleteProgram(intProgramID: number): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .delete(this.mainConfig.backendServerURL + 'api/program/delete/' + intProgramID, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  /**
   * Calendar Year
   */
  addCalendarYear(options: any): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(this.mainConfig.backendServerURL + 'api/program/calendaryears', options, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 201) {
            return { code: 201 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }
  updateCalendarYear(options: any): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .put(this.mainConfig.backendServerURL + 'api/program/calendaryears', options, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  deleteCalendarYear(intCalendarYearID: number): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .delete(this.mainConfig.backendServerURL + 'api/program/calendaryears/' + intCalendarYearID, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  /**
   * Calendar years
   */
  getAllCalendarYears(limit = 250): Observable<any> {
    return this.getAllList(limit, 'getCalendarYears');
  }
  getCalendarYears(page: ServerPage = null, exclude: [number] = null): Observable<any> {
    return this.getList('api/program/calendaryears', null, page, exclude);
  }
  getCalendarYear(intCalendarYearID: number): Observable<any> {
    return this.getItem('api/core/calendaryear', intCalendarYearID);
  }

  /**
   * Programs
   */

  getAllPrograms(limit = 250): Observable<any> {
    return this.getAllList(limit, 'getPrograms');
  }
  getPrograms(page: ServerPage = null, exclude: [number] = null): Observable<any> {
    return this.getList('api/program', null, page, exclude);
  }

  getProgram(
    intProgramID,
    intCalendarYearID = null,
    intStartYearIgnore = null,
    intProgramYearValue = null
  ): Observable<any> {
    let params = intProgramID + '?';
    if (intCalendarYearID) {
      params += 'intCalendarYearID=' + intCalendarYearID + '&';
    }
    if (intStartYearIgnore) {
      params += 'intStartYearIgnore=' + intStartYearIgnore + '&';
    }
    if (intProgramYearValue) {
      params += 'intProgramYearValue=' + intProgramYearValue + '&';
    }

    return this.getItem('api/program', params);
  }

  /**
   * Program Years
   */
  getAllProgramYears(limit = 250, intProgramID: number, intCalendarYearID: number): Observable<any> {
    return this.getAllList(limit, 'getProgramYears', [intProgramID, intCalendarYearID]);
  }
  getProgramYears(page: ServerPage = null, exclude: [number] = null, params: any[]): Observable<any> {
    return this.getList('api/program/' + params[0] + '/cal/' + params[1] + '/year', null, page, exclude);
  }
  getProgramYear(intProgramID: number, intProgramYearID: number): Observable<any> {
    return this.getItem('api/program/' + intProgramID + '/year', intProgramYearID);
  }

  /**
   * Program Year Group
   */
  getProgramYearGroups(intProgramYearID: number, page: ServerPage = null, exclude: [number] = null): Observable<any> {
    return this.getList('api/program/year/' + intProgramYearID + '/groups', null, page, exclude);
  }
  getProgramCourseYearGroups(
    intProgramCourseYearID: number,
    page: ServerPage = null,
    exclude: [number] = null
  ): Observable<any> {
    return this.getList('api/program/course/year/' + intProgramCourseYearID + '/groups', null, page, exclude);
  }

  getProgramYearGroup(intProgramYearGroupID: number): Observable<any> {
    return this.getItem('api/program/year/group', intProgramYearGroupID);
  }

  addProgramYearGroup(programYearGroup: ProgramYearGroup, arrStudents: number[] = []) {
    Helpers.setLoading(true);

    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/program/year/group/add',
        {
          intProgramYearID: programYearGroup.intProgramYearID,
          intProgramCourseYearID: programYearGroup.intProgramCourseYearID,
          strGroupName: programYearGroup.strGroupName,
          arrStudents: arrStudents,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);

          if (response.status === 201) {
            return { code: 201 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  saveProgramYearGroup(programYearGroup: ProgramYearGroup): Observable<any> {
    Helpers.setLoading(true);

    return this.http
      .post(this.mainConfig.backendServerURL + 'api/program/year/group', programYearGroup, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 201) {
            return { code: 201 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  deleteProgramYearGroup(programYearGroup: ProgramYearGroup): Observable<any> {
    return this.deleteItem('api/program/year/group/' + programYearGroup.intProgramYearGroupID);
  }

  addProgramYearGroupStudent(intProgramYearGroupID: number, intStudentID: number) {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL +
          'api/program/year/group/' +
          intProgramYearGroupID +
          '/students/' +
          intStudentID,
        {},
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          // this.hideLoading(".main-page");
          Helpers.setLoading(false);
          // this.showSaved();

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }
  deleteProgramYearGroupStudent(intProgramYearGroupID: number, intStudentID: number): Observable<any> {
    return this.deleteItem('api/program/year/group/' + intProgramYearGroupID + '/students/' + intStudentID);
  }

  /**
   * Program Year Periods
   */
  getAllProgramYearPeriods(limit = 250, intProgramID: number, intProgramYearID: number): Observable<any> {
    return this.getAllList(limit, 'getProgramYearPeriods', [intProgramID, intProgramYearID]);
  }
  getProgramYearPeriods(page: ServerPage = null, exclude: [number] = null, params: any[]): Observable<any> {
    return this.getList('api/program/' + params[0] + '/year/' + params[1] + '/period', null, page, exclude);
  }
  getProgramYearPeriod(intProgramID: number, intProgramYearID: number, intProgramYearPeriodID): Observable<any> {
    return this.getItem(
      'api/program/' + intProgramID + '/year/' + intProgramYearID + '/period',
      intProgramYearPeriodID
    );
  }

  /**
   * Program Courses
   */
  getAllProgramCourses(limit = 250, intProgramID: number): Observable<any> {
    return this.getAllList(limit, 'getProgramCourses', [intProgramID]);
  }

  getProgramCourses(page: ServerPage = null, exclude: [number] = null, params: any[]): Observable<any> {
    return this.getList('api/program/' + params[0] + '/course', null, page, exclude);
  }
  getProgramCourse(intProgramID: number, intProgramCourseID: number, intCalendarYearID: number): Observable<any> {
    return this.getItem(
      'api/program/' + intProgramID + '/course',
      intProgramCourseID + '?intCalendarYearID=' + intCalendarYearID
    );
  }

  /**
   * Program Course Years
   */
  getAllProgramCourseYears(
    limit = 250,
    intProgramID: number,
    intProgramCourseID: number,
    intCalendarYearID: number
  ): Observable<any> {
    return this.getAllList(limit, 'getProgramCourseYears', [intProgramID, intProgramCourseID, intCalendarYearID]);
  }
  getProgramCourseYears(page: ServerPage = null, exclude: [number] = null, params: any[]): Observable<any> {
    return this.getList(
      'api/program/' + params[0] + '/course/' + params[1] + '/cal/' + params[2] + '/year',
      null,
      page,
      exclude
    );
  }
  getProgramCourseYear(
    intProgramID: number,
    intProgramCourseID: number,
    intProgramCourseYearID: number
  ): Observable<any> {
    return this.getItem(
      'api/program/' + intProgramID + '/course/' + intProgramCourseID + '/year',
      intProgramCourseYearID
    );
  }

  /**
   * Program Course Year Periods
   */
  getAllProgramCourseYearPeriods(
    limit = 250,
    intProgramID: number,
    intProgramCourseID: number,
    intProgramCourseYearID: number
  ): Observable<any> {
    return this.getAllList(limit, 'getProgramCourseYearPeriods', [
      intProgramID,
      intProgramCourseID,
      intProgramCourseYearID,
    ]);
  }
  getProgramCourseYearPeriods(page: ServerPage = null, exclude: [number] = null, params: any[]): Observable<any> {
    return this.getList(
      'api/program/' + params[0] + '/course/' + params[1] + '/year/' + params[2] + '/period',
      null,
      page,
      exclude
    );
  }
  getProgramCourseYearPeriod(
    intProgramID: number,
    intProgramCourseID: number,
    intProgramCourseYearID: number,
    intProgramYearPeriodID
  ): Observable<any> {
    return this.getItem(
      'api/program/' + intProgramID + '/course/' + intProgramCourseID + '/year/' + intProgramCourseYearID + '/period',
      intProgramYearPeriodID
    );
  }

  uploadProgramFile(params: any, fileUploader: FileUploader): Observable<any> {
    Helpers.setLoading(true);
    const observable = new Observable((observer) => {
      const options = {
        url: this.mainConfig.backendServerURL + 'api/program/file/',
        authToken: 'Bearer ' + this.authenticationService.token,
      };

      if (params) {
        options['additionalParameter'] = params;
      }

      fileUploader.setOptions(options);
      if (fileUploader.queue.length > 0) {
        const item = fileUploader.queue[0];
        fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          const newResponse = new HttpResponse({
            status: status,
            statusText: response.message,
            url: item.url,
            body: response,
          });
          Helpers.setLoading(false);
          fileUploader.clearQueue();
          observer.next(newResponse.body);
        };
        item.upload();
      } else {
        observer.next(null);
      }
    });
    return observable;
  }

  deleteProgramFile(file: File): Observable<any> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + 'api/program/file/' + file.intFileID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  /**
   * Copy year
   */
  copyProgramYear(intProgramYearID: number, intDestCalendarYearID: number) {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/program/year/copy-programyear',
        {
          intProgramYearID: intProgramYearID,
          intDestCalendarYearID: intDestCalendarYearID,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  copyProgramCourseYear(intProgramCourseYearID: number, intDestCalendarYearID: number) {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/program/year/copy-program-year-course',
        {
          intProgramCourseYearID: intProgramCourseYearID,
          intDestCalendarYearID: intDestCalendarYearID,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }
}
