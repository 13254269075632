<div class="m-portlet">
  <div class="main-page">
    <div class="m-content">
      <div class="mb-3">
        <!-- Back button -->
        <div class="row" *ngIf="authService.user.objUserRole.intUserRoleID !== 6">
          <div class="col">
            <a [routerLink]="'/courses/view/' + course.intCourseID">
              <i class="fa fa-arrow-left"></i>
              <span> {{ '_course_view_final_back_to' | translate }} onderwijseenheid</span>
            </a>
          </div>
          <div class="col">
            <button *ngIf="editable" (click)="createAnalysis(true)" class="btn btn-primary float-right">
              <span>Toetsanalyse opnieuw genereren</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <h3 class="color-iua">{{ '_course_view_course_analysis_title' | translate }} {{ course.strName }}</h3>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <a [attr.href]="downloadURL" target="_blank">
            <i class="fa fa-cloud-download" style="margin-right: 10px"></i>
            <span
              >{{ courseFileName.length > 35 ? (courseFileName | slice : 0 : 35) + '...' : courseFileName }}.pdf</span
            >
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h4>Cesuur: {{ course.douCaesura | number : '1.1-1' }}</h4>
        </div>
        <div class="col" *ngIf="analysisResponse.coursePageExams.length > 1">
          <h4>Minimale cijfer voor gecompenseerde toetsen : {{ course.minGradeCompensatedExam | number : '1.1-1' }}</h4>
        </div>
        <div class="col-auto">
          <!-- Save -->
          <button *ngIf="canCreate" (click)="createAnalysis()" class="btn btn-primary float-right">
            <span>Toetsanalyse aanmaken</span>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div id="report-table">
            <table class="table table-striped">
              <thead>
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams">
                    <th [attr.colspan]="exam.objCoursePageExamRating.ratings.length + 4">
                      <div>{{ exam.strName }}</div>
                      Weging: {{ exam.intCountValue }}%
                    </th>
                  </ng-container>
                </tr>
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <th scope="col" *ngIf="i == 0">#</th>
                    <th scope="col" *ngIf="i == 0">Student</th>
                    <th scope="col" *ngFor="let rating of exam.objCoursePageExamRating.ratings">
                      {{ rating.strTitle }}
                    </th>
                    <th>Tot{{ i + 1 }}.</th>
                    <th>Cij{{ i + 1 }}.</th>
                  </ng-container>
                  <th>Totaal</th>
                  <th>Cijfer</th>
                  <th>G</th>
                  <th>GV</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let student of analysisResponse.students; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{ student.strFirstName }} {{ student.strLastName }}</td>
                  <ng-container *ngFor="let submission of student.submissions">
                    <td *ngFor="let review of submission.objCoursePageExamSubmitReview.reviews">
                      <!-- If the question is conditional, show if it's accomplished or not -->
                      <ng-container *ngIf="review.booRequired === true">
                        <p *ngIf="review.booAccomplished === true">X</p>
                        <p *ngIf="review.booAccomplished !== true">-</p>
                      </ng-container>

                      <!-- Show the score -->
                      <ng-container *ngIf="review.booRequired !== true">
                        <p>
                          {{ review.intScore }}
                        </p>
                      </ng-container>
                    </td>
                    <!-- Show the total accomplished grades -->
                    <td>
                      <strong>{{ submission.totalAccomplished }}</strong>
                    </td>

                    <!-- Show the final grade -->
                    <td>
                      <strong>{{ submission.finalGrade | number : '1.1-1' }}</strong>
                    </td>
                  </ng-container>
                  <td>
                    <b>{{ student.totalAccomplished }}</b>
                  </td>
                  <td>
                    <b>{{ student.finalGrade | number : '1.1-1' }}</b>
                  </td>
                  <td>
                    <ng-container *ngIf="student.booAchieved">
                      <span class="color-success"><strong>JA</strong></span>
                    </ng-container>
                    <ng-container *ngIf="!student.booAchieved"
                      ><span class="color-iua"><strong>NEE</strong></span>
                    </ng-container>
                  </td>

                  <td>
                    <ng-container *ngIf="student.booAchievedConditional">
                      <span class="color-success"><strong>JA</strong></span>
                    </ng-container>
                    <ng-container *ngIf="!student.booAchievedConditional">
                      <span class="color-iua"><strong>NEE</strong></span>
                    </ng-container>
                  </td>
                </tr>
                <!-- The median -->
                <tr>
                  <td [attr.colspan]="3 + analysisResponse.ratingsLength + analysisResponse.coursePageExams.length * 2">
                    Mediaan
                  </td>
                  <td colspan="4">
                    <b>{{ analysisResponse.median | number : '1.0-0' }}</b>
                  </td>
                </tr>
                <!-- The average -->
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2">Gemiddelde</td>
                    <td *ngFor="let avg of exam.average">
                      {{ avg | number : '1.2-2' }}
                    </td>
                    <td></td>
                  </ng-container>
                  <td>
                    <b>{{ analysisResponse.average | number : '1.2-2' }}</b>
                  </td>
                  <td colspan="3">
                    <b>{{ analysisResponse.averageGrade | number : '1.2-2' }}</b>
                  </td>
                </tr>
                <!-- The variance -->
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2">Variantie</td>
                    <td *ngFor="let varience of exam.variance">
                      {{ varience | number : '1.2-2' }}
                    </td>
                    <td></td>
                  </ng-container>
                  <td>
                    <b>{{ analysisResponse.variance | number : '1.2-2' }}</b>
                  </td>
                  <td colspan="3">
                    <b>{{ analysisResponse.varianceGrade | number : '1.2-2' }}</b>
                  </td>
                </tr>
                <!-- The default deviation value -->
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2">Standaardafwijking</td>
                    <td *ngFor="let dev of exam.deviation">
                      {{ dev | number : '1.2-2' }}
                    </td>
                    <td></td>
                  </ng-container>
                  <td>
                    <b>{{ analysisResponse.deviation | number : '1.2-2' }}</b>
                  </td>
                  <td colspan="3">
                    <b>{{ analysisResponse.deviationGrade | number : '1.2-2' }}</b>
                  </td>
                </tr>
                <!-- Maximum earnable points per question -->
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2">Max punten per vraag</td>
                    <td *ngFor="let maxPoint of exam.maxPoints">
                      <strong>{{ maxPoint | number : '1.0-0' }}</strong>
                    </td>
                    <td></td>
                  </ng-container>
                  <td>
                    <strong>{{ analysisResponse.maxPoints }}</strong>
                  </td>
                  <td colspan="3">
                    <strong>{{ analysisResponse.maxGrade }}</strong>
                  </td>
                </tr>
                <!-- Calculated P Value -->
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2">
                      P-waarde (Gemiddelde/Max punten per vraag)
                    </td>
                    <td *ngFor="let pValue of exam.pValue">
                      <strong *ngIf="!pValue" class="val-red">0.00</strong>
                      <strong
                        [ngClass]="{
                          'val-red': !(pValue >= ranges.p.min && pValue <= ranges.p.max),
                          'val-green': pValue >= ranges.p.min && pValue <= ranges.p.max
                        }"
                        >{{ pValue | number : '1.2-2' }}</strong
                      >
                    </td>
                    <td></td>
                  </ng-container>
                  <td></td>
                  <td colspan="3">
                    <strong
                      [ngClass]="{
                        'val-red': !(
                          analysisResponse.pValue >= ranges.p.min && analysisResponse.pValue <= ranges.p.max
                        ),
                        'val-green': analysisResponse.pValue >= ranges.p.min && analysisResponse.pValue <= ranges.p.max
                      }"
                      >{{ analysisResponse.pValue | number : '1.2-2' }}</strong
                    >
                  </td>
                </tr>
                <!-- Calculate RIT value -->
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2">Rit-waarde</td>
                    <td *ngFor="let correl of exam.correl">
                      <strong *ngIf="!correl" class="val-red">0.00</strong>
                      <strong
                        [ngClass]="{
                          'val-red': !(correl >= ranges.rit.min && correl <= ranges.rit.max),
                          'val-green': correl >= ranges.rit.min && correl <= ranges.rit.max
                        }"
                        >{{ correl | number : '1.2-2' }}</strong
                      >
                    </td>
                    <td colspan="2"></td>
                  </ng-container>
                  <td colspan="4"></td>
                </tr>
                <!-- Calculate the Cronbach's alpha -->
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2">Cronbach's alpha α</td>
                    <td [attr.colspan]="exam.correl.length"></td>
                    <td>
                      <strong
                        [ngClass]="{
                          'val-red': !(exam.cronBach >= ranges.rit.min && exam.cronBach <= ranges.rit.max),
                          'val-green': exam.cronBach >= ranges.rit.min && exam.cronBach <= ranges.rit.max
                        }"
                        >{{ exam.cronBach | number : '1.2-2' }}</strong
                      >
                    </td>
                    <td></td>
                  </ng-container>
                  <td>
                    <strong
                      [ngClass]="{
                        'val-red': !(
                          analysisResponse.cronBach >= ranges.rit.min && analysisResponse.cronBach <= ranges.rit.max
                        ),
                        'val-green':
                          analysisResponse.cronBach >= ranges.rit.min && analysisResponse.cronBach <= ranges.rit.max
                      }"
                      >{{ analysisResponse.cronBach | number : '1.2-2' }}</strong
                    >
                  </td>
                  <td colspan="3"></td>
                </tr>
                <!-- Calculate grades -->
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2"></td>
                    <td [attr.colspan]="exam.correl.length"></td>
                    <td></td>
                    <td></td>
                  </ng-container>
                  <td colspan="2"><strong>Gehaald</strong></td>
                  <td>
                    {{ analysisResponse.achieved | number : '1.0-0' }}
                  </td>
                  <td>
                    {{ analysisResponse.achievedConditional | number : '1.0-0' }}
                  </td>
                </tr>
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2"></td>
                    <td [attr.colspan]="exam.correl.length"></td>
                    <td></td>
                    <td></td>
                  </ng-container>
                  <td colspan="2"><strong>Niet gehaald</strong></td>
                  <td>
                    {{ analysisResponse.notAchieved | number : '1.0-0' }}
                  </td>
                  <td>
                    {{ analysisResponse.notAchievedConditional | number : '1.0-0' }}
                  </td>
                </tr>
                <tr>
                  <ng-container *ngFor="let exam of analysisResponse.coursePageExams; let i = index">
                    <td *ngIf="i === 0" [attr.colspan]="exam.intConditionalCount + 2"></td>
                    <td [attr.colspan]="exam.correl.length"></td>
                    <td></td>
                    <td></td>
                  </ng-container>
                  <td colspan="2">% Gehaald</td>
                  <td>
                    <strong>{{ analysisResponse.percentageAchieved | number : '1.0-0' }}%</strong>
                  </td>
                  <td>
                    <strong>{{ analysisResponse.conPercentageAchieved | number : '1.0-0' }}%</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
