import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '../../../../shared/translate';
import { CourseService } from '../../../../_services/course.service';
import { GlobalMessageHandler } from '../../../../_services/message-handler.service';

@Component({
  selector: 'app-analysis-report-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class AnalysisReportMessagesComponent implements OnInit {
  @Input() objCourseAnalysis: any;

  @Output() newMessageSubmitted: EventEmitter<void> = new EventEmitter();

  requestMessage: any = {};

  constructor(
    private courseService: CourseService,
    private gmHandler: GlobalMessageHandler,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  submitMessage() {
    this.courseService
      .add(`api/course/analysis/${this.objCourseAnalysis.intCourseAnalysisID}/message`, this.requestMessage)
      .subscribe(
        (result) => {
          this.gmHandler.handleSuccess(this.translate.instant('_core_added_succesfully'));
          this.newMessageSubmitted.emit();
          this.requestMessage.strMessage = '';
        },
        (error: HttpErrorResponse) => this.gmHandler.handleError(error)
      );
  }
}
