import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export class TINHandler {
    turnItInData = null;
    showOnDefault: boolean = false;
    noCloseButton: boolean = false;

    constructor(
        private router: Router
    ) {
        router.events.subscribe((val) => {
            // see also
            this.turnItInData = null;
            this.showOnDefault = false;
        });
    }


    setTINData(turnItInData: any, options: {
        currentPage: boolean,
        noCloseButton: boolean,
        noNav: boolean
    } = null, cb = null) {
        const currentPage = (options) ? options.currentPage : false;
        const noCloseButton = (options) ? options.noCloseButton : false;
        const noNav = (options) ? options.noNav : false;

        if (noNav) {
            $('#m_aside_left').attr('style', 'display:none;');
            $('#m_aside_left_minimize_toggle').attr('style', 'display:none !important;');
        }

        this.turnItInData = turnItInData;
        this.showOnDefault = !currentPage;
        this.noCloseButton = noCloseButton;

        setTimeout(() => {
            $('#ltiForm').submit();
        }, 500);

        if (cb) {
            cb();
        }
    }

    clearTINData() {
        $('#m_aside_left').attr('style', '');
        $('#m_aside_left_minimize_toggle').attr('style', '');

        this.showOnDefault = false;
        this.turnItInData = null;
    }
}
