<ng-template [ngIf]="_grades && _grades.length > 0" [ngIfElse]="noGradesFounts">
  <div *ngFor="let program of _grades" class="row mb-5">
    <div class="col-12">
      <h3 class="color-iua">{{ program.strName }}</h3>
      <p class="text-justify">
        <a [href]="downloadUrl" target="_blank">
          <i class="fa fa-cloud-download" style="margin-right: 10px"></i>Download studievoortgang
        </a>
      </p>
      <p class="text-justify">
        Lijst met resultaten die zijn opgenomen in de eindbeoordeling van het academische record van de betreffende
        opleiding.
      </p>
      <div class="ml-5">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col mb-3"><b>Onderwijseenheid</b></div>
              <div class="col-2 mb-3"><b>Eindcijfer</b></div>
              <div class="col-2 mb-3"><b>Datum</b></div>
              <div class="col-2 mb-3"><b>EC</b></div>
            </div>

            <!-- Loop the years -->
            <ng-container [ngTemplateOutlet]="yearsView" [ngTemplateOutletContext]="{ years: program.years }">
            </ng-container>

            <!-- Loop the courses -->
            <div *ngFor="let course of program.courses">
              <ng-container [ngTemplateOutlet]="yearsView" [ngTemplateOutletContext]="{ years: course.courseyears }">
              </ng-container>
            </div>
            <hr />
            <p class="text-justify">
              Totaal verdiende EC punten: <b>{{ program.totalEarnedEC }} ECTS credits</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noGradesFounts> Geen cijfers gevonden </ng-template>

<ng-template #yearsView let-years="years">
  <div *ngFor="let year of years">
    <div class="color-iua">
      <b
        >{{ year.strName }} <small>({{ year.objCalendarYear.strName }})</small></b
      >
    </div>
    <div *ngFor="let period of year.periods">
      <div
        *ngFor="let course of period.courses"
        class="row"
        [ngClass]="{ 'grade-nb': !course.assessment || !course.assessment.achieved }"
      >
        <div class="col mb-3">
          <a [routerLink]="['/courses', 'view', course.intCourseID]">{{ course.strName }}</a>
          <small
            ><b>({{ course.intECPoints || 'EC onbekend' }})</b></small
          >
        </div>
        <div class="col-2 mb-3">
          <div *ngIf="course.assessment">
            <ng-template [ngIf]="course.assessment.strType == 'GRADE'" [ngIfElse]="exemption">
              <b *ngIf="course.assessment.achieved">{{ course.assessment.douFinalGrade | number : '1.1-1' }}</b>
              <b *ngIf="!course.assessment.achieved"
                >NB <small>{{ course.assessment.douFinalGrade | number : '1.1-1' }}</small></b
              >
            </ng-template>

            <ng-template #exemption>
              <b>VR</b>
            </ng-template>
          </div>
        </div>
        <div class="col-2 mb-3">
          <small *ngIf="course.assessment">{{ course.assessment.datAssessmentDate | date : 'dd-MM-yyyy' }}</small>
        </div>
        <div class="col-2 mb-3">
          <div *ngIf="course.assessment && course.assessment.achieved">
            {{ course.assessment.intECPoints }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
