import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from './helpers';
import { TranslateService } from './shared/translate';
import { AppConfirmdialogComponent } from './shared/components/confirmdialog/confirmdialog.component';
import { OverviewService } from './_services/overview.service';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'app';
  globalBodyClass =
    'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

  public supportedLanguages: any[];

  constructor(
    private _router: Router,
    private _translate: TranslateService
  ) {}

  ngOnInit() {
    this.supportedLanguages = [{ display: 'Nederlands', value: 'nl' }];
    this.selectLang('nl');

    this._router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        Helpers.setLoading(true);
        Helpers.bodyClass(this.globalBodyClass);
      }
      if (route instanceof NavigationEnd) {
        Helpers.setLoading(false);
      }
    });
  }

  selectLang(lang: string) {
    // set current lang;
    this._translate.use(lang);
  }
}
