
import { throwError as observableThrowError, Subscription, Observable, of } from 'rxjs';
import { catchError, map, expand } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './auth.service';
import { MainConfig } from '../_helpers';
import { TranslateService } from '../shared/translate';
import swal from 'sweetalert2';
import { ServerPage } from '../_models';
import { ServerListResponse } from '../_models/core/serverlistresponse';
import { Helpers } from '../helpers';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';
import { File } from '../_models/core/file';
import { GlobalMessageHandler } from './message-handler.service';


declare let mApp: any;
declare let $: any;

@Injectable()
export class MainService implements OnDestroy {
    tempArray: any[];
    headers: HttpHeaders;
    options: any;

    userTokenChangedSubscription: Subscription;

    authenticationService: AuthenticationService;
    mainConfig: MainConfig;
    router: Router;
    http: HttpClient;

    constructor(
        private _http: HttpClient,
        private _authenticationService: AuthenticationService,
        private _mainConfig: MainConfig,
        private _router: Router,
        private _translate: TranslateService,
        private _gmHandler: GlobalMessageHandler
    ) {
        this.authenticationService = _authenticationService;
        this.mainConfig = _mainConfig;
        this.router = _router;
        this.http = _http;

        this.tempArray = [];
        // add authorization header with jwt token
        this.userTokenChangedSubscription = this.authenticationService._userTokenChanged$.subscribe((tokenChanged) => {
            this.setHeaders();
        });
        this.setHeaders();
    }

    // Prevent memory leak
    ngOnDestroy() {
        this.userTokenChangedSubscription.unsubscribe();
    }

    setHeaders() {
        this.headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.authenticationService.token });
        this.headers.append('x-access-token', this.authenticationService.token);
        this.headers.append('Content-Type', 'application/json');
    }

    unAuthorizedHandler(error) {
        if (error.status === 401) {
            this.router.navigate(['/logout']);
        }
        Helpers.setLoading(false, true);
        return observableThrowError(error);
    }

    showSaved() {
        $('#m_sweetalert_demo_6').click((e) => {
            swal({
                position: 'top-right',
                type: 'success',
                title: 'Your work has been saved',
                showConfirmButton: !1,
                timer: 2000
            });
        });

        // const toast = (swal as any).mixin({
        //     toast: true,
        //     position: 'top-end',
        //     showConfirmButton: false,
        //     timer: 2000

        // });

        // toast({
        //     type: 'success',
        //     title: this._translate.instant('_saved_successfuly')
        // })
    }


    showLoading(strClass: string) {
        // mApp.block('body');
        Helpers.setLoading(true);
    }

    hideLoading(strClass: string) {
        // mApp.unblock('body');
        Helpers.setLoading(false);
    }

    showSaving(strClass: string) {
        // mApp.block(strClass, {
        //     overlayColor: "#000000",
        //     type: "loader",
        //     state: "primary",
        //     message: this._translate.instant('_saving')
        // });
    }


    protected getList(endpoint: string, params: string = null, page: ServerPage = null, exclude: [number] = null): Observable<any> {
        let url = this.mainConfig.backendServerURL + endpoint;
        if (page.pageNumber || page.limit || page.offset || page.query) {
            url += '?';
        }

        if (page.pageNumber) url += 'page=' + page.pageNumber + '&';
        if (page.query) url += 'query=' + page.query + '&';
        if (page.limit) url += 'limit=' + page.limit + '&';
        if (page.offset) url += 'offset=' + page.offset + '&';
        if (exclude) url += 'exclude=' + exclude.join() + '&';
        if (params) url += params + '&';

        Helpers.setLoading(true);
        return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            const list = response.body;
            Helpers.setLoading(false);

            return list;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    protected getAllList(limit = 250, method: string, params: any[] = null): Observable<any> {
        if (limit == null) {
            limit = 250;
        }
        // Create the server page object
        const page = new ServerPage();
        page.pageNumber = 0;
        page.limit = limit;

        // Create te observable
        const request = this[method](page, null, params);

        // Values to be returned
        let values: Array<any> = [];

        // Create a custom observable
        const observable = new Observable((observer) => {
            // Expand the request to make calls until all pages are fetched
            request.pipe(expand((response) => {
                const resp = <ServerListResponse>response;

                // If the limit exceeds the maximum, set it to maximum.
                if (resp.limit < page.limit) {
                    page.limit = resp.limit;
                }

                // Get the rows and merge it with values
                const rows: Array<any> = <Array<any>>resp.rows;
                values = values.concat(rows);

                // Check if all pages are fetched, if not, start a new request
                const count = resp.count;
                const rowsFetched = (page.pageNumber * page.limit) + page.limit;
                if (rowsFetched < count) {
                    // Start new request
                    page.pageNumber = page.pageNumber + 1;
                    return this[method](page, null, params);
                }

                // All pages fetched, notify the observer
                observer.next(values);

                //
                return of();
            }))
                // Start the observable
                .subscribe();
        });

        return observable;
    }

    protected getItem(endpoint: string, intItemID: any = null): Observable<any> {
        Helpers.setLoading(true);

        let url = this.mainConfig.backendServerURL + endpoint;
        if (intItemID !== null) {
            url = url + '/' + intItemID;
        }

        return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            Helpers.setLoading(false);
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    protected deleteItem(endpoint: string): Observable<any> {
        Helpers.setLoading(true);
        const url = this.mainConfig.backendServerURL + endpoint;

        return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            Helpers.setLoading(false);
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }


    add(endpoint, options: any): Observable<any> {
        Helpers.setLoading(true);
        return this.http.post(this.mainConfig.backendServerURL + endpoint, options, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            Helpers.setLoading(false);
            if (response.status === 201) {
                return { code: 201 };
            }
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    update(endpoint, options: any): Observable<any> {
        Helpers.setLoading(true);
        return this.http.put(this.mainConfig.backendServerURL + endpoint, options, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            Helpers.setLoading(false);
            if (response.status === 204) {
                return { code: 204 };
            }
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    delete(endpoint, intID: number): Observable<any> {
        Helpers.setLoading(true);
        return this.http.delete(this.mainConfig.backendServerURL + endpoint + '/' + intID, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            Helpers.setLoading(false);
            if (response.status === 204) {
                return { code: 204 };
            }
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    /**
     * File management
     */
    uploadFile(endpoint: string, params: any, fileUploader: FileUploader): Observable<any> {
        Helpers.setLoading(true);

        const observable = new Observable((observer) => {
            const options = {
                url: this.mainConfig.backendServerURL + endpoint,
                authToken: 'Bearer ' + this.authenticationService.token,
            };
            if (params) {
                options['additionalParameter'] = params;
            }
            fileUploader.setOptions(options);
            if (fileUploader.queue.length > 0) {
                const item = fileUploader.queue[0];
                fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
                    const responseOptions = {
                        status: status,
                        statusText: response.message,
                        url: item.url,
                        body: response
                    };

                    const newResponse = new HttpResponse(responseOptions);
                    Helpers.setLoading(false);
                    fileUploader.clearQueue();
                    observer.next(newResponse);
                };
                item.upload();

                fileUploader.onErrorItem = (item: any, response: string, status: any, headers: any) => {
                    Helpers.setLoading(false);
                };
            } else {
                Helpers.setLoading(false);
                fileUploader.clearQueue();
                observer.next(null);
            }
        });
        return observable;
    }

    deleteFile(endpoint: string, file: File): Observable<any> {
        Helpers.setLoading(true);
        const url = this.mainConfig.backendServerURL + endpoint + '/' + file.intFileID;
        return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            Helpers.setLoading(false);
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }
}
