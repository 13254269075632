import { Component, OnInit, Input } from '@angular/core';
import { TINHandler } from '../../../_services/turn-it-in-handler.service';

@Component({
  selector: '<app-turn-it-in-launcher></app-turn-it-in-launcher>',
  templateUrl: './turnitin.component.html',
  styleUrls: ['./turnitin.component.scss']
})
export class TurnItInComponent implements OnInit {
  constructor(
    public tinHandler: TINHandler
  ) { }

  ngOnInit() {

  }
}
