<div class="m-portlet m-portlet--mobile">
  <div class="m-portlet__head">
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
        <h3 class="m-portlet__head-text">
          {{ '_course_view_page_exam_ungraded_submissions' | translate }}
        </h3>
      </div>
    </div>
  </div>
  <div class="m-portlet__body">
    <div class="table-responsive" *ngIf="overview">
      <ngx-datatable
        #table
        class="material min-width-700"
        [messages]="{
          emptyMessage: this.translate.instant('_course_view_page_exam_no_submissions'),
          totalMessage: this.translate.instant('_exemptions_total')
        }"
        [rows]="overview.item.courseSubmits"
        [columnMode]="'force'"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="3"
      >
        <ngx-datatable-column name="Student">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              href="javascript:void(0)"
              [routerLink]="[
                '/courses/view/' +
                  row.objCoursePage.objCourse.intCourseID +
                  '/page/view/' +
                  row.objCoursePage.intCoursePageID
              ]"
            >
              {{ row.objStudent.strFirstName }} {{ row.objStudent.strLastName }}
            </a>
            <div>
              <small *ngIf="row.objCoursePageExamSubmitReview === null">
                <strong>Geen beoordeling</strong>
              </small>
              <small
                *ngIf="row.objCoursePageExamSubmitReview && row.objCoursePageExamSubmitReview.isReviewed === false"
              >
                <strong>In beoordeling</strong>
              </small>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Herkansing">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              href="javascript:void(0)"
              [routerLink]="[
                '/courses/view/' +
                  row.objCoursePage.objCourse.intCourseID +
                  '/page/view/' +
                  row.objCoursePage.intCoursePageID
              ]"
            >
              {{ row.objCoursePage.strName }}<br />
              <small>{{ row.objCoursePageExam.strName }}</small>
            </a>
            <small>
              <div>{{ row.objCoursePage.objCourse.strName }}</div>
              <div>{{ row.objCoursePage.objCourse.objProgramYearPeriod.strName }}</div>
              <div *ngIf="row.objCoursePage.objCourse.objProgramYearPeriod.objProgramYear">
                <div>{{ row.objCoursePage.objCourse.objProgramYearPeriod.objProgramYear.strName }}</div>
                <div>{{ row.objCoursePage.objCourse.objProgramYearPeriod.objProgramYear.objCalendarYear.strName }}</div>
                <div>{{ row.objCoursePage.objCourse.objProgramYearPeriod.objProgramYear.objProgram.strName }}</div>
              </div>
              <div *ngIf="row.objCoursePage.objCourse.objProgramYearPeriod.objProgramCourseYear">
                <div>{{ row.objCoursePage.objCourse.objProgramYearPeriod.objProgramCourseYear.strName }}</div>
                <div>{{ row.objCoursePage.objCourse.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.strName }}</div>
                <div>{{ row.objCoursePage.objCourse.objProgramYearPeriod.objProgramCourseYear.objCalendarYear.strName }}</div>
                <div>{{ row.objCoursePage.objCourse.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.objProgram.strName }}</div>
              </div>
            </small>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
