import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '../../../../shared/translate';
import { TeacherOverviewResponse } from '../../../../_models/responses/overview/teacher.overview';

@Component({
  selector: '<app-overview-teacher-exemption-request></app-overview-teacher-exemption-request>',
  templateUrl: './exemption-request.component.html',
  styleUrls: ['./exemption-request.component.scss'],
})
export class TeacherOverviewExemptionRequestComponent implements OnInit {
  @Input() overview: TeacherOverviewResponse;
  constructor(public translate: TranslateService) {}

  ngOnInit() {}
}
