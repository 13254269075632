import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '../../../../shared/translate';
import { TeacherOverviewResponse } from '../../../../_models/responses/overview/teacher.overview';

@Component({
  selector: 'app-overview-teacher-course-submit',
  templateUrl: './course-submit.component.html',
  styleUrls: ['./course-submit.component.scss'],
})
export class TeacherOverviewCourseSubmitComponent implements OnInit {
  @Input() overview: TeacherOverviewResponse;
  constructor(public translate: TranslateService) {}

  ngOnInit() {}
}
