import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { TranslateService } from '../../translate';


@Component({
  selector: '<app-confirmdialog></app-confirmdialog>',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class AppConfirmdialogComponent implements OnInit {
  // ViewChild's from the template
  @ViewChild('basicModal', { static: true }) basicModal: any;

  @Input() title: string;
  @Input() description: string;
  @Input() btncancel: string;
  @Input() btnok: string;

  constructor(private translate: TranslateService) {

  }

  ngOnInit() {
    this.initModal();
  }

  /** Modal start */

  deleteModal = {
    title: '',
    description: '',
    btn_cancel: '',
    btn_ok: '',
    command: null,
    args: null,
    class: ''
  };

  initModal() {
    this.deleteModal = {
      title: this.title,
      description: this.description,
      btn_cancel: this.btncancel,
      btn_ok: this.btnok,
      command: null,
      args: null,
      class: 'btn-danger'
    };
  }

  /**
   *
   * @param command the function to be executed on confirm
   * @param args passing arguments in the defined function. Default = null
   */
  openModal(command: any, args: Array<any> = null, title: string = null, description: string = null, ok_button: string = null, btn_class: string = null) {
    this.deleteModal.command = command;
    this.deleteModal.args = args;

    if (title !== null) {
      this.deleteModal.title = title;
    } else {
      this.deleteModal.title = this.title;
    }

    if (description !== null) {
      this.deleteModal.description = description;
    } else {
      this.deleteModal.description = this.description;
    }

    if (ok_button !== null) {
      this.deleteModal.btn_ok = ok_button;
    } else {
      this.deleteModal.btn_ok = this.btnok;
    }

    if (btn_class !== null) {
      this.deleteModal.class = btn_class;
    } else {
      this.deleteModal.class = 'btn-danger';
    }

    this.basicModal.show();
  }

  /**
   * Execute functions after confirmed modal
   */
  confirmModal() {
    if (this.deleteModal.args != null) {
      const args = new Array();
      for (let i = 0; i < this.deleteModal.args.length; i++) args.push(this.deleteModal.args[i]);
      this.deleteModal.command.apply(this, args);
    } else {
      this.deleteModal.command();
    }

    this.basicModal.hide();
  }

  /**
   * Cancel the modal and reset everything
   */
  cancelModal() {
    this.deleteModal.command = null;
    this.deleteModal.args = null;
    this.basicModal.hide();
  }
}
