import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StudentContactType } from '../_models/student/studentcontact';
import { TranslateService } from '../shared/translate';


@Injectable()
export class AppUtils {
    constructor(
        private translate: TranslateService
    ) {

    }

    getLangFromStudentContactType(studentContactType: StudentContactType) {
        switch (studentContactType) {
            case StudentContactType.ORAL: return this.translate.instant('_student_contact_type_oral');
            case StudentContactType.WRITTEN: return this.translate.instant('_student_contact_type_written');
            case StudentContactType.EMAIL: return this.translate.instant('_student_contact_type_email');
            case StudentContactType.CHAT: return this.translate.instant('_student_contact_type_chat');
            case StudentContactType.WEBFORM: return this.translate.instant('_student_contact_type_webform');
        }
    }
}
