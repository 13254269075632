import { ServerPage } from './serverpage';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '../../shared/translate';
import { MainConfig } from '../../_helpers/config';

export class MainDatatableComponent {
    navigateUrl = true;
    allRowsSelected: boolean = false;

    // Datatable properties
    rows: Array<any> = [];

    // These are the messages for the datatable
    messages = {
        emptyMessage: '',
        totalMessage: ''
    };
    // Server pagination of the datatable
    page = new ServerPage();


    // Selections properties
    isToolbarActive: boolean = false;
    selected = [];
    temp = [];
    itemsSelected: string = '';
    itemCount: number = 0;

    // Filter properties
    searchValue: string = null;
    isSearchActive: boolean = false;

    // Loading bars above blocks. If true, loading loop will be shown
    loading = false;

    urlPageNumber: number = 0;

    getList: (page: ServerPage) => void;

    mainURL: string;
    route: ActivatedRoute;
    router: Router;
    config: MainConfig;
    translate: TranslateService;

    selectedFields: {
        itemSelected: string;
        itemsSelected: string;
    } = {
            itemSelected: '',
            itemsSelected: '',
        };
    constructor(
        route: ActivatedRoute,
        router: Router,
        config: MainConfig,
        mainURL: string,

        selectedFields: {
            itemSelected: string;
            itemsSelected: string;
        } = null,
        translate: TranslateService = null
    ) {
        this.mainURL = mainURL;
        this.config = config;
        this.route = route;
        this.router = router;
        this.selectedFields = selectedFields;
        this.translate = translate;

        // Init for pagination of datatable
        this.page.pageNumber = 0;
        this.page.limit = 20;
        this.page.count = 0;
        this.page.query = null;
        this.page.custom = {};

        this.route.params.subscribe((params) => {
            const pagenumber = params['pagenumber'];
            if (typeof pagenumber !== 'undefined') {
                this.urlPageNumber = (<number>pagenumber) - 1;
            }
        });
    }

    setListFn(fn: (page: ServerPage) => void) {
        this.getList = fn;
    }

    /**
     * This method will set the datatable to a page of the server
     * @param pageInfo { offset: value }
     */

    setPage(pageInfo, backendQuery: boolean = true) {
        this.page.pageNumber = pageInfo.offset;
        const url = this.mainURL + (this.page.pageNumber + 1);

        if (this.navigateUrl) {
            this.router.navigate([url]);
        }
        if (backendQuery) {
            this.getList(this.page);
        }
    }

    /**
    * Filter options start
    */

    /**
     * @param event Keyup event of the searchbox
     */
    stillSearching: boolean = false;

    delayTimer;
    updateFilter(event) {
        clearTimeout(this.delayTimer);
        const self = this;
        this.delayTimer = setTimeout(() => {
            if (!this.navigateUrl) {
                self.page.offset = 0;
                self.page.pageNumber = 0;
            }
            const val = event.target.value.toLowerCase();
            this.page.query = val;
            this.getList(self.page);
        }, 500);
    }

    triggerClose(event) {
        this.rows = this.temp;
        this.searchValue = '';
        this.isSearchActive = !this.isSearchActive;
        this.page.query = null;
        this.getList(this.page);
    }

    /**
     * Selections start
     */

    onSelect({ selected }) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
        if (selected.length == 1) {
            this.isToolbarActive = true;
            this.itemCount = selected.length;
            this.itemsSelected = this.translate.instant(this.selectedFields.itemSelected);
        } else if (selected.length > 0) {
            this.isToolbarActive = true;
            this.itemCount = selected.length;
            this.itemsSelected = this.translate.instant(this.selectedFields.itemsSelected);
        } else {
            this.isToolbarActive = false;
        }
    }
}
