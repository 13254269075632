<div class="m-portlet--mobile">
  <div class="m-portlet__head">
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
        <h3 class="m-portlet__head-text"> {{ '_students_detail_contact_moments' | translate }}
          <small>initialized from remote json file</small>
        </h3>
      </div>
    </div>
  </div>
  <div class="m-portlet__body">
    
    <div>
      <!--begin: Search Form -->
      <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
        <div class="row align-items-center">
          <div class="col-xl-8 order-2 order-xl-1">
            <div class="form-group m-form__group row align-items-center">
              <div class="col-md-4">
                <div class="m-input-icon m-input-icon--left">
                  <input type="text" class="form-control m-input m-input--solid data-filter" placeholder="{{ '_filter' | translate }}" id="generalSearch"
                    (keyup)='updateFilter($event)' [(ngModel)]="searchValue">

                  <span class="m-input-icon__icon m-input-icon__icon--left">
                    <span>
                      <i class="la la-search"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="userCanManageContactMoment()" class="col-xl-4 order-1 order-xl-2 m--align-right">
            <a href="javascript:void(0)" [routerLink]="['/students/view/'+student.intStudentID+'/contact']" routerLinkActive="router-link-active"
              class="btn btn-primary m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
              <span>
                <i class="la la-plus"></i>
                <span>{{ '_student_contacts_new' | translate }}</span>
              </span>
            </a>
            <div class="m-separator m-separator--dashed d-xl-none"></div>
          </div>
        </div>
      </div>
      <!--end: Search Form -->
      <!--begin: Datatable -->

      <div class="table-responsive">
        <ngx-datatable #table [messages]="messages" class="material min-width-700" [rows]="rows" [columnMode]="'force'" [footerHeight]="50"
          [rowHeight]="'auto'" [selected]="selected" [selectionType]="'checkbox'" (select)='onSelect($event)' [externalPaging]="(searchValue == null || searchValue == '')"
          [count]="page.count" [offset]="page.pageNumber" [limit]="page.limit" (page)='setPage($event)'>

          <ngx-datatable-column name="{{ '_students_contacts_attachment' | translate }}">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <b>{{row.intStudentContactID}}</b>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="{{ '_students_contacts_subject' | translate }}">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a href="javascript:void(0)" [routerLink]="['/students/view/'+student.intStudentID+'/contact/'+row.intStudentContactID]">
                {{row.strSubject}}
              </a>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="{{ '_students_contacts_type' | translate }}">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a href="javascript:void(0)" [routerLink]="['/students/view/'+student.intStudentID+'/contact/'+row.intStudentContactID]">
                {{appUtils.getLangFromStudentContactType(row.strType)}}
              </a>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="{{ '_students_contacts_date' | translate }}">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.datDate | date:'dd-MM-yyyy'}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="{{ '_students_contacts_mentor' | translate }}">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a href="javascript:void(0)" [routerLink]="['/users/view/'+row.intCreateUserID]">
                <span>{{row.objCreateUser.strFirstName}} {{row.objCreateUser.strLastName}}
                </span>
              </a>
            </ng-template>
          </ngx-datatable-column>



          <ngx-datatable-column name="{{ '_students_contacts_description' | translate }}">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.strDescription }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="userCanManageContactMoment()" name="{{ '_students_contacts_manage' | translate }}">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a href="javascript:void(0)" [routerLink]="['/students/view/'+student.intStudentID+'/contact/'+row.intStudentContactID]">
                <i class="la la-pencil"></i>
              </a>
              <a href="javascript:void(0)" (click)="deleteStudentContact(row)">
                <i class="la la-close"></i>
              </a>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
      <!--end: Datatable -->
    </div>

  </div>
</div>