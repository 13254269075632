import { map } from 'rxjs/operators';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../_models';
import { MainConfig } from '../_helpers/config';
import { Helpers } from '../helpers';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

export enum UserPermissionEnum {
  COURSE_EDIT = 1,
  COURSE_CREATE = 2,
  COURSE_DELETE = 3,
  COURSE_VIEW = 4,
  COURSE_PAGE_MANAGE = 13,

  PAGE_MANAGE = 15,

  USERS_MANAGE = 5,
  USERS_PERMISSION_ROLES = 12,

  STUDENTS_MANAGE = 6,
  STUDENTS_VIEW = 9,
  STUDENTS_CONTACT_VIEW = 10,
  STUDENTS_CONTACT_MANAGE = 11,

  PROGRAM_MANAGE = 7,
  APPLICATION_MANAGE = 8,

  GROUPS_MANAGE = 17,

  FORMS_MANAGE = 18,

  EXEMPTIONS_APPROVE = 19,
  EXEMPTIONS_PUSH = 20,
  EXEMPTIONS_REQUEST_CREATE = 21,

  EXEMPTIONS_UPLOAD_FILE = 22,

  COURSE_MANAGE_GRADES = 23,
  COURSE_MANAGE_ANALYSIS = 24,
}

@Directive()
@Injectable()
export class AuthenticationService {
  @Output() userEmitter: EventEmitter<User> = new EventEmitter();

  private _userTokenChangedSource = new BehaviorSubject<boolean>(false);
  _userTokenChanged$ = this._userTokenChangedSource.asObservable();

  user: User;

  localObject: { username: string; token: string; refreshToken: string; user: User } = null;

  public token: string;
  public refreshToken: string;
  arrService = [];
  constructor(private http: HttpClient, private mainConfig: MainConfig) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.refreshToken = currentUser && currentUser.refreshToken;
    if (currentUser) {
      this.user = <User>currentUser.user;
      this.localObject = currentUser;
    } else {
      this.user = null;
    }
    this.userEmitter.emit(this.user);
  }

  verify() {
    return this.http
      .get(this.mainConfig.backendServerURL + 'api/core/verify', {
        headers: this.jwt(),
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  login(username: string, password: string): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    Helpers.setLoading(true);

    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/auth/authenticate',
        {
          username: username,
          password: password,
        },
        { headers: headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status !== 200) {
            return false;
          }

          const token = response.body.token;
          const refreshToken = response.body.refreshToken;
          this.user = <User>response.body.user;
          this.user.isTeacher = this.user.objUserRole.intUserRoleID === 2;
          this.token = token;
          this.localObject = {
            user: this.user,
            username: username,
            token: token,
            refreshToken: refreshToken,
          };
          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(this.localObject));
          this._userTokenChangedSource.next(true);
          this.userEmitter.emit(this.user);
          Helpers.setLoading(false, true);

          return true;
        })
      );
  }

  getCurrentUser() {
    return this.user;
  }

  setCurrentUser(user: User) {
    this.user = user;
    this.userEmitter.emit(this.user);
    this.localObject.user = this.user;
    localStorage.setItem('currentUser', JSON.stringify(this.localObject));
    this._userTokenChangedSource.next(true);
  }

  getSavedUserEmitter() {
    return this.userEmitter;
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    this.token = null;
    localStorage.removeItem('currentUser');
    this.user = null;
    this.userEmitter.emit(this.user);
  }

  hasPermission(...permissions: UserPermissionEnum[]) {
    if (this.user) {
      if (this.user.objUserRole && this.user.objUserRole.intUserRoleID == 1) {
        return true;
      }

      let output = false;
      this.user.objUserRole.permissions.forEach((perm) => {
        permissions.forEach((permis) => {
          if (perm.intPermissionID == permis) {
            output = true;
          }
        });
      });

      return output;
    } else {
      return false;
    }
  }

  hasPermissionWithoutAdminCheck(...permissions: UserPermissionEnum[]) {
    if (this.user) {
      let output = false;
      this.user.objUserRole.permissions.forEach((perm) => {
        permissions.forEach((permis) => {
          if (perm.intPermissionID == permis) {
            output = true;
          }
        });
      });
      return output;
    } else {
      return false;
    }
  }

  hasUserRoleID(roles: number[]) {
    return roles.includes(this.user.objUserRole.intUserRoleID);
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + currentUser.token });
      headers.append('x-access-token', this.token);
      headers.append('Content-Type', 'application/json');
      return headers;
    }
  }
}
