import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '../../../../shared/translate';
import { TeacherOverviewResponse } from '../../../../_models/responses/overview/teacher.overview';

@Component({
  selector: 'app-overview-teacher-course-analysis-reminder',
  templateUrl: './course-analysis-reminder.component.html',
})
export class TeacherOverviewCourseAnalysisReminderComponent implements OnInit {
  @Input() overview: TeacherOverviewResponse;
  constructor(public translate: TranslateService) {}

  ngOnInit() {}
}
