<div class="m-portlet m-portlet--mobile">
  <div class="m-portlet__head">
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
        <h3 class="m-portlet__head-text">
          {{ '_course_view_request_to' | translate }}
          <!-- initialized from remote json file -->
        </h3>
      </div>
    </div>
  </div>
  <div class="m-portlet__body">
    <div class="table-responsive" *ngIf="overview">
      <ngx-datatable
        #table
        class="material min-width-700"
        [messages]="{
          emptyMessage: this.translate.instant('_course_view_request_no_requests_found'),
          totalMessage: this.translate.instant('_exemptions_total')
        }"
        [rows]="overview.item.courseViewRequests"
        [columnMode]="'force'"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="3"
      >
        <ngx-datatable-column name="Student">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>
              <a href="javascript:void(0)" [routerLink]="['/courses/view/' + row.objCourse.intCourseID]">
                {{ row.objCourseStudent.strFirstName }} {{ row.objCourseStudent.strLastName }}
              </a>
            </div>
            <div>
              <small>
                <strong>
                  <span *ngIf="row.strType == CourseStudentTypeEnum.RESIT">{{
                    '_course_view_access_resit' | translate
                  }}</span>
                  <span *ngIf="row.strType == CourseStudentTypeEnum.EXEMPTION">{{
                    '_course_view_access_exemption' | translate
                  }}</span>
                </strong>
              </small>
            </div>
            <div>
              <small>
                {{ '_course_view_access' | translate }}:<br />
                <b>
                  <span class="text-success" *ngIf="row.booIsAllowed">{{
                    '_course_view_access_granted' | translate
                  }}</span>
                  <span class="text-danger" *ngIf="!row.booIsAllowed">{{
                    '_course_view_access_denied' | translate
                  }}</span>
                </b>
              </small>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Onderwijseenheid">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a href="javascript:void(0)" [routerLink]="['/courses/view/' + row.objCourse.intCourseID]">
              {{ row.objCourse.strName }}
            </a>
            <small>
              <div>{{ row.objCourse.strName }}</div>
              <div>{{ row.objCourse.objProgramYearPeriod.strName }}</div>
              <div *ngIf="row.objCourse.objProgramYearPeriod.objProgramYear">
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramYear.strName }}</div>
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramYear.objCalendarYear.strName }}</div>
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramYear.objProgram.strName }}</div>
              </div>
              <div *ngIf="row.objCourse.objProgramYearPeriod.objProgramCourseYear">
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramCourseYear.strName }}</div>
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.strName }}</div>
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramCourseYear.objCalendarYear.strName }}</div>
                <div>
                  {{ row.objCourse.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.objProgram.strName }}
                </div>
              </div>
            </small>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button
              (click)="grantAccess(row.objCourse.intCourseID, row.intStudentID, true)"
              class="btn btn-success btn-sm w-100"
            >
              <span>{{ '_course_view_access_grant' | translate }}</span>
            </button>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button
              (click)="grantAccess(row.objCourse.intCourseID, row.intStudentID, false)"
              class="btn btn-primary btn-sm w-100"
            >
              <span>{{ '_course_view_access_deny' | translate }}</span>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
