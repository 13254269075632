import {Component, OnInit, Input} from '@angular/core';
import {CourseService} from '../../_services/course.service';
import {BreadCrumbType, BreadCrumb} from '../../_models/core/breadcrumb';

@Component({
  selector: 'course-breadcrumbs',
  templateUrl: './course-breadcrumbs.component.html',
  styleUrls: ['./course-breadcrumbs.component.scss'],
})
export class CourseBreadcrumbsComponent implements OnInit {
  @Input('type') type: BreadCrumbType;
  @Input('ID') ID: number;
  @Input('currentPageName') currentPageName: string;

  constructor(private courseService: CourseService) { }

  breadCrumbs: BreadCrumb[];

  ngOnInit() {
    this.getBreadcrumbs();
  }

  async getBreadcrumbs() {
    switch (this.type) {
      case BreadCrumbType.Course:
        const response = await this.courseService.getBreadcrumbs(this.ID).toPromise();
        const breadCrumbs = this.setHypertextReferences(response.item.breadCrumbs);
        this.breadCrumbs = breadCrumbs;
        break;
    }
  }

  setHypertextReferences(breadcrumbs: BreadCrumb[]) {
    let urlBuild = '';

    for (const breadcrumb of breadcrumbs) {
      switch (breadcrumb.type) {
        case BreadCrumbType.Location:
          breadcrumb.href = urlBuild + '/programs';
          break;
        case BreadCrumbType.Program:
          breadcrumb.href = urlBuild + '/' + breadcrumb.ID;
          break;
        case BreadCrumbType.CalendarYear:
          breadcrumb.href = urlBuild + '/calendaryear/' + breadcrumb.ID;
          break;
        case BreadCrumbType.ProgramYear:
          breadcrumb.href = urlBuild + '/year/' + breadcrumb.ID;
          break;
        case BreadCrumbType.ProgramYearPeriod:
          breadcrumb.href = urlBuild + '/period/' + breadcrumb.ID;
          break;
        case BreadCrumbType.Course:
          breadcrumb.href = '/courses/view/' + breadcrumb.ID;
          breadcrumb.fullUrl = true;
          break;
      }
      if (!breadcrumb.fullUrl) {
        urlBuild = breadcrumb.href;
      }
    }
    return breadcrumbs;
  }
}
