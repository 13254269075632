import { Observable, Subscriber, of } from 'rxjs';

import { catchError, map, expand } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { MainService } from './main.service';
import { ServerPage, User } from '../_models';
import { Helpers } from '../helpers';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class UserService extends MainService {
  /**
   * User Roles
   */
  getAllUserRoles(limit = 250): Observable<any> {
    return this.getAllList(limit, 'getUserRoles');
  }
  getUserRoles(page: ServerPage = null, exclude: [number] = null): Observable<any> {
    const params = null;
    return this.getList('api/user/role', params, page, exclude);
  }

  /**
   * Get all customers even if there is a maximum limit
   */
  getAllUsers(limit = 250, page: ServerPage = null): Observable<any> {
    // Create the server page object
    // var page = new ServerPage();
    if (page == null) {
      page = new ServerPage();
    }
    page.pageNumber = 0;
    page.limit = limit;

    // Create te observable
    const request = this.getUsers(page);

    // Values to be returned
    let values: Array<User> = [];

    // Create a custom observable
    const observable = new Observable((observer) => {
      // Expand the request to make calls until all pages are fetched
      request
        .pipe(
          expand((response) => {
            // If the limit exceeds the maximum, set it to maximum.
            if (response.limit < page.limit) {
              page.limit = response.limit;
            }

            // Get the rows and merge it with values
            const rows: Array<User> = <Array<User>>response.rows;
            values = values.concat(rows);

            // Check if all pages are fetched, if not, start a new request
            const count = response.count;
            const rowsFetched = page.pageNumber * page.limit + page.limit;
            if (rowsFetched < count) {
              // Start new request
              page.pageNumber = page.pageNumber + 1;
              return this.getUsers(page);
            }

            // All pages fetched, notify the observer
            observer.next(values);

            //
            return of();
          })
        )
        // Start the observable
        .subscribe();
    });

    return observable;
  }

  getUsers(page: ServerPage = null, exclude: [number] = null, isInfo: boolean = false): Observable<any> {
    // get product from api

    let url = '';
    if (isInfo) {
      url = this.mainConfig.backendServerURL + 'api/user/infouser';
    } else {
      url = this.mainConfig.backendServerURL + 'api/user';
    }
    if (page.pageNumber || page.limit || page.offset || page.query) {
      url += '?';
    }

    if (page.pageNumber) url += 'page=' + page.pageNumber + '&';
    if (page.query) url += 'query=' + page.query + '&';
    if (page.limit) url += 'limit=' + page.limit + '&';
    if (page.offset) url += 'offset=' + page.offset + '&';
    if (exclude) url += 'exclude=' + exclude.join() + '&';
    if (page.params) url += page.params + '&';

    Helpers.setLoading(true);

    return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        const users = response.body;
        Helpers.setLoading(false);
        return users;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  getUser(intUserID: number): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .get(this.mainConfig.backendServerURL + 'api/user/' + intUserID, { headers: this.headers, observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);

          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  deleteImageUser(user: User): Observable<any> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + 'api/user/' + user.intUserID + '/image';
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  saveUser(user: User): Observable<any> {
    Helpers.setLoading(true);
    // User save request
    const saveRequest = this.http
      .post(
        this.mainConfig.backendServerURL + 'api/user',
        {
          intUserID: user.intUserID,
          strEmail: user.strEmail,
          strPassword: user.strPassword,
          strNewPassword: user.strNewPassword,
          strFirstName: user.strFirstName,
          strLastName: user.strLastName,
          intUserRoleID: user.intUserRoleID,

          datBirthDate: user.datBirthDate,
          strGender: user.strGender,

          strInsertion: user.strInsertion,
          strAddress: user.strAddress,
          strZipCode: user.strZipCode,
          strCity: user.strCity,

          strDescription: user.strDescription,
          booVisible: user.booVisible,

          strPhoneNumber: user.strPhoneNumber,
          strPrivateEmail: user.strPrivateEmail,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 205) {
            return { code: 205 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );

    const uploadRequest = (observer: Subscriber<{}>, saveResponse) => {
      const fileUploader = user.fileUploader;
      fileUploader.setOptions({
        url: this.mainConfig.backendServerURL + 'api/user/image/',
        authToken: 'Bearer ' + this.authenticationService.token,
        additionalParameter: {
          intUserID: user.intUserID,
        },
      });
      const item = fileUploader.queue[0];
      fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        const newResponse = new HttpResponse({
          status: typeof saveResponse !== 'undefined' && saveResponse.status ? saveResponse.status : status,
          statusText:
            typeof saveResponse !== 'undefined' && saveResponse.statusText ? saveResponse.statusText : response.message,
          url: typeof saveResponse !== 'undefined' && saveResponse.url ? saveResponse.url : item.url,
          body: typeof saveResponse !== 'undefined' && saveResponse.body ? saveResponse.body : response,
        });
        fileUploader.clearQueue();
        observer.next(newResponse);
      };
      item.upload();
    };

    const observable = new Observable((observer) => {
      saveRequest
        .pipe(
          expand((response) => {
            if (user.fileUploader && user.fileUploader.queue.length > 0) {
              // start file upload request
              uploadRequest(observer, response);
            } else {
              Helpers.setLoading(false);
              // continue
              observer.next(response);
            }

            return of();
          }),
          catchError((error) => {
            observer.error(error);
            return of();
          })
        )
        .subscribe();
    });
    return observable;
  }

  deleteUser(user: User): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/user/' + user.intUserID;
    Helpers.setLoading(true);
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  /**
   * Google
   */

  deleteGoogleAccount(intGAccountID: number): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/user/google/' + intGAccountID;
    Helpers.setLoading(true);
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }
}
