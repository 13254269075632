export class ServerPage {
    /**
     * Current page number
     */
    pageNumber: number;


    /**
     * Client-side usage
     */
    count: number;


    /**
     * The limit of the results
     */
    limit: number;

    /**
     * Offset: Skip amount rows from result. E.g. starting to query after 10 rows
     */
    offset: number;

    /**
     * Query: This is the query string to search for matches
     */
    query: string;

    /**
     * Params: Send parameters to the backend param1=value1&param2=value2
     */
    params: string;

    // Any customs
    custom: any;
}
