
import { Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { Permission } from '../_models/core/permission';
import { UserRole } from '../_models/core/userrole';
import { ServerPage } from '../_models';
import { ActivityLog } from '../_models/core/activitylog';
import { Helpers } from '../helpers';
import { HttpResponse } from '@angular/common/http';
import { Page } from '../_models/core/page';

@Injectable()
export class CoreService extends MainService {
    getAllPermissions(): Observable<Permission[]> {
        // get product from api
        Helpers.setLoading(true);
        const url = this.mainConfig.backendServerURL + 'api/core/permission';
        return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            const users = response.body;
            Helpers.setLoading(false);
            return users.rows;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    addPermissionToUserRoles(userRoles: UserRole[]): Observable<any> {
        Helpers.setLoading(true);
        const arrValues = [];
        userRoles.forEach((userRole) => {
            const objUserRole = {
                intUserRoleID: userRole.intUserRoleID,
                arrPermissions: []
            };
            userRole.newPermissions.forEach((newPermission) => {
                if (newPermission.checked) {
                    objUserRole.arrPermissions.push(newPermission.intPermissionID);
                }
            });
            arrValues.push(objUserRole);
        });

        return this.http.post(this.mainConfig.backendServerURL + 'api/core/permission/userrole', {
            arrValues: arrValues,
        }, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            if (response.status === 205) {
                return { code: 205 };
            }
            Helpers.setLoading(false);
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }


    /**
     * User roles
     */
    saveUserRole(userRole: UserRole): Observable<any> {
        Helpers.setLoading(true);
        return this.http.post(this.mainConfig.backendServerURL + 'api/core/userrole', {
            intUserRoleID: userRole.intUserRoleID,
            strTitle: userRole.strTitle,
        }, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            if (response.status === 205) {
                return { code: 205 };
            }
            Helpers.setLoading(false);
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    deleteUserRole(userRole: UserRole): Observable<any> {
        Helpers.setLoading(true);
        const url = this.mainConfig.backendServerURL + 'api/core/userrole/' + userRole.intUserRoleID;
        return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            Helpers.setLoading(false);
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    getAllUserRoles(): Observable<UserRole[]> {
        // get product from api
        Helpers.setLoading(true);
        const url = this.mainConfig.backendServerURL + 'api/core/userrole';
        return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            const users = response.body;
            Helpers.setLoading(false);
            return users.rows;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    /**
     * Activity Logs
     */
    getActivityLogs(page: ServerPage = null, onlyVerifyRequired: boolean = false, intUserID: number = null, intOrderID: number = null): Observable<any> {
        Helpers.setLoading(true);
        // get product from api
        let url = this.mainConfig.backendServerURL + 'api/core/activitylogs';
        if (page.pageNumber || page.limit || page.offset || page.query) {
            url += '?';
        }

        if (page.pageNumber) url += 'page=' + page.pageNumber + '&';
        if (page.query) url += 'query=' + page.query + '&';
        if (page.limit) url += 'limit=' + page.limit + '&';
        if (page.offset) url += 'offset=' + page.offset + '&';
        if (onlyVerifyRequired) url += 'onlyVerifyRequired=true' + '&';
        if (intUserID) url += 'intUserID=' + intUserID + '&';
        if (intOrderID) url += 'intOrderID=' + intOrderID + '&';

        return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            const activityLogs = response.body;
            Helpers.setLoading(false);
            return activityLogs;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    verifyActivityLog(activityLog: ActivityLog): Observable<any> {
        Helpers.setLoading(true);
        return this.http.post(this.mainConfig.backendServerURL + 'api/core/activitylog/verify', {
            intActivityLogID: activityLog.intActivityLogID,
        }, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            if (response.status === 205) {
                return { code: 205 };
            }
            Helpers.setLoading(false);
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    saveFileOrders(fileOrders: any[]): Observable<any> {
        Helpers.setLoading(true);
        return this.http.post(this.mainConfig.backendServerURL + 'api/core/save-file-orders', {
            fileOrders: fileOrders
        }, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            Helpers.setLoading(false);
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }

    getPageInfo(intPageId:number ) {
        return this.getItem('api/core/page', intPageId);
    }

    savePageInfo(page: Page): Observable<any> {
        Helpers.setLoading(true);
        return this.http.post(this.mainConfig.backendServerURL + 'api/core/page/'+page.intPageID, {
            strContent: page.strContent
        }, { headers: this.headers, observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            Helpers.setLoading(false);
            return response.body;
        }), catchError((error) => this.unAuthorizedHandler(error)));
    }
}
