<ng-template [ngIf]="tinHandler.turnItInData">
  
  <div *ngIf="!tinHandler.noCloseButton" style="height:50px;">
    <i (click)="tinHandler.clearTINData()" style="margin-right:10px;color:red;font-size:30pt"
      class="fa fa-times-circle float-right"></i>
  </div>
  <form id="ltiForm" target="my_frame" [action]="tinHandler.turnItInData.endpoint" method="POST" novalidate>
    <input *ngFor="let item of tinHandler.turnItInData.values;let i = index" type="hidden"
      [name]="tinHandler.turnItInData.keys[i]" [value]="tinHandler.turnItInData.values[i]" />
  </form>
  <iframe name="my_frame" src="" frameborder="0" height="97%" width="100%"></iframe>
</ng-template>