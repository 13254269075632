import { User } from '../../auth/_models';
import { Student } from './student';

export enum StudentContactType {
    ORAL = 'ORAL',
    WRITTEN = 'WRITTEN',
    EMAIL = 'EMAIL',
    CHAT = 'CHAT',
    WEBFORM = 'WEBFORM'
}

export class StudentContact {
    intStudentContactID: number;
    strSubject: string;
    strDescription: string;
    datDate: Date;
    strType: StudentContactType;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    intUpdateUserID: number;
    objUpdateUser: User;

    intCreateUserID: number;
    objCreateUser: User;

    intStudentID: number;
    objStudent: Student;
}
