import { ModuleWithProviders, NgModule } from '@angular/core';

import { TranslatePipe } from '../translate';

@NgModule({
  imports: [],
  declarations: [TranslatePipe],
  exports: [TranslatePipe],
  providers: [TranslatePipe],
})
export class TranslateModule {
  static forRoot(): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: [],
    };
  }
}
