/**
 * Packge imports
 */
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpParams, HttpResponse} from '@angular/common/http';

/**
 * Custom imports
 */
import {MainService} from '../main.service';
import {Student} from '../../_models/student/student';
import {Helpers} from '../../helpers';
import {CourseAssessment} from '../../_models/course/courseassessment';
import {CoursePageExamSubmit} from '../../_models/course/coursepageexamsubmit';
import {CoursePageExam} from '../../_models/course/coursepageexam';
import {CoursePageExamFinalGrade} from '../../_models/course/CoursePageExamFinalGrade';
import {CourseFinalGrade} from '../../_models/course/CourseFinalGrade';


// eslint-disable-next-line new-cap
@Injectable()
export class CourseFinalGradeService extends MainService {
  getStudents(intCourseID: number): Observable<Student[]> {
    Helpers.setLoading(true);
    const query = new HttpParams();

    query.append('intCourseID', intCourseID.toString());


    return this.http.get(`${this.mainConfig.backendServerURL}api/course/final-grade/student?intCourseID=${intCourseID}`, {
      headers: this.headers,
      observe: 'response',
      params: query,
    }).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);
      return response.body;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }

  getStudentSubmissions(intCourseID: number, intStudentID: number): Observable<{ grades: {finalGrade: CourseFinalGrade, table: CoursePageExamFinalGrade[]}, studentCourseAssessment: CourseAssessment, courseExams:CoursePageExam[] }> {
    Helpers.setLoading(true);

    const query = {
      intCourseID: intCourseID.toString(),
      intStudentID: intStudentID.toString(),
    };

    return this.http.get(`${this.mainConfig.backendServerURL}api/course/final-grade/exam`, {
      headers: this.headers,
      observe: 'response',
      params: query,
    }).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);

      return response.body.item;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }

  setFinalGrade(intCourseID: number, intStudentID: number): Observable<CourseAssessment> {
    Helpers.setLoading(true);

    const payload = {
      intCourseID: intCourseID.toString(),
      intStudentID: intStudentID.toString(),
    };

    return this.http.post(`${this.mainConfig.backendServerURL}api/course/final-grade/exam`, payload, {
      headers: this.headers,
      observe: 'response',
    }).pipe(map((response: HttpResponse<any>) => {
      Helpers.setLoading(false);

      return response.body.item;
    }), catchError((error) => this.unAuthorizedHandler(error)));
  }
}
