<div class="m-portlet m-portlet--mobile">
  <div class="m-portlet__head">
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
        <h3 class="m-portlet__head-text">
          {{ '_exemption_request_title' | translate }}
        </h3>
      </div>
    </div>
  </div>
  <div class="m-portlet__body">
    <div class="table-responsive" *ngIf="overview">
      <ngx-datatable
        #table
        class="material min-width-700"
        [messages]="{
          emptyMessage: this.translate.instant('_exemptions_empty_message_no_open_exemption_requests'),
          totalMessage: this.translate.instant('_exemptions_total')
        }"
        [rows]="overview.item.courseExemptionRequests"
        [columnMode]="'force'"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="3"
      >
        <ngx-datatable-column name="Student">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a href="javascript:void(0)" [routerLink]="['/exemptions/detail/' + row.intCourseExRequestID]">
              {{ row.objStudent.strFirstName }} {{ row.objStudent.strLastName }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Onderwijseenheid">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a href="javascript:void(0)" [routerLink]="['/exemptions/detail/' + row.intCourseExRequestID]">
              {{ row.objCourse.strName }}
            </a>
            <small>
              <div>{{ row.objCourse.strName }}</div>
              <div>{{ row.objCourse.objProgramYearPeriod.strName }}</div>
              <div *ngIf="row.objCourse.objProgramYearPeriod.objProgramYear">
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramYear.strName }}</div>
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramYear.objCalendarYear.strName }}</div>
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramYear.objProgram.strName }}</div>
              </div>
              <div *ngIf="row.objCourse.objProgramYearPeriod.objProgramCourseYear">
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramCourseYear.strName }}</div>
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.strName }}</div>
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramCourseYear.objCalendarYear.strName }}</div>
                <div>{{ row.objCourse.objProgramYearPeriod.objProgramCourseYear.objProgramCourse.objProgram.strName }}</div>
              </div>
            </small>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
