import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../shared/translate';
import { TeacherOverviewResponse } from '../../../_models/responses/overview/teacher.overview';
import { AuthenticationService } from '../../../_services';
import { GlobalMessageHandler } from '../../../_services/message-handler.service';
import { OverviewService } from '../../../_services/overview.service';

@Component({
  selector: '<app-overview-teacher></app-overview-teacher>',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.scss'],
})
export class TeacherOverviewComponent implements OnInit {
  public overview: TeacherOverviewResponse;

  constructor(
    private overviewService: OverviewService,
    private gmHandler: GlobalMessageHandler,
    public translate: TranslateService,
    public authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.getOverview();
  }

  getOverview() {
    if (!this.authService.user.isTeacher) {
      return;
    }

    this.overviewService.getTeacherOverview().subscribe(
      (response) => {
        this.overview = response;
      },
      (error) => this.gmHandler.handleError(error)
    );
  }
}
