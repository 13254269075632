import { empty as observableEmpty, Observable, Subscriber } from 'rxjs';

import { catchError, map, expand } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { ServerPage } from '../_models';
import { Student } from '../_models/student/student';
import { Application } from '../_models/student/application';
import { StudentContact } from '../_models/student/studentcontact';
import { Helpers } from '../helpers';
import { FileUploader } from 'ng2-file-upload';
import { File } from '../_models/core/file';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { DataListResponse } from '../_models/core/datalistresponse';
import '../_models/student/education-grades';

@Injectable()
export class StudentService extends MainService {
  addTeacher(student: Student, intUserID: number): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/student/' + student.intStudentID + '/teacher',
        {
          intUserID: intUserID,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  removeTeacher(student: Student, intUserID: number): Observable<any> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + 'api/student/' + student.intStudentID + '/teacher/' + intUserID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  /* STUDENT */

  getStudentApplication(intApplicationID): Observable<any> {
    return this.getItem('api/student/application', intApplicationID);
  }

  getStudentApplications(page: ServerPage = null, exclude: [number] = null): Observable<any> {
    const params = null;
    return this.getList('api/student/application', params, page, exclude);
  }

  acceptApplication(application: Application) {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/student/application/' + application.intApplicationID + '/accept',
        {},
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  deleteApplication(application: Application): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/student/application/' + application.intApplicationID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  generateEnvironment(
    intStudentID,
    createAccountChecks: {
      booUserAccount: boolean;
      booGoogleAccount: boolean;
    }
  ): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/student/environment',
        {
          intStudentID: intStudentID,
          booUserAccount: createAccountChecks.booUserAccount,
          booGoogleAccount: createAccountChecks.booGoogleAccount,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  /**
   * Get all student even if there is a maximum limit
   */
  getAllStudentContacts(limit = 250, intStudentID: number): Observable<any> {
    // Create the server page object
    const page = new ServerPage();
    page.pageNumber = 0;
    page.limit = limit;

    // Create te observable
    const request = this.getStudentContacts(page, intStudentID);

    // Values to be returned
    let values: Array<StudentContact> = [];

    // Create a custom observable
    const observable = new Observable((observer) => {
      // Expand the request to make calls until all pages are fetched
      request
        .pipe(
          expand((response) => {
            // If the limit exceeds the maximum, set it to maximum.
            if (response.limit < page.limit) {
              page.limit = response.limit;
            }

            // Get the rows and merge it with values
            const rows: Array<StudentContact> = <Array<StudentContact>>response.rows;
            values = values.concat(rows);

            // Check if all pages are fetched, if not, start a new request
            const count = response.count;
            const rowsFetched = page.pageNumber * page.limit + page.limit;
            if (rowsFetched < count) {
              // Start new request
              page.pageNumber = page.pageNumber + 1;
              return this.getStudentContacts(page, intStudentID);
            }

            // All pages fetched, notify the observer
            observer.next(values);

            //
            return observableEmpty();
          })
        )
        // Start the observable
        .subscribe();
    });

    return observable;
  }

  getStudentContacts(page: ServerPage = null, intStudentID: number): Observable<any> {
    // get product from api
    let url = this.mainConfig.backendServerURL + 'api/student/contact';
    if (page.pageNumber || page.limit || page.offset || page.query) {
      url += '?';
    }

    if (page.pageNumber) url += 'page=' + page.pageNumber + '&';
    if (page.query) url += 'query=' + page.query + '&';
    if (page.limit) url += 'limit=' + page.limit + '&';
    if (page.offset) url += 'offset=' + page.offset + '&';
    url += 'intStudentID=' + intStudentID + '&';

    Helpers.setLoading(true);
    return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        const users = response.body;
        Helpers.setLoading(false);
        return users;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  getStudentContact(intStudentContactID: number): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .get(this.mainConfig.backendServerURL + 'api/student/contact/' + intStudentContactID, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  saveStudentContact(studentContact: StudentContact): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + 'api/student/contact',
        {
          intStudentContactID: studentContact.intStudentContactID,
          intStudentID: studentContact.intStudentID,

          strSubject: studentContact.strSubject,
          strDescription: studentContact.strDescription,
          datDate: studentContact.datDate,
          strType: studentContact.strType,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  deleteStudentContact(studentContact: StudentContact): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/student/contact/' + studentContact.intStudentContactID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  /* STUDENT */

  /**
   * Get all student even if there is a maximum limit
   */
  getAllStudents(limit = 250, order: string = null): Observable<any> {
    // Create the server page object
    const page = new ServerPage();
    page.pageNumber = 0;
    page.limit = limit;

    // Create te observable
    const request = this.getStudents(page, order);

    // Values to be returned
    let values: Array<Student> = [];

    // Create a custom observable
    const observable = new Observable((observer) => {
      // Expand the request to make calls until all pages are fetched
      request
        .pipe(
          expand((response) => {
            // If the limit exceeds the maximum, set it to maximum.
            if (response.limit < page.limit) {
              page.limit = response.limit;
            }

            // Get the rows and merge it with values
            const rows: Array<Student> = <Array<Student>>response.rows;
            values = values.concat(rows);

            // Check if all pages are fetched, if not, start a new request
            const count = response.count;
            const rowsFetched = page.pageNumber * page.limit + page.limit;
            if (rowsFetched < count) {
              // Start new request
              page.pageNumber = page.pageNumber + 1;
              return this.getStudents(page, order);
            }

            // All pages fetched, notify the observer
            observer.next(values);

            //
            return observableEmpty();
          })
        )
        // Start the observable
        .subscribe();
    });

    return observable;
  }

  getStudents(page: ServerPage = null, order: string = null): Observable<any> {
    // get product from api
    let url = this.mainConfig.backendServerURL + 'api/student';
    if (page.pageNumber || page.limit || page.offset || page.query) {
      url += '?';
    }

    if (page.pageNumber) url += 'page=' + page.pageNumber + '&';
    if (page.query) url += 'query=' + page.query + '&';
    if (page.limit) url += 'limit=' + page.limit + '&';
    if (page.offset) url += 'offset=' + page.offset + '&';
    if (page.custom && page.custom.isArchived) url += 'archived=true&';
    if (page.custom && page.custom.strOrder) url += 'order=' + page.custom.strOrder + '&';

    if (order) url += 'order=' + order + '&';

    Helpers.setLoading(true);
    return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        const users = response.body;
        Helpers.setLoading(false);
        return users;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  getStudent(intStudentID: number): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .get(this.mainConfig.backendServerURL + 'api/student/' + intStudentID, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  saveStudent(student: Student): Observable<any> {
    Helpers.setLoading(true);
    const saveRequest = this.http
      .post(
        this.mainConfig.backendServerURL + 'api/student',
        {
          intStudentID: student.intStudentID,
          strFirstName: student.strFirstName,
          strLastName: student.strLastName,
          strNickName: student.strNickName,

          strGender: student.strGender,
          datBirthDate: student.datBirthDate,
          strAddress: student.strAddress,
          strZipCode: student.strZipCode,
          strCity: student.strCity,
          strPhoneNumber: student.strPhoneNumber,
          strPrivateEmail: student.strPrivateEmail,
          strStudentEmail: student.strStudentEmail,
        },
        { headers: this.headers, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);

          if (response.status === 204) {
            return { code: 204 };
          }
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );

    const uploadRequest = (observer: Subscriber<{}>, saveResponse, student) => {
      const fileUploader = student.fileUploader;
      fileUploader.setOptions({
        url: this.mainConfig.backendServerURL + 'api/student/image/',
        authToken: 'Bearer ' + this.authenticationService.token,
        additionalParameter: {
          intStudentID: student.intStudentID,
        },
      });
      const item = fileUploader.queue[0];
      fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        const newResponse = new HttpResponse({
          status: typeof saveResponse !== 'undefined' && saveResponse.status ? saveResponse.status : status,
          statusText:
            typeof saveResponse !== 'undefined' && saveResponse.statusText ? saveResponse.statusText : response.message,
          url: typeof saveResponse !== 'undefined' && saveResponse.url ? saveResponse.url : item.url,
          body: typeof saveResponse !== 'undefined' && saveResponse.body ? saveResponse.body : response,
        });
        fileUploader.clearQueue();

        observer.next(saveResponse);
      };
      item.upload();
    };

    const observable = new Observable((observer) => {
      let studentResponse = null;
      saveRequest
        .pipe(
          expand((response) => {
            if (student.fileUploader && student.fileUploader.queue.length > 0) {
              // start file upload request
              response.item.fileUploader = student.fileUploader;
              studentResponse = response;
              uploadRequest(observer, response, response.item);
            } else {
              // continue
              observer.next(response);
            }
            return observableEmpty();
          })
        )
        .subscribe();
    });
    return observable;
  }

  deleteStudent(student: Student): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/student/' + student.intStudentID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  deleteImageStudent(student: Student): Observable<any> {
    const url = this.mainConfig.backendServerURL + 'api/student/' + student.intStudentID + '/image';
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  getGrades(page: ServerPage = null, _includeLowerGrades = false): Observable<any> {
    // get product from api
    let url = this.mainConfig.backendServerURL + 'api/student/grades';
    if (page.pageNumber || page.limit || page.offset || page.query) {
      url += '?';
    }

    if (page.pageNumber) url += 'page=' + page.pageNumber + '&';
    if (page.query) url += 'query=' + page.query + '&';
    if (page.limit) url += 'limit=' + page.limit + '&';
    if (page.offset) url += 'offset=' + page.offset + '&';
    url += `includeLowerGrade=${_includeLowerGrades ? '1' : '0'}`;

    Helpers.setLoading(true);
    return this.http.get(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        const users = response.body;
        Helpers.setLoading(false);
        return users;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  getAllGrades(
    page: ServerPage = null,
    httpParams: HttpParams = new HttpParams(),
    _includeLowerGrades = false
  ): Observable<DataListResponse> {
    // get product from api
    const url = this.mainConfig.backendServerURL + 'api/course/grades';

    if (page.pageNumber) httpParams = httpParams.set('page', page.pageNumber.toString());
    if (page.query) httpParams = httpParams.set('query', page.query.toString());
    if (page.limit) httpParams = httpParams.set('limit', page.limit.toString());
    if (page.offset) httpParams = httpParams.set('offset', page.offset.toString());
    httpParams = httpParams.set('includeLowerGrade', _includeLowerGrades ? '1' : '0');

    Helpers.setLoading(true);
    return this.http.get(url, { headers: this.headers, observe: 'response', params: httpParams }).pipe(
      map((response: HttpResponse<any>) => {
        const grades = response.body;
        Helpers.setLoading(false);
        return grades;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  uploadStudentFile(params: any, fileUploader: FileUploader): Observable<any> {
    Helpers.setLoading(true);

    const observable = new Observable((observer) => {
      const options = {
        url: this.mainConfig.backendServerURL + 'api/student/file',
        authToken: 'Bearer ' + this.authenticationService.token,
      };
      if (params) {
        options['additionalParameter'] = params;
      }
      fileUploader.setOptions(options);
      if (fileUploader.queue.length > 0) {
        const item = fileUploader.queue[0];
        fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          const newResponse = new HttpResponse({
            status: status,
            statusText: response.message,
            url: item.url,
            body: response,
          });
          Helpers.setLoading(false);
          fileUploader.clearQueue();
          observer.next(newResponse);
        };
        item.upload();

        fileUploader.onErrorItem = (item: any, response: string, status: any, headers: any) => {
          console.log(response);
          Helpers.setLoading(false);
        };
      } else {
        Helpers.setLoading(false);
        fileUploader.clearQueue();
        observer.next(null);
      }
    });
    return observable;
  }

  deleteStudentFile(file: File): Observable<any> {
    Helpers.setLoading(true);
    const url = this.mainConfig.backendServerURL + 'api/student/file/' + file.intFileID;
    return this.http.delete(url, { headers: this.headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        Helpers.setLoading(false);
        return response.body;
      }),
      catchError((error) => this.unAuthorizedHandler(error))
    );
  }

  getEducationGrades(student: Student): Observable<EducationGrades.EducationGradesResponse> {
    Helpers.setLoading(true);
    return this.http
      .get(this.mainConfig.backendServerURL + 'api/program/progress/student?intStudentID=' + student.intStudentID, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  getMyEducationGrades(): Observable<EducationGrades.EducationGradesResponse> {
    Helpers.setLoading(true);
    return this.http
      .get(this.mainConfig.backendServerURL + 'api/program/progress/me', {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  getMyApplications(includeHidden = false): Observable<EducationGrades.CourseApplicationsResponse> {
    Helpers.setLoading(true);
    return this.http
      .get(this.mainConfig.backendServerURL + `api/program/progress/course-applications?hidden=${includeHidden}`, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }

  toggleHideApplication(id: number): Observable<any> {
    Helpers.setLoading(true);
    return this.http
      .post(
        this.mainConfig.backendServerURL + `api/program/progress/toggle-hide-application`,
        {
          intCourseStudentID: id,
        },
        {
          headers: this.headers,
          observe: 'response',
        }
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          Helpers.setLoading(false);
          return response.body;
        }),
        catchError((error) => this.unAuthorizedHandler(error))
      );
  }
}
