<div class="program-top-breadcrumbs">
  <div class="m-content">
    <div class="row">
      <div class="col">
        <div style="float:left" *ngFor="let breadcrumb of breadCrumbs;let i = index;">
          <a [routerLink]="breadcrumb.href">
            {{breadcrumb.strName}}
          </a>
          <span style="margin-right:5px;"> > </span>
        </div>
        <div style="float:left">{{currentPageName}}</div>
      </div>
    </div>
  </div>
</div>