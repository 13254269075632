
enum VatType {
    standard = 'STANDARD',
    reduced = 'REDUCED',
    shifted = 'SHIFTED'
}

export class Vat {
    intVatID: number;
    datStartDate: string;
    datEndDate: string;
    douPercentage: number;
    strName: string;
    strType: VatType;
    createdAt: string;
    updatedAt: string;
    intUpdateUserID: number;
    intCreateUserID: number;

    values: number[] = [];
    value: number;
}
