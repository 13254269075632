import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from './_services/script-loader.service';
import { ThemeRoutingModule } from './theme/theme-routing.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { TRANSLATION_PROVIDERS, TranslateService } from './shared/translate';
import { AuthenticationService } from './_services';

import { HttpClientModule } from '@angular/common/http';
import { MainConfig } from './_helpers';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FileUploadModule } from 'ng2-file-upload';
import { AppUtils } from './_helpers/utils';
import { TranslateModule } from './shared/translate/translate.module';
import { Helpers } from './helpers';
import { PermissionGuard } from './guards/permission.guard';
import { GlobalMessageHandler } from './_services/message-handler.service';
import { FormsGuard } from './auth/_guards/forms.guard';
import { TINHandler } from './_services/turn-it-in-handler.service';

@NgModule({
  declarations: [ThemeComponent, AppComponent],
  imports: [
    FileUploadModule,
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemeRoutingModule,
    AuthModule,

    HttpClientModule,

    SharedModule.forRoot(),
    TranslateModule,
  ],
  providers: [
    Helpers,
    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
    ScriptLoaderService,
    TRANSLATION_PROVIDERS,
    TranslateService,
    AuthenticationService,
    { provide: MainConfig, useClass: MainConfig },
    AppUtils,
    PermissionGuard,
    FormsGuard,
    GlobalMessageHandler,
    TINHandler,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
