import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, UserPermissionEnum } from '../_services';

@Injectable()
export class PermissionGuard {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // var path = route.routeConfig.path;
    const path = route.pathFromRoot
      .filter((v) => v.routeConfig !== null && v.routeConfig && v.routeConfig.path)
      .map((v) => v.routeConfig!.path)
      .join('/');
    switch (path) {
      case 'users/list':
      case 'users/list/:pagenumber':
      case 'users/view':
      case 'users/view/:id':
        return this.authenticationService.hasPermission(UserPermissionEnum.USERS_MANAGE);

      case 'users/roles':
        return this.authenticationService.hasPermission(UserPermissionEnum.USERS_PERMISSION_ROLES);

      case 'students':
      case 'students/view/:id':
      case 'students/view/:id/contact':
      case 'students/view/:id/contact/:cid':
      case 'students/:pagenumber':
        return this.authenticationService.hasPermission(
          UserPermissionEnum.STUDENTS_MANAGE,
          UserPermissionEnum.STUDENTS_VIEW
        );

      // Only managepermission can create new studens
      case 'students/view':
        return this.authenticationService.hasPermission(UserPermissionEnum.STUDENTS_MANAGE);

      case 'courses/:pid/view':
        return this.authenticationService.hasPermission(UserPermissionEnum.COURSE_CREATE);

      case 'course-applications':
      case 'course-applications/:pagenumber':
        return this.authenticationService.hasPermission(UserPermissionEnum.PROGRAM_MANAGE);

      case 'applications':
      case 'applications/:pagenumber':
      case 'applications/detail/:id':
        return this.authenticationService.hasPermission(UserPermissionEnum.APPLICATION_MANAGE);

      case 'program/year/group/:id':
        return this.authenticationService.hasPermission(UserPermissionEnum.PAGE_MANAGE);
      case 'forms/new':
        return this.authenticationService.hasPermission(UserPermissionEnum.FORMS_MANAGE);

      case 'courses/view/:ccid/exemption-request':
        return this.authenticationService.hasPermission(UserPermissionEnum.EXEMPTIONS_REQUEST_CREATE);

      case 'all-grades/exams':
      case 'all-grades/exams/:pagenumber':
      case 'all-grades/education':
        return this.authenticationService.hasPermission(UserPermissionEnum.COURSE_MANAGE_GRADES);

      case 'my-grades/exams':
      case 'my-grades/exams/:pagenumber':
      case 'my-grades/education':
        return true;

      case 'overview/teacher':
      case 'courses/view/:courseID/analysis':
        return this.authenticationService.hasPermission(UserPermissionEnum.COURSE_MANAGE_GRADES);

      case 'course/analyses':
        return this.authenticationService.hasPermission(UserPermissionEnum.COURSE_MANAGE_ANALYSIS);
    }

    return false;
  }
}
