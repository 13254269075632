import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TeacherOverviewResponse } from '../../../_models/responses/overview/teacher.overview';
import { AuthenticationService, UserPermissionEnum } from '../../../_services';
import { OverviewService } from '../../../_services/overview.service';

declare let mLayout: any;
@Component({
  selector: 'app-aside-nav',
  templateUrl: './aside-nav.component.html',
  styleUrls: ['./aside-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {
  userPermissionEnum = UserPermissionEnum;

  constructor(public authService: AuthenticationService, public overviewService: OverviewService) {}

  ngOnInit() {
    if (this.authService.user.isTeacher) {
      this.overviewService.getTeacherOverview().subscribe();
    }
  }

  ngAfterViewInit() {
    mLayout.initAside();
  }
}
