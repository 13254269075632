import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainConfig } from '../../../_helpers';
import { MainDatatableComponent, ServerPage } from '../../../_models';
import { Student } from '../../../_models/student/student';
import { AuthenticationService, UserPermissionEnum } from '../../../_services';
import { StudentService } from '../../../_services/student.service';
import { TranslateService } from '../../translate';

@Component({
  selector: 'app-students-datatable',
  templateUrl: './students-datatable.component.html',
  styleUrls: ['./students-datatable.component.scss'],
})
export class StudentsDatatableComponent extends MainDatatableComponent implements OnInit {
  @Output() onStudentSelected: EventEmitter<Student> = new EventEmitter();

  constructor(
    route: ActivatedRoute,
    router: Router,
    config: MainConfig,
    translate: TranslateService,

    private studentService: StudentService
  ) {
    super(route, router, config, '', { itemSelected: '', itemsSelected: '' }, translate);
    this.page.custom.isArchived = false;
    this.page.custom.strOrder = 'strName';
    this.navigateUrl = false;
    // Init datatable messages
    this.messages = {
      emptyMessage: this.translate.instant('_students_empty_message'),
      totalMessage: this.translate.instant('_students_total'),
    };
  }

  clearPage() {
    this.urlPageNumber = 0;
    this.setPage({ offset: this.urlPageNumber });
    return true;
  }

  ngOnInit() {
    this.page.limit = 5;
    this.setListFn(this.getStudents);
    this.setPage({ offset: this.urlPageNumber });
  }

  getStudents(page: ServerPage) {
    this.loading = true;

    this.studentService.getStudents(page).subscribe((result) => {
      this.temp = [...result.rows];
      this.rows = <Array<Student>>result.rows;
      this.loading = false;
      this.page.count = result.count;
    });
  }
}
