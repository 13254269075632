import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../_services';
import { Observable } from 'rxjs';
import { CourseService } from '../../_services/course.service';
import { ADForm } from '../../_models/form/form';
import { Helpers } from '../../helpers';

@Injectable()
export class FormsGuard {
  constructor(
    private _router: Router,
    private authService: AuthenticationService,
    private courseService: CourseService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const observable: Observable<boolean> = new Observable((observer) => {
      const path = route.routeConfig.path;

      const formsPath = ['forms', 'forms/view/:id', ''];

      if (formsPath.includes(path)) {
        observer.next(true);
        return;
      }

      const objUser = this.authService.user;
      if (!objUser.objStudent) {
        observer.next(true);
        return;
      }

      this.courseService.getAllForms().subscribe((result) => {
        const forms = <ADForm[]>result;
        const forced = forms.filter((form) => {
          if (form.booIsForced && form.filledForms.length == 0) {
            return true;
          }

          if (form.filledForms.length > 0) {
            const filledForm = form.filledForms[0];
            if (!filledForm.booIsFilledForm) {
              return true;
            }
          }

          return false;
        });

        if (forced.length > 0) {
          this._router.navigate(['/forms']);
          observer.next(false);
          Helpers.setLoading(false, true);
        } else {
          observer.next(true);
        }
      });
    });
    return observable;
  }
}
