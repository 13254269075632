import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Student } from '../../../_models/student/student';
import { AuthenticationService } from '../../../_services';
import { GlobalMessageHandler } from '../../../_services/message-handler.service';
import { StudentService } from '../../../_services/student.service';
import { MainConfig } from '../../../_helpers';

@Component({
  selector: 'app-student-education-grades',
  templateUrl: './student-education-grades.component.html',
  styleUrls: ['./student-education-grades.component.scss'],
})
export class StudentEducationGradesComponent implements OnInit {
  public _grades: EducationGrades.Program[];
  public _student: Student;

  @Input() ownGrades: boolean = false;

  @Input() set student(value: Student) {
    this._student = value;
    this.setDownloadUrl();
    this.getStudentGrades();
  }

  get student(): Student {
    return this._student;
  }

  public downloadUrl: string;

  constructor(
    private authService: AuthenticationService,
    private studentService: StudentService,
    private gmHandler: GlobalMessageHandler,
    private mainConfig: MainConfig
  ) {}

  ngOnInit(): void {
    this.setDownloadUrl();
  }

  setDownloadUrl() {
    if (this.ownGrades) {
      this.downloadUrl =
        this.mainConfig.backendServerURL + 'api/program/progress/me/download' + `?token=${this.authService.token}`;
    } else {
      this.downloadUrl =
        this.mainConfig.backendServerURL +
        'api/program/progress/student/download?intStudentID=' +
        `${this._student.intStudentID}&token=${this.authService.token}`;
    }
  }

  getStudentGrades() {
    if (this.ownGrades) {
      this.studentService.getMyEducationGrades().subscribe(
        (result) => {
          this._grades = result.item;
        },
        (error: HttpErrorResponse) => this.gmHandler.handleError(error)
      );
    } else {
      this.studentService.getEducationGrades(this._student).subscribe(
        (result) => {
          this._grades = result.item;
        },
        (error: HttpErrorResponse) => this.gmHandler.handleError(error)
      );
    }
  }
}
