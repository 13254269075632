import { FileUploader } from 'ng2-file-upload';
import { UserRole } from './userrole';
import { Gender, Student } from '../student/student';
import { GAccount } from './gaccount';
import { Course } from '../course/course';

export class User {
    intUserID: number;
    strEmail: string;
    strPassword: string;
    strFirstName: string;
    strLastName: string;
    createdAt: string;
    updatedAt: string;

    strNewPassword: string;
    strNewPasswordRepeat: string;

    fileUploader: FileUploader;
    strProfileImageName: string;

    intUserRoleID: number;
    objUserRole: UserRole;

    strInsertion: string;
    strAddress: string;
    strZipCode: string;
    strCity: string;

    datBirthDate: Date;
    strGender: Gender;

    strDescription: string;
    booVisible: boolean;

    strPhoneNumber: string;
    strPrivateEmail: string;

    objGAccount: GAccount;
    objStudent: Student;

    courses: Course[];

    isTeacher: boolean = false;
}
