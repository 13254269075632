import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../_services';
import { FormsGuard } from './forms.guard';

@Injectable()
export class AuthGuard {
  constructor(private _router: Router, private authService: AuthenticationService, private formsGuard: FormsGuard) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const observable: Observable<boolean> = new Observable((observer) => {
      this.authService.verify().subscribe(
        (result: any) => {
          if (result.body.status == 'ok') {
            // logged in so return true
            observer.next(true);
            return;
            // this.formsGuard.canActivate(route, state).subscribe(hasAccess => {
            //     if (hasAccess) {

            //     } else {
            //         observer.next(false);
            //     }
            // });
          } else {
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            observer.next(false);
          }
        },
        (error) => {
          console.log(error);
          this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          observer.next(false);
        }
      );
    });
    return observable;
  }
}
