export enum BreadCrumbType {
    Location = 'Location',
    Program = 'Program',
    CalendarYear = 'CalendarYear',
    ProgramYear = 'ProgramYear',
    ProgramYearPeriod = 'ProgramYearPeriod',
    Course = 'Course'
}

export class BreadCrumb {
    ID: number;
    strName: string;
    type: BreadCrumbType;
    href?: string;
    fullUrl: boolean = false;
}
