<div class="modal fade" bsModal #basicModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title float-left">
          {{ deleteModal.title }}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="deleteModal.description | nl2br: true">
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default btn-flat" (click)="cancelModal()">
          {{ deleteModal.btn_cancel }}
        </button>
        <button type="button" [class]="'btn '+ deleteModal.class" (click)="confirmModal()">
          {{ deleteModal.btn_ok }}
        </button>
      </div>
    </div>
  </div>
</div>